import { useContext, useState } from "react";
import { AddConditionButton, UnderstandButton, CritisizeButton } from "./Buttons";
import { Alert, Badge, Button, Collapse, Modal, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { ContainerType, useCategoryListener } from "../../LucidMindShared/dataModel.web";
import { getTypeFromPath } from "../../LucidMindShared/helperFunctions.web";
import { LocalizedStringsContext } from "../../LucidMindShared/localization.web";
import { useNavigate } from "react-router-dom";
import { InfoAlert } from "./InfoAlert";

export function InteractionBadgeStatement(props: any) {

    const [extendInteractions, setExtendInteractions] = useState(true);

    return (
        // <span className="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-secondary">
        <span className="position-absolute top-100 start-100  translate-middle badge rounded-pill bg-transparent"
            onMouseOver={() => setExtendInteractions(true)}
            onMouseLeave={() => setExtendInteractions(true) /* should be false if enabled*/}>

            {/* <BelieveButton statement={props.statement} /> */}
            {extendInteractions && (
                <AddConditionButton />

            )}
            {/* <span className="visually-hidden">unread messages</span> */}
        </span>
    )
}

export function InteractionBadgeSyllogism(props: any) {

    const [extendInteractions, setExtendInteractions] = useState(true);

    return (
        // <span className="position-absolute top-100 start-50 translate-middle badge rounded-pill bg-secondary">
        <span className="badge rounded-pill"
            onMouseOver={() => setExtendInteractions(true)}
            onMouseLeave={() => setExtendInteractions(true) /* should be false if enabled*/}>

            {extendInteractions && (
                <AddConditionButton />

            )}
            <UnderstandButton />

            {extendInteractions && (
                <CritisizeButton />

            )}

            {/* <span className="visually-hidden">unread messages</span> */}
        </span>
    )
}

export function ConditionalSyllogismBadge(props: any) {
    return (
        <span className={props.type === "negative" ? 'badge rounded-pill bg-danger' : 'badge rounded-pill bg-success'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-box-arrow-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z" />
                <path fillRule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
            </svg>
            {props.type === "negative" ?
                props.statement.getAmountOfNegativeConditionalSyllogisms()
                :
                props.statement.getAmountOfPositiveConditionalSyllogisms()
            }
        </span>
    )
}

export enum ConflictSeverity {
    NONE = 0,
    CRITICAL = 1,
    MAJOR = 2,
    MINOR = 3,

}
export enum ConflictType {
    NOCONFLICT = 0,
    SUPPORTINGARGUMENTSNOTFOUND = 1,
    SUPPORTINGARGUMENTSFOUND = 2,
    SOURCENOTCHECKED = 3,
    SOURCENOTVALID = 4,
    SOURCEVALID = 5,
    LANGUAGEUNOBJECTIVE = 6,
    LANGUAGEOBJECTIVE = 7,
    RELEVANTARGUMENTSUNSEEN = 8,
    RELEVANTARGUMENTSSEEN = 9,
    CONDITIONSOFSOMEDEGREECONFLICTS = 10,
    SUPPORTINGARGUMENTSNOTCHECKED = 11,
    LANGUAGENOTCHECKED = 12,
    RELEVANTARGUMENTSNOTCHECKED = 13,
}
interface ConflictProps { type: ConflictType, containerType: ContainerType, supportingArgumentsFound: boolean, setConflictModalActive: Function }
export function ConflictBadge({ type, containerType, supportingArgumentsFound=false, setConflictModalActive=() => {} }: ConflictProps) {

    const [showConflictModal, setShowConflictModal] = useState(false);

    let severity: ConflictSeverity = ConflictSeverity.NONE;
    switch (type) {
        case ConflictType.NOCONFLICT:
            severity = ConflictSeverity.NONE;
            break;
        case ConflictType.SUPPORTINGARGUMENTSNOTFOUND:
            severity = ConflictSeverity.CRITICAL;
            break;
        case ConflictType.SOURCENOTCHECKED:
            severity = ConflictSeverity.MINOR;
            break;
        case ConflictType.SOURCENOTVALID:
            severity = ConflictSeverity.MAJOR;
            break;
        case ConflictType.LANGUAGEUNOBJECTIVE:
            severity = ConflictSeverity.MINOR;
            break;
        case ConflictType.RELEVANTARGUMENTSUNSEEN:
            severity = ConflictSeverity.MINOR;
            break;
        case ConflictType.RELEVANTARGUMENTSSEEN:
            severity = ConflictSeverity.NONE;
            break;
        case ConflictType.SUPPORTINGARGUMENTSFOUND:
            severity = ConflictSeverity.NONE;
            break;
        case ConflictType.LANGUAGEOBJECTIVE:
            severity = ConflictSeverity.NONE;
            break;
        case ConflictType.SOURCEVALID:
            severity = ConflictSeverity.NONE;
            break;
        case ConflictType.LANGUAGENOTCHECKED:
            severity = ConflictSeverity.MINOR;
            break;
        case ConflictType.RELEVANTARGUMENTSNOTCHECKED:
            severity = ConflictSeverity.MINOR;
            break;
        case ConflictType.SUPPORTINGARGUMENTSNOTCHECKED:
            severity = ConflictSeverity.MINOR;
            break;
        default:
            severity = ConflictSeverity.NONE;
            break;
    }

    let badgeClassName;
    let badgeText;
    switch (severity) {
        case ConflictSeverity.CRITICAL:
            badgeClassName = "conflict-badge conflict-badge-critical p-1";
            badgeText = "Konflikt";
            break;
        case ConflictSeverity.MAJOR:
            badgeClassName = "conflict-badge conflict-badge-major p-1";
            badgeText = "Konflikt";
            break;
        case ConflictSeverity.MINOR:
            badgeClassName = "conflict-badge conflict-badge-minor p-1";
            badgeText = "Konflikt";
            break;
        default:
            badgeClassName = "conflict-badge conflict-badge-noconflict p-1";
            badgeText = "Kein Konflikt";
            break;
    }

    // let badgeText;
    // switch (type) {
    //     case ConflictType.NOCONFLICT:
    //         badgeText = "Kein Konflikt";
    //         break;
    //     case ConflictType.SUPPORTINGARGUMENTSNOTFOUND:
    //         badgeText = "Keine Herleitung";
    //         break;
    //     case ConflictType.SOURCENOTCHECKED:
    //         badgeText = "Quelle wurde nicht überprüft";
    //         break;
    //     case ConflictType.SOURCENOTVALID:
    //         badgeText = "Quelle nicht vertrauenswürdig";
    //         break;

    //     default:
    //         badgeText = "Kein Konflikt";
    //         break;
    // }

    const handleConflictBadgeClick = (event: any) => {
        setShowConflictModal(true);
        setConflictModalActive(true);
    }

    const handleCloseConflictModal = (event: any) => {
        setShowConflictModal(false);
        setConflictModalActive(false);
    }

    return (
        <>
            <Badge pill className={badgeClassName} onClick={handleConflictBadgeClick}>
                {severity === ConflictSeverity.NONE ?
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor">
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation" viewBox="0 0 16 16">
                        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z" />
                    </svg>
                }
                
                {/* {badgeText} */}
            </Badge>
            <ConflictModal show={showConflictModal} handleClose={handleCloseConflictModal} containerType={containerType} supportingArgumentsFound={supportingArgumentsFound}/>
        </>
        
    )

}

interface TopicBadgeProps {topicPath: string, active?: boolean}
export function TopicBadge({topicPath}: TopicBadgeProps) { 
    
    const { category, loading, error } = useCategoryListener(topicPath)
    let navigate = useNavigate();

    const handleViewTopic = () => {
        navigate(`/topic/${topicPath}`);
    }

    if(loading || error) {

    }

    return (
        <>
            {
            <Badge className="topic-badge" pill onClick={handleViewTopic}>
                {category.getName()}
            </Badge>
            }
        </>
    )
}

export function TopicBadgeBig({topicPath, active=false}: TopicBadgeProps) { 
    
    const { category, loading, error } = useCategoryListener(topicPath)
    let navigate = useNavigate();

    const handleViewTopic = () => {
        navigate(`/topic/${topicPath}`);
    }

    if(loading || error) {
        
    }

    return (
        <>
            {
            <Badge className={active ? "topic-badge topic-badge-big-active" : "topic-badge topic-badge-big-inactive"} pill onClick={handleViewTopic}>
                {category.getName()}
            </Badge>
            }
        </>
    )
}

export function WithoutTopicBadge(props: any) { 

    const strings = useContext(LocalizedStringsContext);
    let navigate = useNavigate();
    
    const handleViewStatementsWithoutTopic = () => {
        navigate(`/personal`);
    }

    return (
        <>
            {
            <Badge className="topic-badge topic-badge-big-active" pill onClick={handleViewStatementsWithoutTopic}>
                {strings.without_topic}
            </Badge>
            }
        </>
    )
}


export function AllTopicsBadge(props: any) { 

    const strings = useContext(LocalizedStringsContext);
    let navigate = useNavigate();
    
    const handleViewStatementsWithoutTopic = () => {
        navigate(`/public`);
    }

    return (
        <>
            {
            <Badge className="topic-badge topic-badge-big-active" pill onClick={handleViewStatementsWithoutTopic}>
                {strings.all_topics}
            </Badge>
            }
        </>
    )
}

export function TopicBadges(props: any) {

    const strings = useContext(LocalizedStringsContext);
    const numberOfTopics = props.statement.getParentPaths().filter((parentPath: string) => getTypeFromPath(parentPath) === ContainerType.CATEGORY).length;
    
    return (
        <>
            {numberOfTopics > 0 ? 
                <>
                    {strings.about} {props.statement.getParentPaths().map((parentPath: string) => 
                        getTypeFromPath(parentPath) === ContainerType.CATEGORY? 
                            <TopicBadge key={parentPath} topicPath={parentPath}/> : null
                        )
                    }
                </>
                : null
            }
        </>
       
    )

}

export function ConflictModal({show, handleClose, containerType, supportingArgumentsFound}: any) {

    const strings = useContext(LocalizedStringsContext);
    const [supportingArgumentsCheckSuccessful, setSupportingArgumentsCheckSuccessful] = useState<boolean | undefined>(supportingArgumentsFound);
    const [seenArgumentsCheckSuccessful, setSeenArgumentsCheckSuccessful] = useState<boolean | undefined>(undefined);
    const [sourceCheckSuccessful, setSourceCheckSuccessful] = useState<boolean | undefined>(undefined);
    const [objectivityCheckSuccessful, setObjectivityCheckSuccessful] = useState<boolean | undefined>(undefined);

    const showStatementConflicts = containerType === ContainerType.STATEMENT;
    const showSourceConflicts = containerType === ContainerType.SOURCE;
    
    const showExample = () => {
        setSeenArgumentsCheckSuccessful(false);
        setSupportingArgumentsCheckSuccessful(true);
        setSourceCheckSuccessful(false);
        setObjectivityCheckSuccessful(false);
    }

    const handleCloseModal = () => {
        setSeenArgumentsCheckSuccessful(undefined);
        setSupportingArgumentsCheckSuccessful(supportingArgumentsFound);
        setSourceCheckSuccessful(undefined);
        setObjectivityCheckSuccessful(undefined);
        handleClose();
    }
  
    return (
      <Modal show={show} onHide={handleCloseModal} backdrop="static" centered>
        <Modal.Header closeButton className="border-0">
            <Modal.Title>
              {strings.conflicts}
            </Modal.Title>
            <Button className="mx-1" variant="light" onClick={showExample}>Show Example</Button>
        </Modal.Header>
        <Modal.Body className="pt-0">
            <Stack gap={2}>
                <InfoAlert content={strings.conflicts_info} />
                {showStatementConflicts ? 
                    <ConflictAlert 
                        type={supportingArgumentsCheckSuccessful ?
                            ConflictType.SUPPORTINGARGUMENTSFOUND 
                            : 
                            supportingArgumentsCheckSuccessful === false ? 
                                ConflictType.SUPPORTINGARGUMENTSNOTFOUND
                                :
                                ConflictType.SUPPORTINGARGUMENTSNOTCHECKED
                        }
                    /> : null
                }
                {showSourceConflicts ? 
                    <ConflictAlert
                        type={sourceCheckSuccessful ? 
                            ConflictType.SOURCEVALID
                            : sourceCheckSuccessful === false?
                                ConflictType.SOURCENOTVALID
                                :
                                ConflictType.SOURCENOTCHECKED
                        }
                    /> : null
                }
                {showStatementConflicts ? 
                    <ConflictAlert 
                        type={objectivityCheckSuccessful ?
                            ConflictType.LANGUAGEOBJECTIVE
                            :
                            objectivityCheckSuccessful === false ?
                                ConflictType.LANGUAGEUNOBJECTIVE
                                :
                                ConflictType.LANGUAGENOTCHECKED
                        }
                    /> : null
                }
                {showStatementConflicts ? 
                    <ConflictAlert
                        type={seenArgumentsCheckSuccessful ? 
                            ConflictType.RELEVANTARGUMENTSSEEN 
                            : 
                            seenArgumentsCheckSuccessful === false ? 
                                ConflictType.RELEVANTARGUMENTSUNSEEN
                                :
                                ConflictType.RELEVANTARGUMENTSNOTCHECKED
                    }
                /> : null}
            </Stack>

        </Modal.Body>
      </Modal>
    )
  }


export function ConflictAlert({ type }: {type: ConflictType}) {

    const strings = useContext(LocalizedStringsContext);
    const [showDetails, setShowDetails] = useState(false);

    let severity: ConflictSeverity = ConflictSeverity.NONE;
    let alertText;
    let resolvable: boolean | undefined = undefined;
    let infoText: string = "No info text";
    switch (type) {
        case ConflictType.NOCONFLICT:
            alertText = "Kein Konflikt";
            severity = ConflictSeverity.NONE;
            resolvable = false;
            break;
        case ConflictType.SUPPORTINGARGUMENTSNOTFOUND:
            alertText = strings.supporting_arguments_not_found;
            severity = ConflictSeverity.CRITICAL;
            resolvable = true;
            infoText = strings.supporting_arguments_check_info;
            break;
        case ConflictType.SOURCENOTCHECKED:
            alertText = strings.source_not_checked;
            severity = ConflictSeverity.MINOR;
            infoText = strings.source_check_info;
            resolvable = false;
            break;
        case ConflictType.SOURCENOTVALID:
            alertText = strings.source_invalid;
            infoText = strings.source_check_info;
            severity = ConflictSeverity.MAJOR;
            resolvable = true;
            break;
        case ConflictType.LANGUAGEUNOBJECTIVE:
            alertText = strings.language_unobjective;
            infoText = strings.language_check_info;
            severity = ConflictSeverity.MINOR;
            resolvable = true;
            break;
        case ConflictType.RELEVANTARGUMENTSUNSEEN:
            alertText = strings.relevant_arguments_unseen;
            infoText = strings.relevant_arguments_check_info;
            severity = ConflictSeverity.MINOR;
            resolvable = true;
            break;
        case ConflictType.RELEVANTARGUMENTSSEEN:
            alertText = strings.relevant_arguments_seen;
            infoText = strings.relevant_arguments_check_info;
            severity = ConflictSeverity.NONE;
            resolvable = false;
            break;
        case ConflictType.SUPPORTINGARGUMENTSFOUND:
            alertText = strings.supporting_arguments_found;
            infoText = strings.supporting_arguments_check_info;
            severity = ConflictSeverity.NONE;
            resolvable = false;
            break;
        case ConflictType.LANGUAGEOBJECTIVE:
                alertText = strings.language_objective;
                infoText = strings.language_check_info;
                severity = ConflictSeverity.NONE;
                resolvable = false;
                break;
        case ConflictType.SOURCEVALID:
            alertText = strings.source_valid;
            infoText = strings.source_check_info;
            severity = ConflictSeverity.NONE;
            resolvable = false;
            break;
        case ConflictType.LANGUAGENOTCHECKED:
            alertText = strings.language_not_checked;
            infoText = strings.language_check_info;
            severity = ConflictSeverity.MINOR;
            resolvable = false;
            break;
        case ConflictType.RELEVANTARGUMENTSNOTCHECKED:
            alertText = strings.relevant_arguments_not_checked;
            infoText = strings.relevant_arguments_check_info;
            severity = ConflictSeverity.MINOR;
            resolvable = false;
            break;
        case ConflictType.SUPPORTINGARGUMENTSNOTCHECKED:
            alertText = strings.supporting_arguments_not_checked;
            infoText = strings.supporting_arguments_check_info;
            severity = ConflictSeverity.MINOR;
            resolvable = false;
            break;
        default:
            alertText = "Kein Konflikt";
            severity = ConflictSeverity.NONE;
            break;
    }

    const getIcon = () => {
        switch (severity) {
            case ConflictSeverity.CRITICAL:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                </svg>
            );                
            case ConflictSeverity.MAJOR:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                    </svg>
                );   
            case ConflictSeverity.MINOR:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                    </svg>
                );   
            default:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                    </svg>
                );   
        }
        
    }

    // let alertClassName;
    // switch (severity) {
    //     case ConflictSeverity.CRITICAL:
    //         alertClassName = "conflict-alert conflict-alert-critical";
    //         break;
    //     case ConflictSeverity.MAJOR:
    //         alertClassName = "conflict-alert conflict-alert-major";
    //         break;
    //     case ConflictSeverity.MINOR:
    //         alertClassName = "conflict-alert conflict-alert-minor";
    //         break;

    //     default:
    //         alertClassName = "conflict-alert conflict-alert-noconflict";
    //         break;
    // }

    let alertVariant;
    switch (severity) {
        case ConflictSeverity.CRITICAL:
            alertVariant = "danger";
            break;
        case ConflictSeverity.MAJOR:
            alertVariant = "danger";
            break;
        case ConflictSeverity.MINOR:
            alertVariant = "warning";
            break;
        default:
            alertVariant = "success";
            break;
    }

    const handleClick = (event: any) => {
        console.log(event.target.tagName);
        if(event.target.tagName !== "BUTTON") {
            setShowDetails(!showDetails);
        }
    }

    const getResolveButton = () => {
        if(resolvable) {
            switch (severity) {
                case ConflictSeverity.CRITICAL:
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                            <Tooltip id={`tooltip-top`}>
                                {strings.resolve_tooltip}
                            </Tooltip>
                            }
                        >
                            <Button className="ms-auto" variant="outline-danger">{strings.resolve_conflict}</Button>
                        </OverlayTrigger>
                    );
                case ConflictSeverity.MAJOR:
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                            <Tooltip id={`tooltip-top`}>
                                {strings.resolve_tooltip}
                            </Tooltip>
                            }
                        >
                            <Button className="ms-auto" variant="outline-danger">{strings.resolve_conflict}</Button>
                        </OverlayTrigger>
                    );
                case ConflictSeverity.MINOR:
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                            <Tooltip id={`tooltip-top`}>
                                {strings.resolve_tooltip}
                            </Tooltip>
                            }
                        >
                            <Button className="ms-auto" variant="outline-warning">{strings.resolve_conflict}</Button>
                        </OverlayTrigger>
                    );
                default:
                    return (<></>);
            }
        }
    }

    return (
        <Alert variant={alertVariant} className="m-0">
            <Stack direction="horizontal" gap={2} onClick={handleClick}>
                {getIcon()}
                {alertText}
                {getResolveButton()}
            </Stack>
            <Collapse in={showDetails}>
                <div>{infoText}</div>
            </Collapse>
        </Alert>
    )
}