import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { ContainerType, StanceType, StatementData, StatementGroupData, _StatementData, _StatementGroupData, dbPath, useContainerListener, useStatementGroupListener, uuid } from "../../LucidMindShared/dataModel.web";
import { Statement } from "../../model";
import { StatementBox } from "./StatementBox";
import { useState, useContext } from "react";
import { getAuth } from "firebase/auth";
import { createPathWithUUID, getLocationFromPath } from "../../LucidMindShared/helperFunctions.web";
import { Timestamp } from "firebase/firestore";
import { CancelButton, ConfirmButton, OptionsDropdown } from "./Buttons";
import { LocalizedStringsContext } from "../../LucidMindShared/localization.web";

export type containerProps = {
    id: uuid,
    parentId: uuid,
    stance: StanceType
}
export function ConditionsListItem({ id, parentId, stance }: containerProps): JSX.Element {
    const { container, loading, error } = useContainerListener(id);

    if (loading) {
    } else if (error) {
    }

    const props = {
        id: id,
        parentId: parentId
    }
    if (container.getType() === ContainerType.STATEMENT) {
        return (
            <Card key={id} className={stance === StanceType.SUPPORT ? "conditions-card conditions-card-pro" : "conditions-card conditions-card-contra"}>
            <Card.Body className="conditions-card-body pb-0">
                <Row className="align-items-center">
                    <Col>
                        <Stack gap={2}>
                            <StatementBox deleteEnabled={true} editEnabled={false} publishEnabled={true} displayUser={false} displayCreationTime={false} {...props} statementId={id} rootId={id} statementObject={new Statement("Loading", false)} displayHeader={false} displayTopics={false}/>
                        </Stack>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent pt-0 pb-0">
                <Stack direction="horizontal" className="align-items-start">
                <div className="ms-auto px-1">
                    <OptionsDropdown deleteEnabled={false} onDelete={() => { } } editEnabled={false} onEdit={() => { } } publishEnabled={false} onPublish={() => { } } shareEnabled={false} onShare={() => { } } horizontal={true}/>
                </div>
                </Stack>
            </Card.Footer>
        </Card>
        );
    } else if (container.getType() === ContainerType.STATEMENT_GROUP) {
        return <ConditionsListItemCard {...props} stance={stance} key={id}/>;
    } else if (container.getType() === ContainerType.SOURCE) {
        return <></>; // <SourceBox {...props} />;
    } else {
        return <></>;
    }
}

interface ConditionsFormProps {parentPath: dbPath, stance: StanceType, onClose: Function}
export function ConditionsListItemCardForm({parentPath, stance, onClose }: ConditionsFormProps) {

    const [conditions, setConditions] = useState([new Statement("")]);
    const strings = useContext(LocalizedStringsContext);

    const saveArgumentToDB = (conditions: Statement[]) => {
        if (conditions.length === 1) {
            const userId = getAuth().currentUser?.uid || "";
            const path = createPathWithUUID(getLocationFromPath(parentPath), ContainerType.STATEMENT);
            const statementData: _StatementData = {
                content: conditions[0].content,
                type: ContainerType.STATEMENT,
                path: path,
                userId: userId,
                parentPaths: [parentPath],
                childPaths: [],
                votes: {},
                creationTime: Timestamp.now()
            };
            StatementData.makeInDB(statementData, stance);
        } else {
            const userId = getAuth().currentUser?.uid || "";
            const statementGroupPath = createPathWithUUID(getLocationFromPath(parentPath), ContainerType.STATEMENT_GROUP);
            const statementGroupData: _StatementGroupData = {
                type: ContainerType.STATEMENT_GROUP,
                path: statementGroupPath,
                userId: userId,
                parentPaths: [parentPath],
                childPaths: [],
                votes: {},
                creationTime: Timestamp.now()
            }
            StatementGroupData.makeInDB(statementGroupData, stance);

            conditions.forEach((condition) => {
                const conditionPath = createPathWithUUID(getLocationFromPath(statementGroupPath), ContainerType.STATEMENT);
                const conditionData: _StatementData = {
                    content: condition.content,
                    type: ContainerType.STATEMENT,
                    path: conditionPath,
                    userId: userId,
                    parentPaths: [statementGroupPath],
                    childPaths: [],
                    votes: {},
                    creationTime: Timestamp.now()
                };
                StatementData.makeInDB(conditionData, StanceType.NEUTRAL);
            });
        }
    }

    const handleSubmitArgument = () => {
        console.log(conditions);
        saveArgumentToDB(conditions);
        onClose();
        setConditions([new Statement("")]);
    }

    const handleChangeCondition = (value: string, desiredConditionIndex: number, condition: Statement) => {
        setConditions(
            conditions.map((condition, conditionIndex) =>
                conditionIndex === desiredConditionIndex
                    ? new Statement(value)
                    : condition
            )
        );
    };

    const handleAddCondition = () => {
        setConditions((previousConditions) => [
            ...previousConditions,
            new Statement("")
        ]);
    }

    const handleDeleteCondition = (desiredConditionIndex: number) => {

        if (conditions.length <= 1) {
            onClose();
            setConditions([new Statement("")]);
        } else {
            setConditions(
                conditions.filter((conditions, conditionIndex) => desiredConditionIndex != conditionIndex)
            );
        }
    }

    const onStatementFormSubmit = (e: any, conditionIndex: number) => {
        // TODO Set uneditable, show edit button
    }

    return (
        <Card className={stance === StanceType.SUPPORT ? "conditions-card conditions-card-pro" : "conditions-card conditions-card-contra"}>
            <Card.Body className="conditions-card-body pb-0">
                <Row className="align-items-center">
                    <Col>
                        <Stack gap={2}>
                            {conditions.map((condition: Statement, conditionIndex: number) =>
                                <Card key={conditionIndex} className="statement">
                                    <Card.Body className="pb-0">
                                        <Form onSubmit={(e) => onStatementFormSubmit(e, conditionIndex)}>
                                            <Form.Group controlId="statementContent">
                                                <Form.Control 
                                                    className="statement-content-input" 
                                                    onChange={e => handleChangeCondition(e.target.value, conditionIndex, condition)} 
                                                    value={condition.content} 
                                                    type="statement" 
                                                    placeholder={strings.add_condition_content_hint}
                                                    plaintext
                                                />
                                            </Form.Group>
                                        </Form>
                                    </Card.Body>
                                    <Card.Footer className="statement-footer bt-0">
                                        <Stack direction="horizontal">
                                            <div className="ms-auto" >
                                                <CancelButton onClick={() => handleDeleteCondition(conditionIndex)} />
                                            </div>
                                            {/* <ConfirmButton onClick={() => {}} /> */}
                                        </Stack>
                                    </Card.Footer>
                                </Card>)}
                            <Button onClick={() => handleAddCondition()} className="add-statement-btn">
                                {strings.add_condition}
                            </Button>
                        </Stack>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent pt-0" >
                <Stack direction="horizontal">
                    <div className="ms-auto" >
                        <CancelButton onClick={() => { onClose(); setConditions([new Statement("")]); }} />
                    </div>
                    <ConfirmButton onClick={handleSubmitArgument} />
                </Stack>
            </Card.Footer>
        </Card >
    )
}

export function ConditionsListItemCard({ id, parentId, stance }: any) {

    const { statementGroup, loading, error } = useStatementGroupListener(id);

    const onDelete = () => {
        statementGroup.delete();
    }

    const props = {
        id: id,
        parentId: parentId,
    }
    return (
        <Card className={stance === StanceType.SUPPORT ? "conditions-card conditions-card-pro" : "conditions-card conditions-card-contra"}>
            <Card.Body className="conditions-card-body pb-0">
                <Row className="align-items-center">
                    <Col>
                        <Stack gap={2}>
                            {statementGroup?.getChildPaths().map((childId: string) =>
                                <StatementBox deleteEnabled={true} editEnabled={false} publishEnabled={true} displayUser={false} displayCreationTime={false} key={childId} {...props} statementId={childId} rootId={childId} statementObject={new Statement("Loading", false)} displayHeader={false} displayTopics={false}/>
                            )}
                        </Stack>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent pt-0 pb-0">
                <Stack direction="horizontal" className="align-items-start">
                <div className="ms-auto px-1">
                    <OptionsDropdown deleteEnabled={statementGroup.getChildPaths().length === 0} onDelete={onDelete} editEnabled={false} onEdit={() => { } } publishEnabled={false} onPublish={() => { } } shareEnabled={false} onShare={() => { } } horizontal={true}/>
                </div>
                </Stack>
            </Card.Footer>
        </Card>
    )
}