import { FooterFeed } from "../components/Common/Footer";
import { AddButtonAndModal } from "../components/Common/Buttons";
import { Header } from "../components/Common/Header";
import { FirestoreConstants } from "../LucidMindShared/dataModel.web";
import { Col, Container, Row } from "react-bootstrap";
import { useFeedLoader, useUserState } from "../LucidMindShared/helperFunctions.web";
import { StatementList } from "../components/StatementList";
import { NavigationBar } from "../components/Common/NavigationBar";
import { NavigationBarBottom } from "../components/Common/NavigationBarBottom";

export function Feed(props: any) {

    const {rootPaths, loading, error, reloadFeed, loadMoreEntries} = useFeedLoader();
    const user = useUserState();

    if(error) {
        
    }

    return (
        <>
            <Header topics={props.topics} />

            <Row>
                <Col xs={0} sm={4} xl={2} className="navigation-sidebar d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    <NavigationBar activeTab={"feed"}/>
                </Col> 
                <Col xs={12} sm={8} xl={8} className="full-height">
                    <Container className="feed mx-auto pt-3">
                        {user ?
                                <StatementList data={rootPaths} parentPath={null} 
                                loading={loading} 
                                onRefresh={reloadFeed} 
                                onEndReached={() => loadMoreEntries(10)}
                                />
                            : 
                            null
                        }
                    </Container>
                </Col>
                <Col xs={0} sm={0} xl={2}>
                </Col> 
            </Row>
            <AddButtonAndModal 
                parentPath={FirestoreConstants.ROOT_PATH}
                enableAddTopic={true}
                enableAddArgument={false}
                enableAddStatement={true}
                enableAddSource={false}
            />

            <FooterFeed />

            <NavigationBarBottom/>

        </>

    );
}