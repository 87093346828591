import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import { Stack } from 'react-bootstrap';
import { PersonalIcon, ProfileIcon, PublicIcon } from './Icons';

export function NavigationBarBottom() {

    let navigate = useNavigate();
    const homePageActive = window.location.href.endsWith("public");
    const myWorldViewActive = window.location.href.includes("personal");
    const profileActive = window.location.href.includes("profile");
    
    return (
        <Navbar expand="lg" className="bg-body-tertiary d-block d-sm-none d-md-none d-lg-none d-xl-none" fixed="bottom">
            <Stack direction='horizontal' gap={5} className='justify-content-center'>
                <Nav.Item>
                    <Nav.Link className={homePageActive ? "navbar-link-active" : "navbar-link-inactive"} onClick={()=>navigate("/public")}>
                        <PublicIcon/>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={myWorldViewActive ? "navbar-link-active" : "navbar-link-inactive"} onClick={()=>navigate("/personal")} >
                        <PersonalIcon/>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link className={profileActive ? "navbar-link-active" : "navbar-link-inactive"} onClick={() => navigate("/profile")}>
                        <ProfileIcon/>
                    </Nav.Link>
                </Nav.Item>
            </Stack>
        </Navbar>
    );
}