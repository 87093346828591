import { useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup, Card, Dropdown, Form, OverlayTrigger, Stack, ToggleButton, Tooltip } from "react-bootstrap";
import { ContainerType, SourceData, StanceType, StatementData, VoteType, _SourceData, _StatementData, dbPath } from "../../LucidMindShared/dataModel.web";
import { ConditionsListItemCardForm } from "./ConditionsListItem";
import { Statement } from "../../model";
import { getAuth } from "firebase/auth";
import { createPathWithUUID, getLocationFromPath } from "../../LucidMindShared/helperFunctions.web";
import { Timestamp } from "firebase/firestore";
import { StatementBox } from "./StatementBox";
import { TopicForm } from "../AddTopicButtonAndModal";
import { LocalizedStringsContext, strings } from "../../LucidMindShared/localization.web";
import { ArrowRightIcon, BelieveIcon, BookmarkIcon, CancelIcon, CompareArrowsIcon, ConfirmIcon, EditIcon, NewWindowIcon, NotBelieveIcon, RemoveIcon } from "./Icons";
import { logEvent } from "firebase/analytics";
import { analytics } from '../../firebase-config';

const iconSizeFeed = 22;
const iconSizeAddArgument = 50;

interface BelieveButtonProps { vote: VoteType, onBelieve: Function }
export function BelieveButton({ vote, onBelieve }: BelieveButtonProps) {
    return (
        <Button variant="link" onClick={() => onBelieve()} className="p-1">
            <BelieveIcon vote={vote} />
        </Button>
    )
}

interface NotBelieveButtonProps { vote: VoteType, onNotBelieve: Function }
export function NotBelieveButton({ vote, onNotBelieve }: NotBelieveButtonProps) {
    return (
        <Button variant="link" onClick={() => onNotBelieve()} className="p-1">
            <NotBelieveIcon vote={vote} />
        </Button>
    )
}

// export function AddConditionButton(props) {
//     return (
//         <a
//             href='#'
//             className='card-link 
//         icon icon-add
//         bg-transparent 
//         border border-0
//         rounded-circle'>
//             <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width={iconSizeFeed}
//                 height={iconSizeFeed}
//                 fill="currentColor"
//                 className="bi bi-plus-circle-fill"
//                 viewBox="0 0 16 16">
//                 <circle fill="white" cx="8" cy="8" r="6" />
//                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
//             </svg>
//         </a>
//     )
// }

export function UnderstandButton(props) {

    const [hover, setHover] = useState(false);

    return (
        <a
            href='#'
            className='understand-btn card-link
        icon icon-understand
        bg-transparent 
        border border-0
      rounded-circle'>
            {props.understood | hover ?
                <svg xmlns="http://www.w3.org/2000/svg"
                    width={iconSizeFeed}
                    height={iconSizeFeed}
                    fill="currentColor"
                    className="bi bi-lightbulb-fill icon-understand"
                    viewBox="0 0 16 16"
                    onMouseOver={setHover(true)}
                    onMouseLeave={console.log("Leave")}>
                    <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg"
                    width={iconSizeFeed}
                    height={iconSizeFeed}
                    fill="currentColor"
                    className="bi bi-lightbulb icon-understand"
                    viewBox="0 0 16 16">
                    <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                </svg>
            }
            {/* <img className='mx-2' src={lightBulb} width="30" height="30" alt=""></img> */}
        </a>
    )
}

export function CritisizeButton(props) {

    return (
        <a
            href='#'
            className='card-link
        icon icon-critisize
        bg-transparent 
        border border-0
        rounded-circle'>
            {props.understood ? // Unnecessary here
                <svg xmlns="http://www.w3.org/2000/svg"
                    width={iconSizeFeed}
                    height={iconSizeFeed}
                    fill="currentColor"
                    className="bi bi-lightbulb-fill"
                    viewBox="0 0 16 16">
                    <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z" />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg"
                    width={iconSizeFeed}
                    height={iconSizeFeed}
                    fill="currentColor"
                    className="bi bi-lightbulb-off-fill"
                    viewBox="0 0 16 16"
                >
                    <path d="M2 6c0-.572.08-1.125.23-1.65l8.558 8.559A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm10.303 4.181L3.818 1.697a6 6 0 0 1 8.484 8.484zM5 14.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5zM2.354 1.646a.5.5 0 1 0-.708.708l12 12a.5.5 0 0 0 .708-.708l-12-12z" />
                </svg>
            }
            {/* <img className='mx-2' src={megaphoneColor} width="30" height="30" alt=""></img> */}
        </a>
    )
}

interface AddArgumentButtonProps { parentId: string, stance: StanceType }
export function AddArgumentButtonInline({ parentId, stance }: AddArgumentButtonProps) {

    const [showNewArgumentForm, setShowNewArgumentForm] = useState(false);

    return (
        <>
            {showNewArgumentForm ?
                <>
                    <ConditionsListItemCardForm parentPath={parentId} stance={stance} onClose={() => setShowNewArgumentForm(false)} />
                </>
                :
                <Button onClick={() => setShowNewArgumentForm(true)} className="add-statement-btn">
                    Neues Argument {stance === StanceType.SUPPORT ? "dafür" : "dagegen"}
                </Button>
            }
        </>
    );
}

interface AddStatementButtonProps { parentId: string, stance: StanceType }
export function AddStatementButtonInline({ parentId, stance }: AddStatementButtonProps) {

    const [showNewStatementForm, setShowNewStatementForm] = useState(false);
    const onFormSubmit = () => setShowNewStatementForm(false);

    return (
        <>
            {showNewStatementForm ?
                <StatementForm parentPath={parentId} onSubmit={onFormSubmit} />
                :
                <Button onClick={() => setShowNewStatementForm(true)} className="add-statement-btn">
                    Neue Aussage
                </Button>
            }
        </>
    );
}

interface AddButtonAndModalProps { parentPath: dbPath, enableAddTopic: boolean, enableAddArgument: boolean, enableAddStatement: boolean, enableAddSource: boolean }
export function AddButtonAndModal({ parentPath, enableAddTopic = false, enableAddArgument = false, enableAddStatement = true, enableAddSource = false }: AddButtonAndModalProps) {

    const [show, setShow] = useState(false);
    const [stance, setStance] = useState<StanceType>(StanceType.SUPPORT);
    const [showAddTopic, setShowAddTopic] = useState(false);
    const [showAddArgument, setShowAddArgument] = useState(false);
    const [showAddStatement, setShowAddStatement] = useState(false);
    const [showAddSource, setShowAddSource] = useState(false);
    const strings = useContext(LocalizedStringsContext);

    const handleClose = () => {
        setShowAddTopic(false);
        setShowAddArgument(false);
        setShowAddStatement(false);
        setShowAddSource(false);
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const handleShowAddTopic = () => {
        setShowAddTopic(true);
        handleShow();
    }

    const handleShowAddStatement = () => {
        setShowAddStatement(true);
        handleShow();
    }

    const handleShowAddArgument = () => {
        setShowAddArgument(true);
        handleShow();
    }

    const handleShowAddSource = () => {
        setShowAddSource(true);
        handleShow();
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="link" id="dropdown-basic" className='floating-add-button position-fixed bottom-0 end-0 translate-middle'>
                    <svg xmlns="http://www.w3.org/2000/svg" width={iconSizeAddArgument} height={iconSizeAddArgument} fill="currentColor" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                    </svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {enableAddTopic ? <Dropdown.Item onClick={handleShowAddTopic}>{strings.category}</Dropdown.Item> : null}
                    {enableAddArgument ? <Dropdown.Item onClick={handleShowAddArgument}>{strings.argument}</Dropdown.Item> : null}
                    {enableAddStatement ? <Dropdown.Item onClick={handleShowAddStatement}>{strings.statement}</Dropdown.Item> : null}
                    {enableAddSource ? <Dropdown.Item onClick={handleShowAddSource}>{strings.source}</Dropdown.Item> : null}
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {showAddTopic ? strings.add_topic : null}
                        {showAddArgument ?
                            <>
                                {strings.add_argument}
                                <ToggleStanceButton stance={stance} setStance={setStance} />
                            </>
                            :
                            null
                        }
                        {showAddStatement ? strings.add_statement : null}
                        {showAddSource ?
                            <>
                                {strings.add_source}
                                <ToggleStanceButton stance={stance} setStance={setStance} />
                            </>
                            :
                            null
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showAddTopic ?
                        <TopicForm parentPath={parentPath} onClose={handleClose} />
                        : null
                    }
                    {showAddArgument ?
                        <>
                            <Stack gap={2}>
                                <StatementBox statementId={parentPath} rootId={parentPath} statementObject={new Statement("Loading", false)} displayTopics={false} displayHeader={false} displayUser={false} displayCreationTime={false} deleteEnabled={false} editEnabled={false} publishEnabled={false} />
                                <ConditionsListItemCardForm parentPath={parentPath} stance={stance} onClose={handleClose} />
                                <ProposeArgumentButton />
                            </Stack>
                        </>
                        : null
                    }
                    {showAddStatement ?
                        <StatementForm parentPath={parentPath} onSubmit={handleClose} />
                        : null
                    }
                    {showAddSource ?
                        <SourceForm parentPath={parentPath} onSubmit={handleClose} stance={stance} />
                        : null
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

interface StatementFormProps { parentPath: dbPath, onSubmit: Function }
export function StatementForm({ parentPath, onSubmit }: StatementFormProps) {

    const [statementContent, setStatementContent] = useState("");
    const onInput = ({ target: { value } }: any) => setStatementContent(value);
    const strings = useContext(LocalizedStringsContext);

    const saveStatementToDB = (content: string) => {
        const userId = getAuth().currentUser?.uid || "";
        const path = createPathWithUUID(getLocationFromPath(parentPath), ContainerType.STATEMENT);
        const statementData: _StatementData = {
            content: content,
            type: ContainerType.STATEMENT,
            path: path,
            userId: userId,
            parentPaths: [parentPath],
            childPaths: [],
            votes: {},
            creationTime: Timestamp.now()
        };
        StatementData.makeInDB(statementData, StanceType.NEUTRAL);
    }

    const onFormSubmit = (e: any) => {
        saveStatementToDB(statementContent);
        onSubmit();
        setStatementContent("");
    }

    return (
        <>
            <Card className="statement border border-0 border-secondary rounded-4">
                <Card.Body className="pb-0">
                    <Form>
                        <Form.Group controlId="statementContent">
                            <Form.Control
                                onChange={onInput}
                                value={statementContent}
                                type="statement"
                                placeholder={strings.add_statement_content_hint}
                                as="textarea"
                                rows={3}
                                plaintext
                            />
                        </Form.Group>
                    </Form>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent pt-0">
                    <Stack direction="horizontal">
                        <div className="ms-auto" >
                            <CancelButton onClick={() => onSubmit()} />
                        </div>
                        <ConfirmButton onClick={onFormSubmit} />
                    </Stack>
                </Card.Footer>
            </Card>
        </>
    )

}

interface SourceFormProps { parentPath: dbPath, onSubmit: Function, stance: StanceType }
export function SourceForm({ parentPath, onSubmit, stance }: SourceFormProps) {

    const [sourceContent, setSourceContent] = useState("");
    const [sourceUrl, setSourceUrl] = useState("");
    const [sourceReference, setSourceReference] = useState("");
    const onContentInput = ({ target: { value } }: any) => setSourceContent(value);
    const onUrlInput = ({ target: { value } }: any) => setSourceUrl(value);
    const onReferenceInput = ({ target: { value } }: any) => setSourceReference(value);
    const strings = useContext(LocalizedStringsContext);

    const saveSourceToDB = () => {
        const userId = getAuth().currentUser?.uid || "";
        const path = createPathWithUUID(getLocationFromPath(parentPath), ContainerType.SOURCE);
        const sourceData: _SourceData = {
            content: sourceContent,
            type: ContainerType.STATEMENT,
            url: sourceUrl,
            reference: sourceReference,
            path: path,
            userId: userId,
            parentPaths: [parentPath],
            childPaths: [],
            votes: {},
            creationTime: Timestamp.now()
        };
        SourceData.makeInDB(sourceData, stance);
    }

    const onFormSubmit = (e: any) => {
        // e.preventDefault();
        saveSourceToDB();
        onSubmit();
        setSourceContent("");
        setSourceReference("");
        setSourceUrl("");
    }

    return (
        <Card className={stance === StanceType.SUPPORT ? "source-card source-card-pro" : "source-card source-card-contra"}>
            <Card.Body className="source-card-body">
                <Card className="source">
                    <Card.Body>
                        <Stack className="p-2">
                            <Form>
                                <Form.Group>
                                    <Form.Control
                                        id="sourceContentInput"
                                        onChange={onContentInput}
                                        value={sourceContent}
                                        type="text"
                                        placeholder={strings.add_source_content_hint}
                                        plaintext
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        className="source-input my-2"
                                        id="sourceUrlInput"
                                        onChange={onUrlInput}
                                        value={sourceUrl}
                                        type="url"
                                        placeholder={strings.add_source_url_hint}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Control
                                        className="source-input my-2"
                                        id="sourceReferenceInput"
                                        onChange={onReferenceInput}
                                        value={sourceReference}
                                        type="text"
                                        placeholder={strings.add_source_reference_hint}
                                    />
                                </Form.Group>
                            </Form>
                        </Stack>
                    </Card.Body>
                </Card>
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent pt-0">
                <Stack direction="horizontal">
                    <div className="ms-auto" >
                        <CancelButton onClick={() => onSubmit()} />
                    </div>
                    <ConfirmButton onClick={onFormSubmit} />
                </Stack>
            </Card.Footer>
        </Card>
    )

}

export function ProposeArgumentButton(props: any) {

    const strings = useContext(LocalizedStringsContext);

    const handleProposeArgument = () => {
        logEvent(analytics, 'propose-argument-click');
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    {strings.propose_arguments_tooltip}
                </Tooltip>
            }
        >
            <Button onClick={handleProposeArgument}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-magic" viewBox="0 0 16 16">
                    <path d="M9.5 2.672a.5.5 0 1 0 1 0V.843a.5.5 0 0 0-1 0v1.829Zm4.5.035A.5.5 0 0 0 13.293 2L12 3.293a.5.5 0 1 0 .707.707L14 2.707ZM7.293 4A.5.5 0 1 0 8 3.293L6.707 2A.5.5 0 0 0 6 2.707L7.293 4Zm-.621 2.5a.5.5 0 1 0 0-1H4.843a.5.5 0 1 0 0 1h1.829Zm8.485 0a.5.5 0 1 0 0-1h-1.829a.5.5 0 0 0 0 1h1.829ZM13.293 10A.5.5 0 1 0 14 9.293L12.707 8a.5.5 0 1 0-.707.707L13.293 10ZM9.5 11.157a.5.5 0 0 0 1 0V9.328a.5.5 0 0 0-1 0v1.829Zm1.854-5.097a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L8.646 5.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0l1.293-1.293Zm-3 3a.5.5 0 0 0 0-.706l-.708-.708a.5.5 0 0 0-.707 0L.646 13.94a.5.5 0 0 0 0 .707l.708.708a.5.5 0 0 0 .707 0L8.354 9.06Z" />
                </svg>
                {strings.propose_arguments}
            </Button>
        </OverlayTrigger>

    )
}

interface ToggleTopicViewButtonProps { view: string, setView: Function }
export function ToggleTopicViewButton({ view, setView }: ToggleTopicViewButtonProps) {

    const radios = [
        { name: 'List', value: 'list' },
        { name: 'Tree', value: 'tree' },
    ];

    return (
        <>
            <ButtonGroup>
                {radios.map((radio, idx) => (
                    <ToggleButton
                        className={view === radio.value ? "btn-toggle-checked" : "btn-toggle-unchecked"}
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-secondary"
                        name="radio"
                        value={radio.value}
                        checked={view === radio.value}
                        onChange={(e) => setView(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    );
}

interface ButtonProps { onClick: Function }
export function CancelButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon" variant="primary" onClick={() => onClick()}>
            <CancelIcon />
        </Button>
    )
}

export function ConfirmButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon" variant="primary" onClick={() => onClick()}>
            <ConfirmIcon />
        </Button>
    )
}

export function EditButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon" variant="primary" onClick={() => onClick()}>
            <EditIcon />
        </Button>
    )
}

export function RemoveButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon-text align-items-center text-center" variant="" onClick={() => onClick()}>
            <RemoveIcon />
            {strings.delete}
        </Button>
    )
}

export function AddArgumentButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon-text align-items-center text-center" variant="" onClick={() => onClick()}>
            <NewWindowIcon />
            {strings.add_argument}
        </Button>
    )
}

export function AddConditionButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon-text align-items-center text-center" variant="" onClick={() => onClick()}>
            <NewWindowIcon />
            {strings.add_condition}
        </Button>
    )
}

export function SwitchStanceButton({ onClick }: ButtonProps) {
    return (
        <Button className="btn-icon-text align-items-center text-center" variant="" onClick={() => onClick()}>
            <CompareArrowsIcon />
            Ändere Haltung
        </Button>
    )
}

interface ToggleStanceButtonProps { stance: StanceType, setStance: Function }
export function ToggleStanceButton({ stance, setStance }: ToggleStanceButtonProps) {

    const strings = useContext(LocalizedStringsContext);

    const radios = [
        { name: strings.support, value: StanceType.SUPPORT },
        { name: strings.oppose, value: StanceType.OPPOSE },
    ];

    return (
        <>
            <ButtonGroup className="mx-2">
                {radios.map((radio, idx) => (
                    <ToggleButton
                        className={stance === radio.value ? "btn-toggle-checked" : "btn-toggle-unchecked"}
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant="outline-secondary"
                        name="radio"
                        value={radio.value}
                        checked={stance === radio.value}
                        onChange={() => setStance(radio.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </>
    );
}

interface TestVersionButtonProps { handleLandingPageClick: Function }
export function TestVersionButton({ handleLandingPageClick }: TestVersionButtonProps) {

    const strings = useContext(LocalizedStringsContext);

    const handleSeeTestVersion = () => {
        handleLandingPageClick()
        window.open("https://app.lucid-mind.eu/register");
    }

    return (
        <Button onClick={handleSeeTestVersion}>
            {strings.see_test_version}
        </Button>
    )
}

interface TryButtonProps { handleLandingPageClick: Function }
export function TryButton({ handleLandingPageClick }: TryButtonProps) {

    const strings = useContext(LocalizedStringsContext);

    const handleSeeTestVersion = () => {
        handleLandingPageClick()
        window.open("https://app.lucid-mind.eu/login");
    }

    return (
        <Button variant="secondary" onClick={handleSeeTestVersion}>
            {strings.try_now}
        </Button>
    )
}

interface WaitListButtonProps { handleLandingPageClick: Function }
export function WaitListButton({ handleLandingPageClick }: WaitListButtonProps) {

    const strings = useContext(LocalizedStringsContext);

    const handleSeeTestVersion = () => {
        handleLandingPageClick()
        window.open("https://tally.so/r/3NAr5O");
    }

    return (
        <Button variant="primary" onClick={handleSeeTestVersion}>
            {strings.join_waitlist}
        </Button>
    )
}

interface LearnMoreProps { handleLandingPageClick: Function }
export function LearnMore({ handleLandingPageClick }: LearnMoreProps) {

    const strings = useContext(LocalizedStringsContext);

    const handleLearnMore = () => {
        handleLandingPageClick()
        window.open("https://app.lucid-mind.eu/login");
    }

    return (
        <Button variant='link' className='px-0 learn-more-link align-items-center' onClick={handleLearnMore}>
            {strings.learn_more}
            <ArrowRightIcon />
        </Button>
    )
}

export function BookmarkButton() {

    const strings = useContext(LocalizedStringsContext);

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip>
                    {strings.bookmark_tooltip}
                </Tooltip>
            }
        >
            <Button className="btn-icon" variant="link">
                <BookmarkIcon />
            </Button>
        </OverlayTrigger>
    )
}

interface OptionsDropdownProps {
    horizontal: boolean
    deleteEnabled: boolean,
    onDelete: Function,
    editEnabled: boolean,
    onEdit: Function
    publishEnabled: boolean,
    onPublish: Function
    shareEnabled: boolean,
    onShare: Function
}
export function OptionsDropdown({
    horizontal = false,
    deleteEnabled = true,
    onDelete = () => { },
    editEnabled = false,
    onEdit = () => { },
    publishEnabled = true,
    onPublish = () => { },
    shareEnabled = false,
    onShare = () => { },
}: OptionsDropdownProps) {

    const strings = useContext(LocalizedStringsContext);

    return (
        <Dropdown>
            <Dropdown.Toggle variant="link" id="dropdown-basic" className="statement-options-dropdown">
                {horizontal ?
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20" fill="currentColor" >
                        <path d="M207.858-432Q188-432 174-446.142q-14-14.141-14-34Q160-500 174.142-514q14.141-14 34-14Q228-528 242-513.858q14 14.141 14 34Q256-460 241.858-446q-14.141 14-34 14Zm272 0Q460-432 446-446.142q-14-14.141-14-34Q432-500 446.142-514q14.141-14 34-14Q500-528 514-513.858q14 14.141 14 34Q528-460 513.858-446q-14.141 14-34 14Zm272 0Q732-432 718-446.142q-14-14.141-14-34Q704-500 718.142-514q14.141-14 34-14Q772-528 786-513.858q14 14.141 14 34Q800-460 785.858-446q-14.141 14-34 14Z" />
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                }
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    disabled={!editEnabled}
                    onClick={() => onEdit()}
                >
                    {strings.edit}
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={!deleteEnabled}
                    onClick={() => onDelete()}
                >
                    {strings.delete}
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={!shareEnabled}
                    onClick={() => onShare()}
                >
                    {strings.share}
                </Dropdown.Item>
                <Dropdown.Item
                    disabled={!publishEnabled}
                    onClick={() => onPublish()}
                >
                    {strings.publish}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
