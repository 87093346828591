import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Image from 'react-bootstrap/esm/Image';
import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
// @ts-ignore
import logo from '../LucidMindShared/res/LogoLM.png';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, updateProfile } from 'firebase/auth';
import { initializeUserData } from '../LucidMindShared/helperFunctions.web';
import { analytics } from '../firebase-config';
import { logEvent } from 'firebase/analytics';

export function RegisterView(props: any) {

    const strings = useContext(LocalizedStringsContext);
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleRegister = () => {
        if(email === '' || password === '' || displayName === '') {
            alert("Please enter your email, username and password.");
            return;
        }

        logEvent(analytics, 'register_click');

        const auth = getAuth()
        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                if(auth.currentUser) {
                    updateProfile(auth.currentUser, {
                        displayName: displayName
                    });
                }
            }).then(() => {
                if(auth.currentUser) {
                    navigate("/personal?tutorial=true");
                    initializeUserData(auth.currentUser.uid);
                    sendEmailVerification( auth.currentUser, {
                        handleCodeInApp: false,
                        url: 'https://lucid-mind.eu',
                        android: {
                            installApp: true,
                            packageName: 'com.mobiletest'
                        }
                    })
                }
            })
            .catch(error => {
                if (error.code === 'auth/email-already-in-use') {
                    alert('That email address is already in use!');
                } else if (error.code === 'auth/invalid-email') {
                    alert('That email address is invalid!');
                } else {
                    alert("Error:" + error);
                    console.log("Error:" + error);
                }
        });
    }

    return (
        <>  
            <Container className='login-view'>
                <Row>
                    <Col>
                        <Row className='my-0 mx-3'>
                            <Image src={logo} height={`${250}px`} width={`${250}px`}/>
                        </Row>
                        <Form>
                            <Form.Group className="mb-3">
                                {/* <Form.Label>{strings.display_name}</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    placeholder={strings.display_name}
                                    onChange={event => setDisplayName(event.target.value)}
                                    autoFocus
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                {/* <Form.Label>E-Mail Adresse</Form.Label> */}
                                <Form.Control
                                    type="email"
                                    placeholder={strings.email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                            >
                                {/* <Form.Label>Passwort</Form.Label> */}
                                <Form.Control
                                    type='password'
                                    placeholder={strings.password}
                                    onChange={event => setPassword(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Text className="text-muted">
                                <p className='p-1'>{strings.already_have_account} <a onClick={() => console.log("Click!")} href="/login">{strings.login}.</a></p>
                            </Form.Text>
                        </Form>
                        <Button className="login-btn" variant="primary" onClick={handleRegister}>
                            {strings.register}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )

}