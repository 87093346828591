import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/esm/Container';
import { ContainerType, FirestoreConstants, dbPath, useContainerListener } from '../LucidMindShared/dataModel.web';
import { Badge, Stack } from 'react-bootstrap';
import { Topic } from '../model';
import { useContext } from 'react';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';
import { getTypeFromPath } from '../LucidMindShared/helperFunctions.web';
import { TopicBadgeBig, WithoutTopicBadge } from './Common/Badges';

export function TopicList(props: any) {

    const strings = useContext(LocalizedStringsContext);

    const handleViewTopic = (topic: any) => props.handleViewTopic(topic);

    return (
        <div className='topic-list'>
            <h4>{strings.personal_categories_header}</h4>
            <ListGroup>
                {Object.keys(props.topics).map((key, index) =>
                    <ListGroup.Item key={key}>
                        <Card className='topic-card'>
                            <Card.Body>
                                <Card.Title>
                                    {props.topics[key].name}
                                </Card.Title>
                                {/* TODO button to follow topic */}
                                <Link className="" to="/world">
                                    <Button onClick={() => handleViewTopic(props.topics[key])}>
                                        View
                                    </Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    )
}

interface TopicProps {topicPath?: dbPath}
export function TopicListHorizontal({topicPath}: TopicProps) {

    const { container, loading, error } = useContainerListener(topicPath? topicPath : FirestoreConstants.ROOT_PATH);
    const strings = useContext(LocalizedStringsContext);

    if(loading) {

    } else if (error) {

    }

    return (
        <Container className='topic-list-horizontal p-0'>
            {/* <h4>{strings.personal_categories_header}</h4> */}

            <div className="topic-list-horizontal-container container-fluid px-0 py-4">
                <div className="d-flex flex-row flex-nowrap">
                    <Stack direction='horizontal' gap={1}>
                        <WithoutTopicBadge />
                        {container.getChildPaths().map((childId: string) =>
                            getTypeFromPath(childId) === ContainerType.CATEGORY ?
                                <TopicBadgeBig key={childId} topicPath={childId} />
                            : 
                            null
                        )}
                        { !loading && container.getChildPaths().length === 0 ?
                            <Badge className="topic-badge topic-badge-big-inactive" pill>
                                {strings.no_topics_info}
                            </Badge>
                            :
                            null
                        }
                    </Stack>
                </div>
            </div>
        </Container >
    )
}