import Container from 'react-bootstrap/esm/Container';
import { Badge, Button, Col, Image, Row, Stack } from 'react-bootstrap';
import { useContext } from 'react';
import { LocalizedStringsContext, strings } from '../LucidMindShared/localization.web';
import { LearnMore, TestVersionButton, TryButton } from '../components/Common/Buttons';
// // @ts-ignore
// import logo from '../LucidMindShared/res/LogoLM.png';
// // @ts-ignore
// import mockupCollectionDE from "../LucidMindShared/res/mockupCollectionDE.png";
// // @ts-ignore
// import syllogismMockupDE from "../LucidMindShared/res/syllogismMockupDE.png";
// // @ts-ignore
// import logicalNetworkMockupDE from "../LucidMindShared/res/logicalNetworkMockupDE.png";
// // @ts-ignore
// import interactionMockupDE from "../LucidMindShared/res/interactionMockupDE.png";
// // @ts-ignore
// import feedMockupDE from "../LucidMindShared/res/feedMockupDE.png";
// // @ts-ignore
// import statementViewMockupDE from "../LucidMindShared/res/statementViewMockupDE.png";
// // @ts-ignore
// import topicTreeViewMockupDE from "../LucidMindShared/res/topicTreeViewMockupDE.png";
// // @ts-ignore
// import introductionChecksConflictsImageEN from "../LucidMindShared/res/introductionChecksConflictsEN.png";
// // @ts-ignore
// import introductionChecksConflictsImageDE from "../LucidMindShared/res/introductionChecksConflictsDE.png";
// // @ts-ignore
// import knowledgeExtractionImageDE from "../LucidMindShared/res/knowledgeExtractionDE.png";
// // @ts-ignore
// import knowledgeExtractionImageEN from "../LucidMindShared/res/knowledgeExtractionEN.png";
import { FooterFeed } from '../components/Common/Footer';
// // @ts-ignore
// import logoWithText from '../LucidMindShared/res/logoWithText.png';
// @ts-ignore
import newsArticlesMockupEN from "../LucidMindShared/res/newsArticlesMockupEN.png";
// @ts-ignore
import collectionArticlesNetworkBrain from "../LucidMindShared/res/collectionArticlesNetworkBrain.png";
// // @ts-ignore
// import newsArticlePostMockupEN from "../LucidMindShared/res/newsArticlePostMockupEN.png";
// @ts-ignore
import statementNetworkIcon from "../LucidMindShared/res/statementNetworkIcon.png";
// @ts-ignore
import timeIcon from "../LucidMindShared/res/timeIcon.png";
// @ts-ignore
import scaleIcon from "../LucidMindShared/res/scaleIcon.png";
// @ts-ignore
import arrowRight from "../LucidMindShared/res/arrowRight.svg";
// @ts-ignore
import controllerIcon from "../LucidMindShared/res/controllerIcon.png";
// @ts-ignore
import aiChessIcon from "../LucidMindShared/res/aiChessIcon.png";
// @ts-ignore
import sharedIcon from "../LucidMindShared/res/sharedIcon.png";
// @ts-ignore
import feedArticleArgumentMockup from "../LucidMindShared/res/feedArticleArgumentMockup.png";
// @ts-ignore
import consistencyCheckProfile from "../LucidMindShared/res/consistencyCheckProfile.png";
// @ts-ignore
import proContraArguments from "../LucidMindShared/res/proContraArguments.png";
// @ts-ignore
import syllogismWithoutIcons from "../LucidMindShared/res/syllogismWithoutIcons.png";
// @ts-ignore
import statementNetwork from "../LucidMindShared/res/statementNetwork.png";
// @ts-ignore
import syllogismWithPlaceholderWithIcons from "../LucidMindShared/res/syllogismWithPlaceholderWithIcons.png";
// @ts-ignore
import feedArguments from "../LucidMindShared/res/feedArguments.png";
// @ts-ignore
import articleStatementPost from "../LucidMindShared/res/articleStatementPost.png";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';
import { LandingPageHeader } from '../components/Common/LandingPageHeader';
import { BenefitCol } from './BenefitCol';
import { ArrowRightIcon } from '../components/Common/Icons';

export function LandingPageSingularInformationSourceView(props: any) {

    const strings = useContext(LocalizedStringsContext);
    // const language = strings.getInterfaceLanguage().split("-")[0];
    // const landingPageActive = false;

    const randomBenefitAssignment = () => {
        // Define an array of possible benefits as objects
        const allBenefits = [
            {
                header: strings.better_overview_header,
                text: strings.better_overview_text,
                icon: statementNetworkIcon
            },
            {
                header: strings.time_saving_header,
                text: strings.time_saving_text,
                icon: timeIcon
            },
            {
                header: strings.benefit_engaging_header,
                text: strings.benefit_engaging_text,
                icon: controllerIcon
            },
            {
                header: strings.benefit_shared_header,
                text: strings.benefit_shared_text,
                icon: sharedIcon
            },
            {
                header: strings.benefit_smart_header_alternative_1,
                text: strings.benefit_smart_text,
                icon: aiChessIcon
            },
            {
                header: strings.benefit_unbiased_header,
                text: strings.benefit_unbiased_text,
                icon: scaleIcon
            },
        ];

        // Shuffle the array to randomize the order
        for (let i = allBenefits.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [allBenefits[i], allBenefits[j]] = [allBenefits[j], allBenefits[i]];
        }

        // Return the first 3 benefit objects
        return allBenefits.slice(0, 3);
    }

    const randomBenefits = randomBenefitAssignment();

    const randomFeatureAssignment = () => {
        // Define an array of possible benefits as objects
        const allFeatures = [
            {
                header: strings.share_knowledge_header,
                text: strings.share_knowledge_text,
                icon: feedArticleArgumentMockup,
                iconScale: "50%"
            },
            {
                header: strings.conquer_your_bias_header,
                text: strings.conquer_your_bias_text,
                icon: proContraArguments,
                iconScale: "100%"
            },
            {
                header: strings.automatic_critizism_header,
                text: strings.automatic_critizism_text,
                icon: consistencyCheckProfile,
                iconScale: "100%"
            },
            {
                header: strings.feature_generated_content_header,
                text: strings.feature_generated_content_text,
                icon: articleStatementPost,
                iconScale: "100%"
            },
            {
                header: strings.introduction_group_statements_header,
                text: strings.introduction_group_statements_text,
                icon: syllogismWithoutIcons,
                iconScale: "100%"
            },
            {
                header: strings.introduction_group_logical_tree_header,
                text: strings.introduction_group_logical_tree_text,
                icon: statementNetwork,
                iconScale: "100%"
            },
            {
                header: strings.introduction_group_interaction_header_soft,
                text: strings.introduction_group_interaction_text,
                icon: syllogismWithPlaceholderWithIcons,
                iconScale: "100%"
            },
            {
                header: strings.argument_proposal_header,
                text: strings.argument_proposal_text,
                icon: feedArguments,
                iconScale: "50%"
            },
            {
                header: strings.pro_contra_argument_header_soft,
                text: strings.pro_contra_argument_text,
                icon: proContraArguments,
                iconScale: "100%"
            },
            {
                header: strings.conflictsChecks_header,
                text: strings.conflictsChecks_text,
                icon: consistencyCheckProfile,
                iconScale: "100%"
            },
        ];

        // Shuffle the array to randomize the order
        for (let i = allFeatures.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [allFeatures[i], allFeatures[j]] = [allFeatures[j], allFeatures[i]];
        }

        // Return the first 3 feature objects
        return allFeatures.slice(0, 3);
    }

    const randomFeatures = randomFeatureAssignment();

    const randomCallToActionAssignment = () => {
        // Define an array of possible call to actions as objects
        const allCallToActions = [
            {
                header: strings.call_to_action_elevate_insights_header,
                text: strings.call_to_action_elevate_insights_text,
            },
            {
                header: strings.call_to_action_uncover_knowledge_header,
                text: strings.call_to_action_uncover_knowledge_text,
            },
            {
                header: strings.call_to_action_expand_horizons_header,
                text: strings.call_to_action_expand_horizons_text,
            },
            {
                header: strings.call_to_action_header_soft,
                text: strings.call_to_action_text,
            },
            {
                header: strings.call_to_action_register_header,
                text: strings.call_to_action_register_text,
            },
            {
                header: strings.call_to_action_header,
                text: strings.call_to_action_register_text,
            },
            {
                header: strings.call_to_action_register_test_header,
                text: strings.call_to_action_register_test_text,
            },
            {
                header: strings.call_to_action_try_solution_header,
                text: strings.call_to_action_try_solution_text,
            },
            {
                header: strings.call_to_action_get_test_access_header,
                text: strings.call_to_action_get_test_access_text,
            },
        ];
    
        // Select a random call to action from the array
        const randomIndex = Math.floor(Math.random() * allCallToActions.length);
        return allCallToActions[randomIndex];
    }
    
    const randomCallToAction = randomCallToActionAssignment();

    const handleSeeTestVersion = () => {
        logEvent(analytics, 'landing_page_register_click', { approach: "singular-information-source" });
    }

    return (
        <>
            <LandingPageHeader handleLandingPageClick={handleSeeTestVersion} />

            <Container fluid className='px-0'>
                <Row xs={1} sm={2} md={2} className='mx-auto my-auto px-5 py-3 landing-page-header-knowledge-base'>
                    <Col className='p-0 d-block d-sm-none d-md-none'>
                        <Image fluid src={collectionArticlesNetworkBrain} />
                    </Col>
                    <Col className='my-auto'>
                        <h1>{strings.header_information_source}</h1>
                        <p>{strings.header_information_source_text}</p>
                        <TestVersionButton handleLandingPageClick={handleSeeTestVersion} />
                    </Col>
                    <Col className='p-0 d-none d-sm-block d-md-block'>
                        <Image fluid src={collectionArticlesNetworkBrain} />
                    </Col>
                </Row>
            </Container>

            <Container fluid className='px-0 landing-page-benefits'>
                <Row className='text-center pb-0 pt-4 mx-5'>
                    <h2>{strings.one_place_for_all_header}</h2>
                </Row>
                <Row xs={1} sm={3} md={3} className='my-auto mx-5 introduction-group'>
                    {randomBenefits.map((benefit) =>
                        <BenefitCol
                            header={benefit.header}
                            text={benefit.text}
                            icon={benefit.icon} />
                    )}
                </Row>
            </Container>
            <Container>
                {randomFeatures.map((feature, index) =>
                    <FeatureRow
                        header={feature.header}
                        text={feature.text}
                        icon={feature.icon}
                        orientation={index % 2 === 0 ? "right" : "left"}
                        iconScale={feature.iconScale} 
                    />
                )}
            </Container>
            <CallToAction callToAction={randomCallToAction} handleSeeTestVersion={handleSeeTestVersion}/>

            <FooterFeed />

        </>
    )
};

// export const BenefitCol = ({ header, text, icon }: any) => {
//     return (
//         <Col className='p-4'>
//             <Image className='pt-4 px-4 pb-1' key="introImage" src={icon} fluid />
//             <h3>{header}</h3>
//             <p>{text}</p>
//         </Col>
//     )
// };

export const FeatureRow = ({ name, header, comingSoon=false, text, icon, orientation, iconScale, iconPaddingY=5 }: any) => {

    const handleLearnMore = () => {
        logEvent(analytics, 'learn_more_click', { feature: name });
    }
    
    return (
        <Row xs={1} sm={2} className='p-0'>

            <Col className={`mx-auto my-auto text-center pt-5 px-5 pb-1 d-block d-sm-none d-md-none`}>
                <Image src={icon} fluid height={iconScale} width={iconScale} />
            </Col>

            {orientation === "right" ? 
                <Col className={`mx-auto my-auto text-center px-5 py-${iconPaddingY} d-none d-sm-block d-md-block`}>
                    <Image src={icon} fluid height={iconScale} width={iconScale} />
                </Col>
                : null
            }
           
            <Col className='my-auto px-5 pt-2 pb-3'>
                {comingSoon && 
                    <Badge className='coming-soon-badge mb-1'>
                        {strings.coming_soon}
                    </Badge>
                }
                <h3>
                    {header}
                </h3>
                <p className='mb-0 feature-text'>
                    {text}
                </p>
                <LearnMore handleLandingPageClick={handleLearnMore}/>
            </Col>

            {orientation === "left" ? 
                <Col className={`mx-auto my-auto text-center px-5 py-${iconPaddingY} d-none d-sm-block d-md-block`}>
                    <Image src={icon} fluid height={iconScale} width={iconScale} />
                </Col>
                : null
            }

        </Row>
    )
};

export const CallToAction = ({callToAction, handleSeeTestVersion, handleTry}: any) => <Container fluid className='call-to-action p-5'>
    <Row>
        <Col className='mx-auto my-auto justify-content-center align-items-center text-align-center text-center'>
            <h4>
                {callToAction.header}
            </h4>
            <p>
                {callToAction.text}
            </p>
            <Stack direction='horizontal' gap={1} className='justify-content-center'>
                <TestVersionButton handleLandingPageClick={handleSeeTestVersion} />
                <TryButton handleLandingPageClick={handleTry} />
            </Stack>
        </Col>
    </Row>
</Container>