import {
  getFirestore,
  collection,
  getDoc,
  doc,
  setDoc,
  writeBatch,
  arrayUnion,
  QueryDocumentSnapshot,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs
} from 'firebase/firestore';
import { User, getAuth } from 'firebase/auth';
import { app } from '../firebase-config';
import { useEffect, useState } from 'react';
import {
  ContainerData,
  ContainerType,
  FirestoreConstants,
  userId,
  DocumentRef,
  CollectionRef,
  PathConstants,
  dbPath,
  StanceType,
  uuid
} from './dataModel.web';
import make_uuid from 'react-uuid';

export function getUserId(): userId {
  const userId = getAuth().currentUser?.uid;
  if (userId === undefined) {
    throw new Error('User is not logged in.');
  }
  return userId;
}

// Called once a user registered to check if the base structure of their data exists in the database.
// If it does not, it is created.
export const initializeUserData = async (userId: string) => {
  const userData = await getDoc(getUserDataRef(userId));

  if (!userData.exists()) {
    await setDoc(getUserDataRef(userId), {});

    const personalElementsRef = collection(getUserDataRef(userId), FirestoreConstants.PERSONAL_ELEMENTS);
    await setDoc(doc(personalElementsRef, FirestoreConstants.ROOT_ID), { childPaths: [] });
  }
};

export async function saveContainerToDB(container: ContainerData, stance: StanceType) {
  // TODO: Implement feedback for Errors.
  // TODO: prevent overriding of existing containers or at least make the container consistent and not mix properties of the old and new container. The former might require transactions instead of batch writes.
  if (container.getParentPaths().length === 0) {
    return;
  }

  const batch = writeBatch(getFirestore());

  batch.set(getRefFromDB(container.getPath()), container.getData());

  // TODO: For now, updating childs is too complicated as it requires knowledge about the stance of each child to the new parent. Could simply be neutral but probably will not need this right now.
  // for(const childPath of container.getChildPaths()) {
  //     batch.update(getPersonalStatementsRef().doc(childPath), {parentPaths: firebase.firestore.FieldValue.arrayUnion(container.getId())});
  // }

  // TODO: Missing proper handling of public/feed saves.
  for (const parentPath of container.getParentPaths()) {
    const childPath: { [key: dbPath]: StanceType } = {};
    childPath[container.getPath()] = stance;
    batch.update(getRefFromDB(parentPath), {
      childPaths: arrayUnion(childPath),
    });
  }

  batch.commit().catch(error => {
    console.error('Error saving container to DB: ', error);
  });
}

export function containerTypeToString(
  type: ContainerType,
  localizedStrings: any,
) {
  let result = localizedStrings.none;
  Object.entries(ContainerType).forEach(([key, value]) => {
    if (value === type) {
      result = localizedStrings[key.toLowerCase()];
    }
  });
  return result;
}

/**
 * Hook for getting the current user, which is updated when the user changes (logged in/out, different user).
 *
 * @returns [currentUser, setCurrentUser] Pair of the current user and a function to set the current user.
 */
export function useUserState(): User | null {
  const [currentUser, setCurrentUser] = useState(getAuth().currentUser);

  useEffect(() => {
    const subscriber = getAuth().onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return subscriber; // unsubscribe on unmount
  }, []);

  // TODO: Do I need to return the setter function?
  return currentUser;
}

export function getUserDataRef(userId: userId = getUserId()): DocumentRef {
  return doc(getFirestore(), FirestoreConstants.USER_DATA, userId);
}

export function getPersonalElementsRef(userId: userId = getUserId()): CollectionRef {
  return collection(getUserDataRef(userId), FirestoreConstants.PERSONAL_ELEMENTS);
}

export function getPublicDataRef(): CollectionRef {
  return collection(getFirestore(), FirestoreConstants.PUBLIC_DATA);
}

export function getFeedRef(): DocumentRef {
  return doc(getPublicDataRef(), FirestoreConstants.FEED);
}

export function getFeedElementsRef(): CollectionRef {
  return collection(getFeedRef(), FirestoreConstants.FEED_ELEMENTS);
}

export function getFeedRootElementsRef(): CollectionRef {
  return collection(getFeedRef(), FirestoreConstants.FEED_ROOT_ELEMENTS);
}

export function getRootElementsRef(path: dbPath): CollectionRef {
  if (path === PathConstants.PUBLIC) {
    return getFeedRootElementsRef();
  } else {
    throw new Error('Invalid path: ' + path);
  }
}

export function getLocationFromPath(path: dbPath): PathConstants {
  return path.split('_')[0] as PathConstants;
}

export function getTypeFromPath(path: dbPath): ContainerType {
  return path.split('_')[1].split('-')[0] as ContainerType;
}

export function getIdFromPath(path: dbPath): uuid {
  return path.split('_').pop() || '';
}

export function getRefFromDB(path: dbPath): DocumentRef {
  const id = getIdFromPath(path);
  const location = getLocationFromPath(path);
  if (location === PathConstants.PERSONAL) {
    return doc(getPersonalElementsRef(), id);
  } else if (location === PathConstants.PUBLIC) {
    return doc(getFeedElementsRef(), id);
  } else {
    throw new Error('Invalid path: ' + path);
  }
}

export function createPathWithUUID(
  location: PathConstants,
  typeId: ContainerType,
): uuid {
  return location + '_' + typeId + '-' + make_uuid();
}

export function createPersonalPathFromId(id: uuid): dbPath {
  return PathConstants.PERSONAL + '_' + id;
}

export function createPublicPathFromId(id: uuid): uuid {
  return PathConstants.PUBLIC + '_' + id;
}

export function convertToPublicPath(path: dbPath): dbPath | null {
  if (getIdFromPath(path) !== FirestoreConstants.ROOT_ID) {
    return createPublicPathFromId(getIdFromPath(path));
  } else {
    return null;
  }
}

export function useFeedLoader(initialEntries = 10): {
  rootPaths: dbPath[];
  loading: boolean;
  error: Error | undefined;
  reloadFeed: () => void;
  loadMoreEntries: (numberOfEntries: number) => void;
} {
  const [rootPaths, setRootPaths] = useState([] as uuid[]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined as Error | undefined);

  const [lastDoc, setLastDoc] = useState(
    undefined as QueryDocumentSnapshot | undefined,
  );
  const [firstDoc, setFirstDoc] = useState(
    undefined as QueryDocumentSnapshot | undefined,
  );
  const [endReached, setEndReached] = useState(false);

  const loadEntries = async (numberOfEntries: number, reload = false) => {
    // TODO: Will need ordering since order is not guaranteed.
    // TODO: Also needs improved reloading. Currently, it reloads the entire feed, which is not necessary.
    // BUG: It loads the same entries again when loading more entries. Ordering might fix this.
    if (endReached && !reload) {
      return;
    }
    if (loading) {
      return;
    } else {
      setLoading(true);
    }

    let q = query(getFeedElementsRef(),
      where('parentPaths', '==', []),
      orderBy('creationTime', 'desc'),
      limit(numberOfEntries));

    if (reload) {
      setLastDoc(undefined);
      setFirstDoc(undefined);
      setEndReached(false);
      setError(undefined);
    } else if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    const newPaths: uuid[] = [];

    try {
      const querySnapshot = await getDocs(q);
      // console.log('Query snapshot: ', querySnapshot);
      querySnapshot.forEach((doc) => {
        newPaths.push(createPublicPathFromId(doc.id));
      });
      if (newPaths.length > 0) {
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setFirstDoc(querySnapshot.docs[0]);
        if (reload) {
          setRootPaths(newPaths);
        } else {
          // console.log('Adding new paths: ', newPaths);
          setRootPaths(prevRootPaths => [...prevRootPaths, ...newPaths]);
        }
      } else {
        setEndReached(true);
      }
    } catch (error: any) {
      console.log('Error getting documents: ', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const reloadFeed = () => {
    loadEntries(initialEntries, true);
  };

  useEffect(() => {
    loadEntries(initialEntries, true);
  }, []);

  return {
    rootPaths: rootPaths,
    loading,
    error,
    reloadFeed,
    loadMoreEntries: loadEntries,
  };
}

export function pathIsPublic(path: dbPath): boolean {
  return path.split('_')[0] === PathConstants.PUBLIC;
}

export function pathIsRoot(path: dbPath): boolean {
  return path === PathConstants.PERSONAL || path === PathConstants.PUBLIC;
}

export async function publishSubtree(rootPath: dbPath): Promise<void> {
  const batch = writeBatch(getFirestore());

  const rootContainer = await ContainerData.getFromDB(rootPath);
  const childPaths = [...rootContainer.getChildPaths()];
  rootContainer.setParentPaths([]);

  batch.set(
    getRefFromDB(convertToPublicPath(rootPath)!),
    rootContainer.convertToPublicData().getData(),
  );

  // This does not terminate if there is a cycle in the tree.
  // Must be fixed in the future by checking if a path has already been visited.
  while (childPaths.length > 0) {
    const childPath = childPaths.pop();
    const childContainer = await ContainerData.getFromDB(childPath!)!;
    childPaths.push(...childContainer.getChildPaths());
    batch.set(
      getRefFromDB(convertToPublicPath(childPath!)!),
      childContainer.convertToPublicData().getData(),
    );
  }

  batch
    .commit()
    .then(() => {
      // console.log('Published subtree.');
      Promise.resolve();
    })
    .catch(error => {
      console.error('Error publishing subtree: ', error);
      Promise.reject(error);
    });
}

export async function getArgumentIds(statementId: string) { // TODO Adjust to new backend structure or delete
  let argumentIds: any = [];

  const db = getFirestore(app);

  const statementRef = doc(
    db,
    FirestoreConstants.USER_DATA,
    '5owDJddI31PmlHTQszxcqKcg3632',
    FirestoreConstants.PERSONAL_STATEMENTS,
    statementId,
  );
  const statementSnap = await getDoc(statementRef);

  if (statementSnap.exists()) {
    // console.log('Document data:', statementSnap.data());
    const children = await Promise.all(
      statementSnap.data().childrenIds.map(async (childId: string) => {
        const childSnap = await getDoc(
          doc(
            db,
            FirestoreConstants.USER_DATA,
            '5owDJddI31PmlHTQszxcqKcg3632',
            FirestoreConstants.PERSONAL_STATEMENTS,
            childId,
          ),
        );
        if (childSnap.exists()) {
          return childSnap.data();
        }
      }),
    );
    argumentIds = children.map((child: any) => {
      // TODO Change to object oriented syntax
      if (
        child.type === ContainerType.STATEMENT_GROUP ||
        child.type === ContainerType.STATEMENT_GROUP_ALTERNATIVE
      ) {
        return child.childrenIds;
      } else {
        return [child.id];
      }
    });
  } else {
    // docSnap.data() will be undefined in this case
    console.log('No such document!');
  }

  return argumentIds;
}

export async function getUserName(userId: userId = getUserId()): Promise<string> {
  const userDataReference = collection(getUserDataRef(userId), "data");
  const publicUserDataReference = doc(userDataReference, "public");
  const publicUserData = await getDoc(publicUserDataReference);
  return publicUserData.data()?.userName;
}