import Container from 'react-bootstrap/esm/Container';
import { Header } from '../components/Common/Header';
import { Row } from 'react-bootstrap';
import { FooterFeed } from '../components/Common/Footer';

export function PrivacyPolicyView(props: any) {

    return (
        <div>
            <Header topics={props.topics} />

            <Container className='p-5'>
                <Row>
                    <h1>Datenschutzerklärung</h1>

                    <p>
                        Wir legen großen Wert auf den Schutz Ihrer personenbezogenen Daten. Im Folgenden informieren wir Sie darüber, welche Daten wir erheben, wie wir sie verwenden und schützen. Diese Datenschutzerklärung entspricht dem deutschen Recht und insbesondere den Anforderungen der Datenschutz-Grundverordnung (DSGVO).
                    </p>
                    <h2>
                        Verantwortliche Stelle
                    </h2>
                    <p>
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                        <br/>
                        lucid-mind.eu
                        <br/>
                        Vincent Müller
                        <br/>
                        Schlüsselweg 58
                        <br/>
                        69221 Dossenheim
                        <br/>
                        Deutschland
                    </p>

                    <h2>
                        Erhebung und Verwendung von personenbezogenen Daten
                    </h2>
                    <h3>
                        a) Beim Besuch der Website
                    </h3>
                    <p>
                        Beim Besuch unserer Website erheben wir automatisch bestimmte Informationen, die von Ihrem Browser an unseren Server übermittelt werden. Diese Informationen können technischer Natur sein, wie zum Beispiel Ihre IP-Adresse, Ihr Browsertyp und -version, das Datum und die Uhrzeit des Zugriffs, die Referrer-URL (die zuvor besuchte Seite) sowie andere ähnliche Daten.
                    </p>
                    <p>
                        Diese Daten werden von uns nicht dazu verwendet, Sie persönlich zu identifizieren. Sie dienen dazu, die Website korrekt anzuzeigen, ihre Sicherheit und Stabilität zu gewährleisten und anonyme statistische Auswertungen durchzuführen.
                    </p>
                    <h3>
                        b) Bei Kontaktaufnahme
                    </h3>
                    <p>
                        Wenn Sie uns über die auf der Website bereitgestellten Kontaktformulare oder auf andere Weise kontaktieren, werden die von Ihnen angegebenen personenbezogenen Daten wie Ihr Name, Ihre E-Mail-Adresse oder Ihre Telefonnummer von uns erhoben und gespeichert. Diese Daten werden nur für die Bearbeitung Ihrer Anfrage verwendet und nicht an Dritte weitergegeben, sofern dies nicht zur Erfüllung Ihrer Anfrage erforderlich ist.
                    </p>
                    <h3>
                        c) Bei Newsletter-Abonnement
                    </h3>
                    <p>
                        Sofern Sie sich für unseren Newsletter anmelden, verwenden wir die von Ihnen angegebenen Daten (insbesondere Ihre E-Mail-Adresse) ausschließlich für den Versand des Newsletters. Ihre Daten werden nicht an Dritte weitergegeben und Sie können sich jederzeit vom Newsletter abmelden.
                    </p>
                   
                    <h2>Cookies</h2>
                    <p>
                        Wir verwenden auf unserer Website Cookies. Cookies sind kleine Textdateien, die von Ihrem Browser auf Ihrem Gerät gespeichert werden. Sie dienen dazu, die Nutzung unserer Website benutzerfreundlicher, effektiver und sicherer zu machen.
                    </p>
                    <p>
                        Sie können die Verwendung von Cookies in den Einstellungen Ihres Browsers kontrollieren und gegebenenfalls ablehnen. Bitte beachten Sie jedoch, dass dies die Funktionalität unserer Website beeinträchtigen kann.
                    </p>

                    <h2>
                        Datensicherheit
                    </h2>
                    <p>
                        Wir treffen geeignete technische und organisatorische Maßnahmen, um Ihre Daten gegen unbeabsichtigte oder unrechtmäßige Löschung, Veränderung, Weitergabe oder Zugriff zu schützen. Ihre Daten werden auf sicheren Servern gespeichert und sind vor unbefugtem Zugriff geschützt.
                    </p>

                    <h2>
                        Rechte der betroffenen Personen
                    </h2>
                    <p>
                        Gemäß der DSGVO haben Sie das Recht auf Auskunft über die von uns verarbeiteten personenbezogenen Daten, deren Berichtigung, Löschung oder Einschränkung der Verarbeitung sowie das Recht auf Datenübertragbarkeit. Darüber hinaus können Sie der Verarbeitung Ihrer Daten widersprechen und Ihre erteilte Einwilligung zur Verarbeitung jederzeit widerrufen.
                    </p>
                    <p>
                        Wenn Sie eines Ihrer Rechte ausüben möchten oder Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte an die oben genannte verantwortliche Stelle.
                    </p>

                    <h2>
                        Änderungen der Datenschutzerklärung
                    </h2>
                    <p>
                        Wir behalten uns das Recht vor, diese Datenschutzerklärung anzupassen, um sie an rechtliche Anforderungen oder Änderungen unseres Dienstes anzupassen. Die aktuelle Version der Datenschutzerklärung wird auf unserer Website veröffentlicht.
                    </p>

                    <p className='text-muted'>
                        Stand: 18.07.2023
                    </p>
                </Row>
            </Container>

            <FooterFeed />
                        
        </div>
    )
};