import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import React, { useContext, useState } from 'react';
import { InfoAlert } from './Common/InfoAlert';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';

export function SearchBar(props) {

    const strings = useContext(LocalizedStringsContext);

    const handleToggle = (event) => {
        console.log("Toggle");
        props.setSuggestionsShown(false);
    }

    const SearchInputToggle = React.forwardRef(({ children, parents, onClick }, ref) => {

        const handleChange = (event) => {
            event.preventDefault();
            if (!props.suggestionsShown) {
                if (event.target.value.length > 0) {
                    onClick(event);
                    props.setSuggestionsShown(true);
                }
            }
            props.setSearchValue(event.target.value);
        }

        return (

            <Form.Control
                className='search-bar mr-sm-2 w-auto'
                type='search'
                value={props.searchValue}
                placeholder={strings.search}
                aria-label='Search'
                onChange={handleChange}
                // autoFocus
            />
        )
    });

    const SearchResultsMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <ul className="list-unstyled m-0">
                    {/* {React.Children.toArray(children).filter(
                        (child) =>
                            !props.searchValue || child.props.children.toLowerCase().startsWith(props.searchValue.toLowerCase()),
                    )} */}
                    <InfoAlert content={strings.search_teaser}/>
                </ul>
            </div>
        )
    });


    return (
        <Dropdown onToggle={handleToggle}>
            <Dropdown.Toggle as={SearchInputToggle} id="dropdown-custom-components">
                Custom toggle
            </Dropdown.Toggle>

            <Dropdown.Menu as={SearchResultsMenu} >
                <Dropdown.Item eventKey="1">Klimawandel</Dropdown.Item>
                <Dropdown.Item eventKey="2">Soziale Medien</Dropdown.Item>
                <Dropdown.Item eventKey="3">Demokratie</Dropdown.Item>
                <Dropdown.Item eventKey="4">Vermögenssteuer</Dropdown.Item>
                <Dropdown.Item eventKey="5">Demographie</Dropdown.Item>
                <Dropdown.Item eventKey="6">Demonstrationsfreiheit</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>

    )
}