import { Col, Row, Image, Container } from "react-bootstrap"
// @ts-ignore
import logo from '../../LucidMindShared/res/LogoLM.png';
import { Link } from "react-router-dom";
import { LocalizedStringsContext } from "../../LucidMindShared/localization.web";
import { useContext } from "react";

export function Footer(props: any) {
    return (
        <footer className='footer fixed-bottom'>
            <div className="text-center p-3">
                © 2023 Copyright
            </div>
        </footer >
    )
}

export function FooterFeed(props: any) {

    const strings = useContext(LocalizedStringsContext);

    return (
        <footer className='footer'>
            <Container>

            <Row xs={1} sm={2} className='p-5'>
                <Col xs={6}>
                    <Col className=''>
                        <Link to={"/"}>
                            <Image className="mx-2" src={logo} height={`${40}px`} width={`${40}px`}/>
                        </Link>
                    </Col>
                    <Col className='footer-link'>
                        © 2024 Copyright 
                    </Col>    
                </Col>
                <Col xs={6}>
                    <Row xs={1}>
                        <Col>
                            <Link className="footer-link" to={"/impressum"}>
                                {strings.impressum}
                            </Link>
                        </Col>
                        <Col>
                            <Link className="footer-link" to={"/privacy"}>
                                {strings.privacy_policy}
                            </Link>
                        </Col>
                        {/* <Col>
                            <Link className="footer-link" to={"/agb"}>
                                {strings.agb}
                            </Link>
                        </Col> */}
                    </Row>
                </Col>
             </Row>
             </Container>

        </footer >
    )
}