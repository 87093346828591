import { useContext, useEffect, useState } from "react";
import { Button, Col, Image, Modal, ProgressBar, Row, Stack } from "react-bootstrap";
import { LocalizedStringsContext } from "../LucidMindShared/localization.web";
// @ts-ignore
import introductionStatementsImageDE from "../LucidMindShared/res/introductionStatementsDE.png";
// @ts-ignore
import introductionArgumentsImageDE from "../LucidMindShared/res/introductionArgumentsDE.png";
// @ts-ignore
import introductionPrivatePublicImageDE from "../LucidMindShared/res/introductionPrivatePublicDE.png";
// @ts-ignore
import introductionInteractionImageDE from "../LucidMindShared/res/introductionInteractionDE.png";
// @ts-ignore
import introductionChecksConflictsImageDE from "../LucidMindShared/res/introductionChecksConflictsDE.png";
// @ts-ignore
import introductionTestVersionImageDE from "../LucidMindShared/res/introductionTestVersionDE.png";
// @ts-ignore
import introductionStatementsImageEN from "../LucidMindShared/res/introductionStatementsEN.png";
// @ts-ignore
import introductionArgumentsImageEN from "../LucidMindShared/res/introductionArgumentsEN.png";
// @ts-ignore
import introductionPrivatePublicImageEN from "../LucidMindShared/res/introductionPrivatePublicEN.png";
// @ts-ignore
import introductionInteractionImageEN from "../LucidMindShared/res/introductionInteractionEN.png";
// @ts-ignore
import introductionChecksConflictsImageEN from "../LucidMindShared/res/introductionChecksConflictsEN.png";
// @ts-ignore
import introductionTestVersionImageEN from "../LucidMindShared/res/introductionTestVersionEN.png";

interface IntroductionModalProps {externalShow: boolean}
export function IntroductionModal({externalShow}: IntroductionModalProps) {
    const [show, setShow] = useState(externalShow);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [slideIndex, setSlideIndex] = useState(0);
    const lastSlideIndex = 5;
    const firstSlideIndex = 0;
    const handleNext = () => setSlideIndex(slideIndex + 1);
    const handlePrevious = () => setSlideIndex(slideIndex - 1);
    const strings = useContext(LocalizedStringsContext);
    const handleContinue = () => handleClose();

    useEffect(() => {
        setShow(externalShow);
    }, [externalShow]);
    
    return (
      <>
        {/* <Button variant="primary" onClick={handleShow}>
        </Button> */}
  
        <Modal show={show} onHide={handleClose} centered className="border-0">
          <Modal.Header closeButton  className="border-0 pb-0">
            <Modal.Title>Introduction</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-3 py-0">
            <ProgressBar now={(slideIndex +  1 / lastSlideIndex + 1) * 100 / 6} />
            <IntroductionSlider slideIndex={slideIndex}/>
          </Modal.Body>
          <Modal.Footer  className="border-0">
            <Row xs={1} sm={2} className="w-100">
                <Col xs={12} sm={6} className="p-1">
                    {slideIndex === firstSlideIndex ? 
                        <Button className="invisible w-100">
                            {strings.previous}
                        </Button>
                        :
                        <Button onClick={handlePrevious} className="w-100">
                            {strings.previous}
                        </Button>
                    }
                </Col>
                <Col xs={12} sm={6} className="p-1">
                    {slideIndex === lastSlideIndex ?
                        <Button onClick={handleContinue} className="w-100">
                            {strings.continue}
                        </Button>
                        :
                        <Button onClick={handleNext} className="w-100">
                            {strings.next}
                        </Button>
                    }
                </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

interface IntroductionSliderProps {slideIndex: Number}
export function IntroductionSlider ({slideIndex = 0}: IntroductionSliderProps) {

    const strings = useContext(LocalizedStringsContext);
    const language = strings.getInterfaceLanguage().split("-")[0];
    let content;

    switch (slideIndex) {
        case 0:
            content = <>
            {language === "de" ? 
                    <Image key="introImage" src={introductionStatementsImageDE}/>
                    :
                    <Image key="introImage" src={introductionStatementsImageEN}/>
                }
                <p className="text-center m-0" key="intro-text" id="intro-text">
                    {strings.introduction_statements.split("\n").map(line =>
                        <>
                            {line}
                            <br/>
                        </>
                    )}
                </p>
            </>;
            break;
        case 1:
            content = <>
                {language === "de" ? 
                    <Image key="introImage" src={introductionArgumentsImageDE}/>
                    :
                    <Image key="introImage" src={introductionArgumentsImageEN}/>
                }
                <p className="text-center m-0" key="intro-text" id="intro-text">
                    {strings.introduction_arguments.split("\n").map(line =>
                            <>
                                {line}
                                <br/>
                            </>
                    )}
                </p>
            </>;
            break;
        case 2:
            content = <>
                {language === "de" ? 
                    <Image key="introImage" src={introductionPrivatePublicImageDE}/>
                    :
                    <Image key="introImage" src={introductionPrivatePublicImageEN}/>
                }
                <p className="text-center m-0" key="intro-text" id="intro-text">
                    {strings.introduction_private_public.split("\n").map(line =>
                        <>
                            {line}
                            <br/>
                        </>
                    )}
                </p>
            </>;
            break;
        case 3:
            content = <>
                {language === "de" ? 
                    <Image key="introImage" src={introductionInteractionImageDE}/>
                    :
                    <Image key="introImage" src={introductionInteractionImageEN}/>
                }
                <p className="text-center m-0" key="intro-text" id="intro-text">
                    {strings.introduction_interaction.split("\n").map(line =>
                        <>
                            {line}
                            <br/>
                        </>
                    )}
                </p>
            </>;
            break;
        case 4:
            content = <>
                {language === "de" ? 
                    <Image key="introImage" src={introductionChecksConflictsImageDE}/>
                    :
                    <Image key="introImage" src={introductionChecksConflictsImageEN}/>
                }
                <p className="text-center m-0" key="intro-text" id="intro-text">
                    {strings.introduction_checks_conflicts.split("\n").map(line =>
                        <>
                            {line}
                            <br/>
                        </>
                    )}
                </p>
            </>;
            break;
        case 5:
            content = <>
                {language === "de" ? 
                    <Image key="introImage" src={introductionTestVersionImageDE}/>
                    :
                    <Image key="introImage" src={introductionTestVersionImageEN}/>
                }
                <p className="text-center m-0" key="intro-text" id="intro-text">
                    {strings.introduction_test_version.split("\n").map(line =>
                        <>
                            {line}
                            <br/>
                        </>
                    )}
                </p>
            </>;
            break;
    
        default:
            break;
    }

    return (
        <Stack gap={0} className="p-0">
            {content}
        </Stack>
    )
}