import { MarkerType } from "reactflow";

export function convertToRadians(angle_degrees) {
    return angle_degrees / 360 * 2 * Math.PI;
}

export function convertToDegree(angle_radians) {
    return angle_radians * 180 / Math.PI;
}

export function getEdge(sourceId: string, targetId: string, argumentType: string = "default", edgeType: string = "straight", withArrowHead: boolean = true) {
    const edgeColor = argumentType === "default" ? "green" : argumentType === "negation" ? "red" : "grey";
    return {
        id: sourceId + "-" + targetId,
        source: sourceId.toString(),
        target: targetId.toString(),
        type: edgeType,
        style: {
            stroke: edgeColor
        },
        markerStart: withArrowHead ? {
            type: MarkerType.ArrowClosed,
            color: edgeColor
        } : undefined
    };
}

export function getSimulatedCheckResult(returnValue: boolean = true, timeout: number = 1500) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          checkSuccessful: returnValue
        })
      }, timeout)
    })
  }