import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Component, useRef, useEffect, useState } from 'react';
import { SearchBar } from '../SearchBar';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { Image } from 'react-bootstrap';
// @ts-ignore
import logo from '../../LucidMindShared/res/LogoLM.png';
// @ts-ignore
import logoWithText from '../../LucidMindShared/res/logoWithText.png';
import { useUserState } from '../../LucidMindShared/helperFunctions.web';
import { TestVersionButton } from './Buttons';

const iconSizeNavigationBar = 25;

export function Header(props: any) {

    const [searchValue, setSearchValue] = useState('');
    const [searchSuggestionsShown, setSearchSuggestionsShown] = useState(false);

    const [showLoginRegisterModal, setShowLoginRegisterModal] = useState(false);

    const handleCloseLoginRegisterModal = () => setShowLoginRegisterModal(false);
    const handleShowLoginRegisterModal = () => setShowLoginRegisterModal(true);
    const user = useUserState()

    const landingPageActive = window.location.href.endsWith("/");

    return (
        <>
            <Navbar sticky='top' className='py-1'>
                <Container fluid>
                    <Navbar.Brand href='/' className='mx-2'>
                        {landingPageActive ? 
                            <>
                                <Image className="mx-2" src={logoWithText} height="20%" width="20%"/>
                            </>
                            :
                            <>
                                <Image className="mx-2" src={logo} height="40px" width="40px"/>
                            </>
                        }
                    </Navbar.Brand>

                    {props.hideSearchBar || landingPageActive ? null :
                        <SearchBar
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            suggestionsShown={searchSuggestionsShown}
                            setSuggestionsShown={setSearchSuggestionsShown} // TODO Rename
                        />
                    }

                    <Nav>
                        {/* <Row>
                            <Container>
                                <Row className='mx-2'>
                                    <Col>
                                        <NavDropdown title="TestViews">
                                            <ListGroup>
                                                <ListGroup.Item key="statement">
                                                    <Link to='/statement'>
                                                        Statement
                                                    </Link>
                                                </ListGroup.Item>
                                                <ListGroup.Item key="world">
                                                    <Link to='/world'>
                                                        World
                                                    </Link>
                                                </ListGroup.Item>
                                                <ListGroup.Item key="topics">
                                                    <Link to='/topics'>
                                                        Topics
                                                    </Link>
                                                </ListGroup.Item>
                                                <ListGroup.Item key="critisize">
                                                    <Link to='/critisize'>
                                                        Critisize
                                                    </Link>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </NavDropdown>
                                    </Col>
                                    <Col className="mx-1">
                                        <Link to='/' className='nav-link'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={iconSizeNavigationBar * 0.9}
                                                height={iconSizeNavigationBar * 0.9} fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </Link>
                                    </Col>
                                    <Col className="mx-1">
                                        <Link to='/myWorldView' className='nav-link'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-diagram-3" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
                                            </svg>
                                        </Link>
                                    </Col>
                                    <Col className="mx-1">
                                        <Link to='/profile' className='nav-link'>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                width={iconSizeNavigationBar}
                                                height={iconSizeNavigationBar} fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                            </svg>
                                        </Link>
                                    </Col>
                                </Row>
                            </Container>
                        </Row> */}
                        {landingPageActive ? 
                            <div className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                <TestVersionButton/>
                            </div>
                            :
                            null
                        }
                    </Nav>
                </Container>
            </Navbar >

            <Modal show={showLoginRegisterModal} onHide={handleCloseLoginRegisterModal} backdrop='static' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Anmelden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>E-Mail Adresse</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Password</Form.Label>
                            <Form.Control type='password' />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseLoginRegisterModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleCloseLoginRegisterModal}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}