import Container from 'react-bootstrap/esm/Container';
import { Button, Card, Col, Form, Image, Row, Stack } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { LocalizedStringsContext, strings } from '../LucidMindShared/localization.web';
import { AddArgumentButton, AddConditionButton, CancelButton, ConfirmButton, EditButton, RemoveButton, SwitchStanceButton, TestVersionButton, TryButton } from '../components/Common/Buttons';
// @ts-ignore
import collectionSourceToArguments from "../LucidMindShared/res/collectionSourceToArguments.png";
// @ts-ignore
import extractStatementsPlugin from "../LucidMindShared/res/extractStatementsPlugin.png";
// @ts-ignore
import orderThoughts from "../LucidMindShared/res/orderThoughts.png";
// @ts-ignore
import generateManuscript from "../LucidMindShared/res/generateManuscript.png";
import { CallToAction, FeatureRow } from './LandingPageSingularInformationSource';
import { FooterFeed } from '../components/Common/Footer';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';
import { LandingPageHeader } from '../components/Common/LandingPageHeader';
import { BenefitCol } from './BenefitCol';
import { Statement } from '../model';
import { StanceType } from '../LucidMindShared/dataModel.web';
// @ts-ignore
import syllogismWithPlaceholderWithIcons from "../LucidMindShared/res/syllogismWithPlaceholderWithIcons.png";
// @ts-ignore
import collectionSyllogismNetworkCubes from "../LucidMindShared/res/collections/collectionSyllogismNetworkCubes.png";
// @ts-ignore
import feedArticleArgumentMockup from "../LucidMindShared/res/feedArticleArgumentMockup.png";

export function FeaturePageLogicalStructure() {

    const browserPluginFeature = "browser-plugin";
    const logicalStructureFeature = 'logical-structure';
    const approach = logicalStructureFeature;

    const strings = useContext(LocalizedStringsContext);
    const allHeaders = [
        {
            header: "Verkürze Deine Recherchezeit",
            text: "Extrahiere Aussagen von Quellen, ordne Aussagen zu Argumenten und generiere ein Manuskript in Windeseile.",
            icon: collectionSourceToArguments,
            iconPadding: 0,
            approaches: [browserPluginFeature]
        },
        {
            header: strings.headers.structureKnowledge.header,
            text: strings.headers.structureKnowledge.text,
            icon: collectionSyllogismNetworkCubes,
            iconPadding: 5,
            approaches: [logicalStructureFeature]
        },
    ];
    const allFeatures = [
        {
            name: "statementExtractionPlugin",
            content: [
                {
                    header: "Schritt 1: Von Quelle zu Aussage in Sekundenschnelle",
                    text: "Extrahiere Aussagen aus Quellen im Browser und füge sie deinem Projekt hinzu.",
                    icon: extractStatementsPlugin,
                    iconScale: "100%",
                    approaches: [browserPluginFeature]
                }
            ]
        },
        {
            name: "structureKnowledge",
            content: [
                {
                    header: strings.features.argumentNetwork.structureKnowledge.header,
                    text: strings.features.argumentNetwork.structureKnowledge.text,
                    icon: syllogismWithPlaceholderWithIcons,
                    iconScale: "100%",
                    approaches: [logicalStructureFeature]
                }
            ]
        },
        {
            name: "structureThoughtsInSyllogisms",
            content: [
                {
                    header: "Schritt 2: Ordne deine Gedanken in Argumente",
                    text: "Strukturiere in Quellen gefundene Aussagen in Syllogismen, um die Übersicht zu behalten und trennscharf zu argumentieren.",
                    icon: orderThoughts,
                    iconScale: "100%",
                    approaches: [browserPluginFeature]
                },
            ]
        },
        {
            name: "generateDraftManuscript",
            content: [
                {
                    header: "Schritt 3: Manuskript-Entwurf im Handumdrehen",
                    text: "Generiere einen Entwurf deines Manuskripts anhand deiner Argumente.",
                    icon: generateManuscript,
                    iconScale: "100%",
                    approaches: [browserPluginFeature]
                },
            ]
        },
        {
            name: "shareArguments",
            content: [
                {
                    header: strings.features.sharedArguments.shareAndDiscuss.header,
                    text: strings.features.sharedArguments.shareAndDiscuss.text,
                    icon: feedArticleArgumentMockup,
                    iconScale: "50%",
                    approaches: [logicalStructureFeature]
                },
            ]
        },
    ];
    const allCallsToAction = [
        {
            header: strings.call_to_action_get_test_access_header,
            text: "",
            approaches: [browserPluginFeature, logicalStructureFeature]
        },
    ];

    const getRandomObjects = (array: Array<Object>, numberOfRandomObjects: number): any => {
        if (numberOfRandomObjects === 1) {
            const randomIndex = Math.floor(Math.random() * array.length);
            return array[randomIndex];
        } else {
            // Return the first objects
            return array.slice(0, numberOfRandomObjects);
        }
    }

    const randomHeaderAssignment = () => {
        const allHeadersForApproach = allHeaders.filter(header => header.approaches.includes(approach));
        return getRandomObjects(allHeadersForApproach, 1);
    }

    const randomFeatureAssignment = () => {
        const featuresForApproach = allFeatures.filter(feature =>
            feature.content.filter((feature: any) =>
                feature.approaches.includes(approach)
            ).length > 0
        );
        featuresForApproach.forEach((feature: any) =>
            feature.content = feature.content.filter((feature: any) =>
                feature.approaches.includes(approach)
            )
        );
        const randomFeaturesForApproachAllContent = getRandomObjects(featuresForApproach, 3);
        console.log(randomFeaturesForApproachAllContent)
        const randomFeaturesForApproachRandomContent = randomFeaturesForApproachAllContent.map((feature: any) => getRandomObjects(feature.content, 1));
        console.log(randomFeaturesForApproachRandomContent);
        const randomNamedFeaturesForApproachRandomContent = randomFeaturesForApproachAllContent.map((feature: any, index: any) => {
            return {
                name: feature.name,
                header: randomFeaturesForApproachRandomContent[index].header,
                text: randomFeaturesForApproachRandomContent[index].text,
                icon: randomFeaturesForApproachRandomContent[index].icon,
                iconScale: randomFeaturesForApproachRandomContent[index].iconScale,
                approaches: randomFeaturesForApproachRandomContent[index].approaches
            }

        }
        );
        console.log(randomNamedFeaturesForApproachRandomContent);
        return randomNamedFeaturesForApproachRandomContent;
    }

    const randomCallToActionAssignment = () => {
        const allCallsToActionForApproach = allCallsToAction.filter(callToAction => callToAction.approaches.includes(approach));
        return getRandomObjects(allCallsToActionForApproach, 1);
    }

    const randomHeader = randomHeaderAssignment();
    const randomCallToAction = randomCallToActionAssignment();
    const randomFeatures = randomFeatureAssignment();

    const handleSeeTestVersion = () => {
        logEvent(analytics, 'landing_page_register_click', { approach: approach });
    }

    const handleTry = () => {
        logEvent(analytics, 'landing_page_try_click', { approach: approach });
    }

    return (
        <>
            <LandingPageHeader handleLandingPageClick={handleSeeTestVersion} />
            <LandingPageSubHeader header={randomHeader} handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry} />
            <LogicalStructureDemo />
            <Container>
                {randomFeatures.map((feature: any, index: any) =>
                    <FeatureRow
                        header={feature.header}
                        text={feature.text}
                        icon={feature.icon}
                        orientation={index % 2 === 0 ? "right" : "left"}
                        iconScale={feature.iconScale}
                    />
                )}
            </Container>
            <CallToAction callToAction={randomCallToAction} handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry} />
            <FooterFeed />
        </>
    )
};

interface LandingPageSubHeaderProps { header: any, handleSeeTestVersion: Function, handleTry: Function }
export function LandingPageSubHeader({ header, handleSeeTestVersion, handleTry }: LandingPageSubHeaderProps) {

    return (
        <Container fluid className='px-0'>
            <Row xs={1} sm={2} md={2} className='mx-auto my-auto px-5 py-3 pb-5 landing-page-header-knowledge-base'>
                <Col className={`p-1 p-md-${header.iconPadding} d-block d-sm-none d-md-none`}>
                    <Image fluid src={header.icon} />
                </Col>
                <Col className='my-auto p-0 p-md-5'>
                    <h1>{header.header}</h1>
                    <p>{header.text}</p>
                    <Stack direction='horizontal' gap={1}>
                        <TestVersionButton handleLandingPageClick={handleSeeTestVersion} />
                        <TryButton handleLandingPageClick={handleTry} />
                    </Stack>
                </Col>
                <Col className={`p-1 p-md-${header.iconPadding} d-none d-sm-block d-md-block`}>
                    <Image fluid src={header.icon} />
                </Col>
            </Row>
        </Container>
    )
}

interface LandingPageBenefitsProps { subHeader: any, benefits: any }
export function Benefits({ subHeader, benefits }: LandingPageBenefitsProps) {

    return (
        <Container fluid className='pt-1 pb-5 px-0 px-md-5 landing-page-benefits'>
            <Row className='pb-0 pt-5 mx-5'>
                <Col>
                    <h2>{subHeader.header}</h2>
                    {/* <p>{subHeader.text}</p> */}
                </Col>
                <Col>

                </Col>
            </Row>
            <Row xs={1} sm={3} md={3} className='my-auto mx-5 introduction-group'>
                {benefits.map((benefit: any) =>
                    <BenefitCol
                        header={benefit.header}
                        text={benefit.text}
                        icon={benefit.icon} />
                )}
            </Row>
        </Container>
    )
}

export function LogicalStructureDemo() {

    const [conclusion, setConclusion] = useState(strings.demo.logicalStructure.socrates_is_mortal);
    const [conclusionArguments, setConclusionArguments] = useState([
        {
            id: 0,
            stance: StanceType.SUPPORT,
            conditions: [strings.demo.logicalStructure.socrates_is_a_men, strings.demo.logicalStructure.all_ment_are_mortal]
        }
    ]);
    const [lastArgumentId, setLastArgumentId] = useState(0);

    function handleAddArgument(): void {
        setConclusionArguments((previousArguments) => [
            ...previousArguments,
            {
                id: lastArgumentId + 1,
                stance: StanceType.SUPPORT,
                conditions: [strings.demo.logicalStructure.socrates_is_a_men, strings.demo.logicalStructure.all_ment_are_mortal]
            }
        ]);
        setLastArgumentId(lastArgumentId + 1);
    }

    function handleRemoveArgument(desiredArgumentIndex: number) {
        const newArguments = conclusionArguments.filter((argument, argumentIndex) =>
            desiredArgumentIndex !== argument.id);
        setConclusionArguments(newArguments);
    }

    function handleSwitchArgumentStance(desiredArgumentIndex: number): void {
        setConclusionArguments((previousArguments) => previousArguments.map((argument) =>
        ({
            id: argument.id,
            stance: argument.id !== desiredArgumentIndex ?
                argument.stance : argument.stance === StanceType.SUPPORT ?
                    StanceType.OPPOSE : StanceType.SUPPORT,
            conditions: argument.conditions
        })
        ));
    }

    function handleAddCondition(argumentId: Number): void {
        setConclusionArguments((previousArguments) => previousArguments.map((argument) =>
        ({
            id: argument.id,
            stance: argument.stance,
            conditions: argument.id === argumentId ? argument.conditions.concat("") : argument.conditions
        })
        ));
    }

    function handleRemoveCondition(argumentId: Number, desiredConditionIndex: number): void {
        console.log(conclusionArguments, argumentId);
        setConclusionArguments((previousArguments) => previousArguments.map((argument) =>
        ({
            id: argument.id,
            stance: argument.stance,
            conditions: argument.id === argumentId ? argument.conditions.filter(
                (condition, conditionIndex) => desiredConditionIndex !== conditionIndex
            ) : argument.conditions
        })
        ));
        console.log(conclusionArguments);
    }

    function handleChangeCondition(argumentId: Number, desiredConditionIndex: number, content: string): void {
        console.log("handleChangeConditoin", conclusionArguments)
        setConclusionArguments((previousArguments) => previousArguments.map((argument) =>
        ({
            id: argument.id,
            stance: argument.stance,
            conditions: argument.id === argumentId ? argument.conditions.map(
                (condition, conditionIndex) => desiredConditionIndex === conditionIndex ? content : condition
            ) : argument.conditions
        })
        ));
        console.log("handleChangeConditoin2", conclusionArguments)

    }

    function handleConclusionChange(id: Number, content: string) {
        setConclusion(content);
    }

    return (
        <Container className='px-sm-0 px-md-3 px-lg-5 py-5 demo-container'>
            <Card className='demo-card mx-sm-1 mx-md-3 mx-lg-5 px-sm-3 px-md-3 px-lg-5 py-sm-3 py-md-3 py-lg-3'>
                <Card.Body className='px-sm-5 px-md-5 px-lg-3 py-sm-3 py-md-3 py-lg-3'>
                    <h2 className='mb-4 mt-3'>
                        {strings.demo.logicalStructure.title}
                    </h2>
                    <Row className='my-3'>
                        <Col>
                            <StatementLightweight content={conclusion} id={9999} handleStatementChange={handleConclusionChange} handleAddArgument={handleAddArgument} />
                        </Col>
                    </Row>
                    <Row xs={1} md={conclusionArguments.length > 1 ? 2 : 1}>
                        {conclusionArguments.map((argument) =>
                            <Col className='my-2'>
                                <ArgumentLightweight
                                    conditions={argument.conditions}
                                    handleRemoveArgument={() => handleRemoveArgument(argument.id)}
                                    handleChangeCondition={handleChangeCondition}
                                    id={argument.id} handleAddCondition={handleAddCondition}
                                    handleRemoveCondition={handleRemoveCondition}
                                    stance={argument.stance}
                                    handleSwitchStance={handleSwitchArgumentStance} />
                            </Col>
                        )}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}

const StatementLightweight = ({ id, content, editableExternal, removable, handleStatementChange, handleRemove, handleAddArgument }: { id: Number, content: string, editableExternal?: boolean, removable?: boolean, handleStatementChange: Function, handleRemove?: Function, handleAddArgument?: Function }) => {

    const handleFormChange = ({ target: { value } }: any) => {
        handleStatementChange(id, value);
    }

    return (
        <Card className="statement border border-0 border-secondary rounded-4">
            <Card.Body className="pb-0">
                <Form>
                    <Form.Group controlId="statementContent">
                        <Form.Control
                            onChange={handleFormChange}
                            value={content}
                            type="statement"
                            placeholder={strings.add_statement_content_hint}
                            as="textarea"
                            rows={2}
                            plaintext
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent pt-0">
                <Stack direction="horizontal">
                    <div className="ms-auto" >
                        {removable && <RemoveButton onClick={() => handleRemove ? handleRemove() : null} />}
                    </div>
                    {handleAddArgument && <div className="ms-auto" >
                        <AddArgumentButton onClick={handleAddArgument} />
                    </div>}
                </Stack>
            </Card.Footer>
        </Card>
    )
}

const ArgumentLightweight = ({ id, stance, conditions, handleChangeCondition, handleRemoveArgument, handleAddCondition, handleRemoveCondition, handleSwitchStance }: { id: number, stance: StanceType, conditions: string[], handleChangeCondition: Function, handleRemoveArgument: Function, handleAddCondition: Function, handleRemoveCondition: Function, handleSwitchStance: Function }) => {

    const removable = true;

    const onChangeCondition = (conditionId: number, content: string) => {
        handleChangeCondition(id, conditionId, content);
    }

    return (
        <Card className={stance === StanceType.SUPPORT ? "conditions-card conditions-card-pro" : "conditions-card conditions-card-contra"}>
            <Card.Body className="conditions-card-body pb-0">
                <Row className="align-items-center">
                    <Col>
                        <Stack gap={2}>
                            {conditions.map((condition: string, conditionIndex: number) =>
                                <StatementLightweight id={conditionIndex} content={condition} removable={true} handleRemove={() => handleRemoveCondition(id, conditionIndex)} key={conditionIndex} handleStatementChange={onChangeCondition} />
                            )}
                        </Stack>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="border-0 bg-transparent pt-0" >
                <Stack direction="horizontal" className='justify-content-between mx-4'>

                    <div className="" >
                        <AddConditionButton onClick={() => handleAddCondition(id)} />
                    </div>

                    <div className="" >
                        <SwitchStanceButton onClick={() => handleSwitchStance(id)} />
                    </div>

                    <div className="" >
                        {removable && <RemoveButton onClick={() => handleRemoveArgument ? handleRemoveArgument() : null} />}
                    </div>

                </Stack>
            </Card.Footer>
        </Card >
    )
}