// import logo from './assets/logo.svg';
// import shield from './assets/shield/shield.png'; // https://www.flaticon.com/free-icon/shield_1489596?term=trust&related_id=1489596 <a href="https://www.flaticon.com/free-icons/trust" title="trust icons">Trust icons created by berkahicon - Flaticon</a>
// import trust from './assets/trust/like.png'; // https://www.flaticon.com/free-icon/like_929417?term=heart&page=1&position=53&page=1&position=53&related_id=929417&origin=search <a href="https://www.flaticon.com/free-icons/heart" title="heart icons">Heart icons created by Freepik - Flaticon</a>
// import heart_stroke from './assets/trust/like_stroke.png'; // https://www.flaticon.com/free-icon/like_929417?term=heart&page=1&position=53&page=1&position=53&related_id=929417&origin=search <a href="https://www.flaticon.com/free-icons/heart" title="heart icons">Heart icons created by Freepik - Flaticon</a>
// import megaphone from './assets/megaphone/megaphone_outline.png'; // https://www.flaticon.com/free-icon/megaphone_1742320?related_id=1742320 <a href="https://www.flaticon.com/free-icons/megaphone" title="megaphone icons">Megaphone icons created by Freepik - Flaticon</a>
// import megaphoneColor from './assets/megaphone/megaphone_color.png'; // https://www.flaticon.com/free-icon/megaphone_1742320?related_id=1742320 <a href="https://www.flaticon.com/free-icons/megaphone" title="megaphone icons">Megaphone icons created by Freepik - Flaticon</a>
// import myWorld from './assets/people/people.png'; // https://www.flaticon.com/free-icon/people_4944520?related_id=4944500&origin=search&k=1665035968768&log-in=google <a href="https://www.flaticon.com/free-icons/decide" title="decide icons">Decide icons created by Freepik - Flaticon</a>
// import world from './assets/logical_thinking/logical-thinking_outline.png'; // https://www.flaticon.com/free-icon/logical-thinking_4155279?related_id=4155279&origin=search <a href="https://www.flaticon.com/free-icons/reason" title="reason icons">Reason icons created by Witdhawaty - Flaticon</a>
// import critisize from './assets/critisize/settings.png';  // https://www.flaticon.com/free-icon/settings_868829?related_id=868778&origin=search <a href="https://www.flaticon.com/free-icons/idea" title="idea icons">Idea icons created by Freepik - Flaticon</a>
// import plus from './assets/plus-svgrepo-com.svg';
// import lightBulb from './assets/lightBulb/idea.png'; // https://www.flaticon.com/free-icon/idea_841539?term=light%20bulb&related_id=841539 <a href="https://www.flaticon.com/free-icons/idea" title="idea icons">Idea icons created by Freepik - Flaticon</a>
// import lightBulbStroke from './assets/lightBulb/ideaStroke.png'; // https://www.flaticon.com/free-icon/idea_841539?term=light%20bulb&related_id=841539 <a href="https://www.flaticon.com/free-icons/idea" title="idea icons">Idea icons created by Freepik - Flaticon</a>
import './App.css';
import { Component, useEffect, useState, useContext } from 'react';
import { User } from './model';
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
// import { WorldView } from './pages/WorldView';
import { Login } from './pages/LoginView';
import {
  getAuth,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence
} from 'firebase/auth';
import { ProfileView } from './pages/ProfileView';
import { Feed } from './pages/FeedView';
import { StatementView } from './pages/StatementView';
// import { ThinkView } from './pages/ResolveView';
import { TopicView } from './pages/TopicView';
import { MyWorldView } from './pages/MyWorldView';
import { LocalizedStringsContext } from './LucidMindShared/localization.web';
import { RegisterView } from './pages/RegisterView';
import dayjs from "dayjs";
import { PrivacyPolicyView } from './pages/PrivacyPolicyView';
import { ImpressumView } from './pages/ImpressumView';
import { LandingPageReasonablePoliticalDebateView } from './pages/LandingPageReasonablePoliticalDebate';
import { LandingPageLogicalKnowledgeBaseView } from './pages/LandingPageLogicalKnowledgeBase';
import { LandingPageSingularInformationSourceView } from './pages/LandingPageSingularInformationSource';
import { LandingPageMain } from './pages/LandingPageMain';
import { analytics } from './firebase-config';
import { logEvent } from 'firebase/analytics';
import { LandingPage } from './pages/LandingPage';
import { FeaturePageBrowserPlugin } from './pages/FeaturePageBrowserPlugin';
import { LandingPageGeneralPublic } from './pages/LandingPageGeneralPublic';
import { FeaturePageLogicalStructure } from './pages/FeaturePageLogicalStructure';
// @ts-ignore
require('dayjs/locale/de')


// let topicId = 0;
// const getTopicId = () => `${topicId++}`;

// TODO Explore draggable elements
// TODO Check out https://eliav2.github.io/react-xarrows/

function Main(props: any) { // TODO Merge with App to one function as constructor is not needed anymore

  const strings = useContext(LocalizedStringsContext);
  strings.setLanguage(strings.getInterfaceLanguage().split("-")[0]);
  dayjs.locale((strings.getInterfaceLanguage().split("-")[0]));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();

  onAuthStateChanged(getAuth(), authStateObserver);

  function authStateObserver(user: any) {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      console.log("User signed in.");
      if ((window.location.href.endsWith("/login") || window.location.href.endsWith("/register"))) {
        navigate('/personal');
      }
    } else {
      console.log("User not signed in.");
      if (
        (
          window.location.href.includes("/public") ||
          window.location.href.includes("/personal") ||
          window.location.href.includes("/profile") ||
          window.location.href.includes("/statement/") ||
          window.location.href.includes("/topic/")
        )
      ) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {

    // if (useTestUser) {
    //   setEmail(testUserEMail);
    //   setPassword(testUserPassword);
    // }

  }, [])

  const handleLogin = () => {
    // console.log(email, password);
    const authentication = getAuth();
    if (email === '' || password === '') {
      alert("Bitte gib deine E-Mailadresse und Passwort ein.");
      return;
    }

    logEvent(analytics, 'login_click');

    setPersistence(authentication, browserLocalPersistence)
      .then(() => {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        signInWithEmailAndPassword(authentication, email, password)
          .then((userCredential) => {
            // console.log(userCredential);
            navigate('/personal');
            // sessionStorage.setItem('Auth Token', userCredential._tokenResponse.refreshToken);
          })
          .catch(error => {
            if (error.code === 'auth/invalid-email') {
              alert('Bitte gib eine gültige E-Mailadresse ein!');
            } else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
              alert('Falsche E-Mailadresse oder Passwort!');
            } else {
              alert("Error:" + error);
            }
          });
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
      });

  }

  // const handleLogout = async () => {
  //   const authentication = getAuth();
  //   await signOut(authentication);
  // }

  return (
    <Routes>
      <Route
        path="/c/513027c3-d36a-4dd0-8703-b664b5db8333"
        element={<LandingPage approach="ai-generated-news" lightweight />}
      />
      <Route
        path="/c/db0e6aba-f4c2-4ac5-8af0-39b50cbc000f"
        element={<LandingPage approach="ai-generated-news" lightweight />}
      />
      <Route
        path="/c/189842"
        element={<LandingPage approach="reasonable-political-debate" persona="rational-roberta-persona" />}
      />
      <Route
        path="/c/22d47d29-8522-4757-a2d9-e7cde0b9b3e6"
        element={<LandingPage approach="singular-information-source" />}
      />
      <Route
        path="/c/588e8d87-2cca-414a-8307-7f6e340cf148"
        element={<LandingPage approach="reasonable-political-debate" />}
      />
      <Route
        path="/c/481520"
        element={<LandingPage approach="" persona="political-content-creator" />}
      />
      <Route
        path="/c/dbb804c9-24c5-4720-8646-85437967a4c5"
        element={<LandingPage approach="logical-knowledge-base" persona="" />}
      />
      <Route
        path="/c/9173f105-bbb8-49c1-8919-c7b3a26f6f36"
        element={<FeaturePageBrowserPlugin />}
      />
      <Route
        path="/reasonable-political-debate"
        element={<LandingPage approach="reasonable-political-debate" />}
      />
      <Route
        path="/logical-knowledge-base"
        element={<LandingPage approach="logical-knowledge-base" persona="" />}
      />
      <Route
        path="/singular-information-source"
        element={<LandingPage approach="singular-information-source" />}
      />
      <Route
        path="/"
        element={<LandingPageGeneralPublic />}
      />
      <Route
        path="/feature/2dbc1412-bbfb-4f95-a34b-809371918163"
        element={<FeaturePageBrowserPlugin />}
      />
      <Route
        path="/feature/9ec8afc8-b205-4c3e-b3ed-972eaf6c29eb"
        element={<FeaturePageLogicalStructure />}
      />
      <Route
        path="/privacy"
        element={<PrivacyPolicyView />}
      />
      <Route
        path="/impressum"
        element={<ImpressumView />}
      />
      <Route
        path="/register"
        element={
          <RegisterView />
        }
      />
      <Route path="/public" element={
        <Feed
          syllogisms={props.syllogisms}
          topics={props.topics}
          userTopics={props.topics}
          handleViewTopic={props.handleViewTopic}
          handleCreateTopic={props.handleCreateTopic}
        />
      } />
      <Route path="/personal" element={
        <MyWorldView
          syllogisms={props.syllogisms}
          topics={props.topics}
          userTopics={props.topics}
          handleViewTopic={props.handleViewTopic}
          handleCreateTopic={props.handleCreateTopic}
        />
      } />
      <Route path="/login" element={
        <Login
          setEmail={setEmail}
          setPassword={setPassword}
          handleLogin={handleLogin}
          topics={props.topics}
        />
      } />
      {/* <Route path="/critisize" element={<ThinkView syllogism={props.syllogism} syllogisms={props.syllogisms} topics={props.topics} />} /> */}
      {/* <Route path="/world" element={<WorldView topic={props.currentTopic} topics={props.topics} />} /> */}
      <Route path="/statement/:id" element={<StatementView statement={props.statement} topics={props.topics} />} />
      <Route path="/profile" element={
        <ProfileView
          userTopics={props.topics}
          handleViewTopic={props.handleViewTopic}
          handleCreateTopic={props.handleCreateTopic}
          topics={props.topics}
        />} />
      {/* TODO Rename TopicView to TopicListView, WorldView to TopicTreeView and StatementTreeView, StatementView to StatementListView */}
      <Route path="/topic/:id" element={<TopicView topic={props.currentTopic} topics={props.topics} topicId='' />} />
    </Routes>
  )
}

class App extends Component {

  constructor(props: any) {
    super(props);
    this.state = {
      statements: {
        // "0001": new Statement("Sokrates ist ein Mensch.", true),
        // "0002": new Statement("Alle Menschen sind sterblich.", false),
        // "0003": new Statement("Sokrates ist sterblich.", true),
        // "0004": new Statement("Sokrates ist gestorben.", false),
        // "0005": new Statement("Social Media is detrimental to democratic societies.", false),
        // "0006": new Statement("Social Media could be detrimental to the Freedom of Elections.", false),
        // "0007": new Statement("The Freedom of Elections is a fundamental part of democracy.", false),
        // "0008": new Statement("Elections are not free when third parties can interfere in the excercising of the active voting right.", true),
        // "0009": new Statement("Social Media companies can influence votes.", false),
        // "0010": new Statement("Facebook could influence votes.", false),
        // "0011": new Statement("If Social Media companies can influence elections its detrimental to democracy.", false),
        // "0012": new Statement("Social Media enriches the diversity of opinions.", false),
        // "0013": new Statement("Social Media contributes to polarisation of opinions.", false),
        // "0014": new Statement("Opinions of Social Media users are radicalizing.", false),
        // "0015": new Statement("Social Media reinforces users opinion.", false),
        // "0016": new Statement("Social Media users are blocking users with different opinions.", false),
        // "0017": new Statement("When humans interact they adapt each others opinions.", false),
        // "0018": new Statement("On Social Media users meet people with similar opinions.", false),
        // "0019": new Statement("On Social Media users primarily see content that aligns with their opinions.", false),
      },
      syllogisms: {
      },
      topics: {
        // "0001": new Topic("Soziale Medien"),
        // "0002": new Topic("Klimawandel"),
        // "0003": new Topic("Demokratie"),
        // "0004": new Topic("Sokrates"),
      },
      user: new User(),
      currentTopic: null
    }
    // console.log("Created example statements.");
    // this.state.syllogisms["0001"] = new Syllogism([this.state.statements["0006"], this.state.statements["0007"]], this.state.statements["0005"], ["Social Media", "Democracy"], "default", true);
    // this.state.syllogisms["0002"] = new Syllogism([this.state.statements["0008"], this.state.statements["0009"]], this.state.statements["0006"], ["Social Media", "Democracy"], "default", false);
    // this.state.syllogisms["0003"] = new Syllogism([this.state.statements["0010"]], this.state.statements["0009"], ["Social Media", "Democracy"], "default", true);
    // this.state.syllogisms["0004"] = new Syllogism([this.state.statements["0011"], this.state.statements["0009"]], this.state.statements["0005"], ["Social Media", "Democracy"], "default", false);
    // this.state.syllogisms["0005"] = new Syllogism([this.state.statements["0012"]], this.state.statements["0005"], ["Social Media", "Democracy"], "negation", false);
    // this.state.syllogisms["0006"] = new Syllogism([this.state.statements["0014"]], this.state.statements["0013"], ["Social Media", "Democracy"], "default", false);
    // this.state.syllogisms["0007"] = new Syllogism([this.state.statements["0015"]], this.state.statements["0014"], ["Social Media", "Democracy"], "default", false);
    // this.state.syllogisms["0008"] = new Syllogism([this.state.statements["0016"]], this.state.statements["0015"], ["Social Media", "Democracy"], "default", false);
    // this.state.syllogisms["0009"] = new Syllogism([this.state.statements["0017"], this.state.statements["0018"], this.state.statements["0019"]], this.state.statements["0015"], ["Social Media", "Democracy"], "default", false);
    // this.state.syllogisms["0010"] = new Syllogism([this.state.statements["0001"], this.state.statements["0002"]], this.state.statements["0003"], [this.state.topics["0004"]], "default", false);
    // this.state.syllogisms["0011"] = new Syllogism([this.state.statements["0004"]], this.state.statements["0003"], [this.state.topics["0004"]], "default", false);
    //   console.log("Created syllogisms with example statements.");
    //   for (let statementId in this.state.statements) {
    //     let statement = this.state.statements[statementId];
    //     this.state.topics["0001"].statements.push(statement);
    //   }
    //   console.log("Added example statements to example topic");
    //   this.state.currentTopic = this.state.topics["0001"]
  }

  // addStatement = (statement, type) => { // TODO Not up to date
  //   switch (type) {
  //     case "precondition":
  //       this.setState({
  //         statements: [this.state.statements[0].addPrecondition(statement)],
  //       });
  //       break;
  //     case "implication":
  //       this.setState({
  //         statements: [this.state.statements[0].addImplication(statement)],

  //       });
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // handleViewTopic = (topic) => {
  //   this.setState({ currentTopic: topic });
  // };

  // handleCreateTopic = (topicName) => {
  //   const newTopics = this.state.topics;
  //   newTopics[getTopicId()] = new Topic(topicName)
  //   this.setState({ topics: newTopics })
  // };

  render() {
    return (
      <div className="App">

        <BrowserRouter>

          <div className="main">
            <Main
            // user={this.state.user}
            // topics={this.state.topics}
            // currentTopic={this.state.currentTopic}
            // syllogism={this.state.syllogisms["0001"]}
            // syllogisms={this.state.syllogisms}
            // statement={this.state.statements["0005"]}
            // addStatement={this.addStatement}
            // handleViewTopic={this.handleViewTopic}
            // handleCreateTopic={this.handleCreateTopic}
            />
          </div>

        </BrowserRouter>

      </div>
    );
  }
}

export default App;