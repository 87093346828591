import { VoteType } from "../../LucidMindShared/dataModel.web";

const iconSizeNavigationBar = 25;

interface BelieveIconProps { vote: VoteType }
export function BelieveIcon({ vote }: BelieveIconProps) {

    return (
        <svg
            className={vote === VoteType.UPVOTE ? 'btn-vote-agree' : 'btn-vote-neutral'}
            xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
            <path d="M24 24H0V0h24v24z" fill="none" />
            <path d="M2 20h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1H2v11zm19.83-7.12c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.84C7 18.95 8.05 20 9.34 20h8.11c.7 0 1.36-.37 1.72-.97l2.66-6.15z" />
        </svg>
    )
}

export function NotBelieveIcon({ vote }: BelieveIconProps) {

    return (
        <svg 
            className={vote === VoteType.DOWNVOTE ? 'btn-vote-disagree' : 'btn-vote-neutral'}
            xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor">
            <path d="M24 24H0V0h24v24z" fill="none" />
            <path d="M22 4h-2c-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h2V4zM2.17 11.12c-.11.25-.17.52-.17.8V13c0 1.1.9 2 2 2h5.5l-.92 4.65c-.05.22-.02.46.08.66.23.45.52.86.88 1.22L10 22l6.41-6.41c.38-.38.59-.89.59-1.42V6.34C17 5.05 15.95 4 14.66 4h-8.1c-.71 0-1.36.37-1.72.97l-2.67 6.15z" />
        </svg>
    )
}

export function CancelIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
    )
}

export function ConfirmIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/></svg>
    )
}

export function EditIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
    )
}

export function RemoveIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_733_6482" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
        <rect x="0.87915" y="0.781464" width="24" height="24" fill="currentColor"/>
        </mask>
        <g mask="url(#mask0_733_6482)">
        <path d="M7.87915 21.7815C7.32915 21.7815 6.85832 21.5856 6.46665 21.194C6.07498 20.8023 5.87915 20.3315 5.87915 19.7815V6.78146C5.59582 6.78146 5.35832 6.68563 5.16665 6.49396C4.97498 6.3023 4.87915 6.0648 4.87915 5.78146C4.87915 5.49813 4.97498 5.26063 5.16665 5.06896C5.35832 4.8773 5.59582 4.78146 5.87915 4.78146H9.87915C9.87915 4.49813 9.97498 4.26063 10.1667 4.06896C10.3583 3.8773 10.5958 3.78146 10.8792 3.78146H14.8792C15.1625 3.78146 15.4 3.8773 15.5917 4.06896C15.7833 4.26063 15.8792 4.49813 15.8792 4.78146H19.8792C20.1625 4.78146 20.4 4.8773 20.5917 5.06896C20.7833 5.26063 20.8792 5.49813 20.8792 5.78146C20.8792 6.0648 20.7833 6.3023 20.5917 6.49396C20.4 6.68563 20.1625 6.78146 19.8792 6.78146V19.7815C19.8792 20.3315 19.6833 20.8023 19.2917 21.194C18.9 21.5856 18.4292 21.7815 17.8792 21.7815H7.87915ZM17.8792 6.78146H7.87915V19.7815H17.8792V6.78146ZM10.8792 17.7815C11.1625 17.7815 11.4 17.6856 11.5917 17.494C11.7833 17.3023 11.8792 17.0648 11.8792 16.7815V9.78146C11.8792 9.49813 11.7833 9.26063 11.5917 9.06896C11.4 8.8773 11.1625 8.78146 10.8792 8.78146C10.5958 8.78146 10.3583 8.8773 10.1667 9.06896C9.97498 9.26063 9.87915 9.49813 9.87915 9.78146V16.7815C9.87915 17.0648 9.97498 17.3023 10.1667 17.494C10.3583 17.6856 10.5958 17.7815 10.8792 17.7815ZM14.8792 17.7815C15.1625 17.7815 15.4 17.6856 15.5917 17.494C15.7833 17.3023 15.8792 17.0648 15.8792 16.7815V9.78146C15.8792 9.49813 15.7833 9.26063 15.5917 9.06896C15.4 8.8773 15.1625 8.78146 14.8792 8.78146C14.5958 8.78146 14.3583 8.8773 14.1667 9.06896C13.975 9.26063 13.8792 9.49813 13.8792 9.78146V16.7815C13.8792 17.0648 13.975 17.3023 14.1667 17.494C14.3583 17.6856 14.5958 17.7815 14.8792 17.7815Z" fill="currentColor"/>
        </g>
        </svg>
    )
}

export function CompareArrowsIcon() {
    return (
        <svg className="btn-icon-text-icon" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_718_10310" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor"/>
        </mask>
        <g mask="url(#mask0_718_10310)">
        <path d="M9.175 16H3C2.71667 16 2.47917 15.9042 2.2875 15.7125C2.09583 15.5208 2 15.2833 2 15C2 14.7167 2.09583 14.4792 2.2875 14.2875C2.47917 14.0958 2.71667 14 3 14H9.175L7.3 12.125C7.11667 11.9417 7.025 11.7125 7.025 11.4375C7.025 11.1625 7.11667 10.925 7.3 10.725C7.5 10.525 7.7375 10.425 8.0125 10.425C8.2875 10.425 8.525 10.525 8.725 10.725L12.3 14.3C12.4 14.4 12.4708 14.5083 12.5125 14.625C12.5542 14.7417 12.575 14.8667 12.575 15C12.575 15.1333 12.5542 15.2583 12.5125 15.375C12.4708 15.4917 12.4 15.6 12.3 15.7L8.7 19.3C8.5 19.5 8.26667 19.5958 8 19.5875C7.73333 19.5792 7.5 19.475 7.3 19.275C7.11667 19.075 7.02083 18.8417 7.0125 18.575C7.00417 18.3083 7.1 18.075 7.3 17.875L9.175 16ZM14.825 9.99999L16.7 11.875C16.8833 12.0583 16.975 12.2875 16.975 12.5625C16.975 12.8375 16.8833 13.075 16.7 13.275C16.5 13.475 16.2625 13.575 15.9875 13.575C15.7125 13.575 15.475 13.475 15.275 13.275L11.7 9.69999C11.6 9.59999 11.5292 9.49165 11.4875 9.37499C11.4458 9.25832 11.425 9.13332 11.425 8.99999C11.425 8.86665 11.4458 8.74165 11.4875 8.62499C11.5292 8.50832 11.6 8.39999 11.7 8.29999L15.3 4.69999C15.5 4.49999 15.7333 4.40415 16 4.41249C16.2667 4.42082 16.5 4.52499 16.7 4.72499C16.8833 4.92499 16.9792 5.15832 16.9875 5.42499C16.9958 5.69165 16.9 5.92499 16.7 6.12499L14.825 7.99999H21C21.2833 7.99999 21.5208 8.09582 21.7125 8.28749C21.9042 8.47915 22 8.71665 22 8.99999C22 9.28332 21.9042 9.52082 21.7125 9.71249C21.5208 9.90415 21.2833 9.99999 21 9.99999H14.825Z" fill="currentColor"/>
        </g>
        </svg>
    )
}

export function NewWindowIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_733_6488" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor"/>
        </mask>
        <g mask="url(#mask0_733_6488)">
        <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H10C10.2833 3 10.5208 3.09583 10.7125 3.2875C10.9042 3.47917 11 3.71667 11 4C11 4.28333 10.9042 4.52083 10.7125 4.7125C10.5208 4.90417 10.2833 5 10 5H5V19H19V14C19 13.7167 19.0958 13.4792 19.2875 13.2875C19.4792 13.0958 19.7167 13 20 13C20.2833 13 20.5208 13.0958 20.7125 13.2875C20.9042 13.4792 21 13.7167 21 14V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM16 8H14C13.7167 8 13.4792 7.90417 13.2875 7.7125C13.0958 7.52083 13 7.28333 13 7C13 6.71667 13.0958 6.47917 13.2875 6.2875C13.4792 6.09583 13.7167 6 14 6H16V4C16 3.71667 16.0958 3.47917 16.2875 3.2875C16.4792 3.09583 16.7167 3 17 3C17.2833 3 17.5208 3.09583 17.7125 3.2875C17.9042 3.47917 18 3.71667 18 4V6H20C20.2833 6 20.5208 6.09583 20.7125 6.2875C20.9042 6.47917 21 6.71667 21 7C21 7.28333 20.9042 7.52083 20.7125 7.7125C20.5208 7.90417 20.2833 8 20 8H18V10C18 10.2833 17.9042 10.5208 17.7125 10.7125C17.5208 10.9042 17.2833 11 17 11C16.7167 11 16.4792 10.9042 16.2875 10.7125C16.0958 10.5208 16 10.2833 16 10V8Z" fill="currentColor"/>
        </g>
        </svg>
    )
}

export function BookmarkIcon() {
    return (
        <svg className="statement-footer-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"  fill="currentColor"><path d="M154 1002V304q0-57.125 40.137-96.562Q234.275 168 290 168h380q55.725 0 95.862 39.438Q806 246.875 806 304v698L480 863l-326 139Zm136-206 190-81 190 81V304H290v492Zm0-492h380-380Z"/></svg>
    )
}

export function PublicIcon({sidebar=false}: any) {
    return(
        <svg className={sidebar? "sidebar-icon" : "navbar-icon"} xmlns="http://www.w3.org/2000/svg" height={iconSizeNavigationBar * 0.9} viewBox="0 0 24 24" width={iconSizeNavigationBar * 0.9} fill="currentColor">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M5 15h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zm0 4h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zm0-8h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zM4 6c0 .55.45 1 1 1h14c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1z"/>
        </svg>
        )
}

export function PersonalIcon({sidebar=false}: any) {
    return(
        <svg className={sidebar? "sidebar-icon" : "navbar-icon"} xmlns="http://www.w3.org/2000/svg" width={iconSizeNavigationBar * 0.9}  enable-background="new 0 0 24 24" height={iconSizeNavigationBar * 0.9} fill="currentColor" viewBox="0 0 24 24">
           <rect fill="none" height="24" width="24"/><path d="M8.4,18.2C8.78,18.7,9,19.32,9,20c0,1.66-1.34,3-3,3s-3-1.34-3-3s1.34-3,3-3c0.44,0,0.85,0.09,1.23,0.26l1.41-1.77 c-0.92-1.03-1.29-2.39-1.09-3.69l-2.03-0.68C4.98,11.95,4.06,12.5,3,12.5c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 c0,0.07,0,0.14-0.01,0.21l2.03,0.68c0.64-1.21,1.82-2.09,3.22-2.32l0-2.16C9.96,5.57,9,4.4,9,3c0-1.66,1.34-3,3-3s3,1.34,3,3 c0,1.4-0.96,2.57-2.25,2.91v2.16c1.4,0.23,2.58,1.11,3.22,2.32l2.03-0.68C18,9.64,18,9.57,18,9.5c0-1.66,1.34-3,3-3s3,1.34,3,3 s-1.34,3-3,3c-1.06,0-1.98-0.55-2.52-1.37l-2.03,0.68c0.2,1.29-0.16,2.65-1.09,3.69l1.41,1.77C17.15,17.09,17.56,17,18,17 c1.66,0,3,1.34,3,3s-1.34,3-3,3s-3-1.34-3-3c0-0.68,0.22-1.3,0.6-1.8l-1.41-1.77c-1.35,0.75-3.01,0.76-4.37,0L8.4,18.2z"/>
        </svg>
    )
}

export function ProfileIcon({sidebar=false}: any) {
    return(
        <svg className={sidebar? "sidebar-icon" : "navbar-icon"} xmlns="http://www.w3.org/2000/svg" height={sidebar? iconSizeNavigationBar * 0.9 : iconSizeNavigationBar} viewBox="0 0 24 24" width={sidebar? iconSizeNavigationBar * 0.9 : iconSizeNavigationBar} fill="currentColor">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v1c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-1c0-2.66-5.33-4-8-4z"/>
        </svg>
    )
}

export function ArrowRightIcon() {
    return(
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z"/></svg>
    )
}