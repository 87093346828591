import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Image from 'react-bootstrap/esm/Image';
import { useContext } from 'react';
// @ts-ignore
import logo from '../LucidMindShared/res/LogoLM.png';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';

export function Login(props: any) {

    const strings = useContext(LocalizedStringsContext);

    return (
        <>
            <Container className='login-view'>
                <Row>
                    <Col>
                        <Row className='my-0 mx-3'>
                            <Image src={logo} height={`${250}px`} width={`${250}px`}/>
                        </Row>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                {/* <Form.Label>E-Mail Adresse</Form.Label> */}
                                <Form.Control
                                    type="email"
                                    placeholder={strings.email}
                                    onChange={event => props.setEmail(event.target.value)}
                                    autoFocus
                                />
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                            >
                                {/* <Form.Label>Passwort</Form.Label> */}
                                <Form.Control
                                    type='password'
                                    placeholder={strings.password}
                                    onChange={event => props.setPassword(event.target.value)}
                                />
                            </Form.Group>
                            <Form.Text className="text-muted">
                                <p className='p-1'>{strings.no_account} <a href="/register">{strings.register_call_to_action}</a></p>
                            </Form.Text>
                        </Form>
                        <Button className="login-btn" variant="primary" onClick={props.handleLogin}>
                            {strings.login}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )

}