import Container from 'react-bootstrap/esm/Container';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';
import { TestVersionButton, TryButton, WaitListButton } from '../components/Common/Buttons';

// @ts-ignore
import mockupCollectionDE from "../LucidMindShared/res/mockupCollectionDE.png";
// @ts-ignore
import collectionSyllogismNetworkCubes from "../LucidMindShared/res/collections/collectionSyllogismNetworkCubes.png";
// @ts-ignore
import collectionGeneratedArticleNetwork from "../LucidMindShared/res/collections/collectionGeneratedArticleNetwork.png";
// @ts-ignore
import collectionArticlesNetworkBrain from "../LucidMindShared/res/collectionArticlesNetworkBrain.png";
// @ts-ignore
import resolveConflict from "../LucidMindShared/res/features/resolveConflict.png";
import { CallToAction, FeatureRow } from './LandingPageSingularInformationSource';
import { FooterFeed } from '../components/Common/Footer';
// @ts-ignore
import statementNetworkIcon from "../LucidMindShared/res/statementNetworkIcon.png";
// @ts-ignore
import timeIcon from "../LucidMindShared/res/timeIcon.png";
// @ts-ignore
import scaleIcon from "../LucidMindShared/res/scaleIcon.png";
// @ts-ignore
import controllerIcon from "../LucidMindShared/res/controllerIcon.png";
// @ts-ignore
import aiChessIcon from "../LucidMindShared/res/aiChessIcon.png";
// @ts-ignore
import sharedIcon from "../LucidMindShared/res/sharedIcon.png";
// @ts-ignore
import feedArticleArgumentMockup from "../LucidMindShared/res/feedArticleArgumentMockup.png";
// @ts-ignore
import consistencyCheckProfile from "../LucidMindShared/res/consistencyCheckProfile.png";
// @ts-ignore
import proContraArguments from "../LucidMindShared/res/proContraArguments.png";
// @ts-ignore
import syllogismWithoutIcons from "../LucidMindShared/res/syllogismWithoutIcons.png";
// @ts-ignore
import statementNetwork from "../LucidMindShared/res/statementNetwork.png";
// @ts-ignore
import syllogismWithPlaceholderWithIcons from "../LucidMindShared/res/syllogismWithPlaceholderWithIcons.png";
// @ts-ignore
import feedArguments from "../LucidMindShared/res/feedArguments.png";
// @ts-ignore
import articleStatementPost from "../LucidMindShared/res/articleStatementPost.png";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';
import { LandingPageHeader } from '../components/Common/LandingPageHeader';
import { BenefitCol } from './BenefitCol';
import { LogicalStructureDemo } from './FeaturePageLogicalStructure';

export function LandingPage({ approach, persona, lightweight }: any) {

    const generalApproach = "general"; // TODO Check where "general" is used if this is still accurate
    const singularInformationSourceApproach = "singular-information-source";
    const reasonablePoliticalDebateApproach = "reasonable-political-debate";
    const logicalKnowledgeBaseApproach = "logical-knowledge-base";
    const aiGeneratedNewsApproach = "ai-generated-news";
    const rationalRobertaPersona = "rational-roberta-persona";
    const politicalContentCreatorPersona = "political-content-creator";

    const strings = useContext(LocalizedStringsContext);
    const allHeaders = [
        {
            header: strings.headers.generatedNews2.header,
            text: strings.headers.generatedNews2.text,
            icon: collectionGeneratedArticleNetwork,
            iconPadding: 1,
            approaches: [aiGeneratedNewsApproach]
        },
        {
            header: strings.header_header,
            text: strings.header_text,
            icon: mockupCollectionDE,
            iconPadding: 5,
            approaches: [reasonablePoliticalDebateApproach]
        },
        {
            header: strings.headers.debate_superpowers.header,
            text: strings.headers.debate_superpowers.text,
            icon: mockupCollectionDE,
            iconPadding: 5,
            approaches: [rationalRobertaPersona]
        },
        {
            header: strings.headers.rational_content.header,
            text: strings.headers.rational_content.text,
            icon: collectionSyllogismNetworkCubes,
            iconPadding: 5,
            approaches: [politicalContentCreatorPersona]
        },
        {
            header: strings.header_knowledge_base,
            text: strings.header_knowledge_base_text,
            icon: collectionSyllogismNetworkCubes,
            iconPadding: 5,
            approaches: [logicalKnowledgeBaseApproach]
        },
        {
            header: strings.headers.structureKnowledge.header,
            text: strings.headers.structureKnowledge.text,
            icon: collectionSyllogismNetworkCubes,
            iconPadding: 5,
            approaches: [logicalKnowledgeBaseApproach]
        },
        {
            header: strings.header_information_source,
            text: strings.header_information_source_text,
            icon: collectionArticlesNetworkBrain,
            iconPadding: 5,
            approaches: [singularInformationSourceApproach]
        },
    ];
    const allFeatureDemos = [
        {
            element: <LogicalStructureDemo />,
            approaches: [generalApproach],
        }
    ];
    const allSubHeaders = [
        {
            header: strings.one_place_for_all_header,
            text: "",
            approaches: [generalApproach]
        },
        {
            header: strings.introduction_group_header_soft,
            text: "",
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.subHeaders.contentExcellence.header,
            text: "",
            approaches: [politicalContentCreatorPersona]
        },
        {
            header: strings.subHeaders.logicalOrganization.header,
            text: strings.subHeaders.logicalOrganization.text,
            approaches: [logicalKnowledgeBaseApproach]
        }
    ];
    const allFeatures = [
        {
            name: "argumentNetwork",
            content: [
                {
                    header: strings.features.argumentNetwork.webOfKnowledge.header,
                    text: strings.features.argumentNetwork.webOfKnowledge.text,
                    icon: statementNetwork,
                    iconScale: "100%",
                    approaches: [generalApproach]
                },
                {
                    header: strings.introduction_group_statements_header,
                    text: strings.introduction_group_statements_text,
                    icon: syllogismWithoutIcons,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.introduction_group_logical_tree_header,
                    text: strings.introduction_group_logical_tree_text,
                    icon: statementNetwork,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.introduction_group_interaction_header_soft,
                    text: strings.introduction_group_interaction_text,
                    icon: syllogismWithPlaceholderWithIcons,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.features.argumentNetwork.structureKnowledge.header,
                    text: strings.features.argumentNetwork.structureKnowledge.text,
                    icon: syllogismWithPlaceholderWithIcons,
                    iconScale: "100%",
                    approaches: [logicalKnowledgeBaseApproach]
                },
            ]
        },
        {
            name: "aiGeneratedArguments",
            content: [
                {
                    header: strings.features.aiGeneratedArguments.intelligentArguments.header,
                    text: strings.features.aiGeneratedArguments.intelligentArguments.text,
                    icon: syllogismWithPlaceholderWithIcons,
                    iconScale: "100%",
                    approaches: [generalApproach]
                },
                {
                    header: strings.features.aiGeneratedArguments.counterArgumentGeneration.header,
                    text: strings.features.aiGeneratedArguments.counterArgumentGeneration.text,
                    icon: syllogismWithPlaceholderWithIcons,
                    iconScale: "100%",
                    approaches: [politicalContentCreatorPersona]
                },
            ]
        },
        {
            name: "shareArguments",
            content: [
                {
                    header: strings.share_knowledge_header,
                    text: strings.share_knowledge_text,
                    icon: feedArticleArgumentMockup,
                    iconScale: "50%",
                    approaches: [singularInformationSourceApproach]
                },
                {
                    header: strings.features.sharedArguments.shareAndDiscuss.header,
                    text: strings.features.sharedArguments.shareAndDiscuss.text,
                    icon: feedArticleArgumentMockup,
                    iconScale: "50%",
                    approaches: [logicalKnowledgeBaseApproach]
                },
            ]
        },
        {
            name: "consistencyCheck",
            content: [
                {
                    header: strings.features.consistencyChecker.consistencyAtGlance.header,
                    text: strings.features.consistencyChecker.consistencyAtGlance.text,
                    icon: consistencyCheckProfile,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.automatic_critizism_header,
                    text: strings.automatic_critizism_text,
                    icon: consistencyCheckProfile,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.conflictsChecks_header,
                    text: strings.conflictsChecks_text,
                    icon: consistencyCheckProfile,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.features.consistencyChecker.consistentExcellence.header,
                    text: strings.features.consistencyChecker.consistentExcellence.text,
                    icon: consistencyCheckProfile,
                    iconScale: "100%",
                    approaches: [politicalContentCreatorPersona]
                },
            ]
        },
        {
            name: "argumentDetection",
            content: [
                {
                    header: strings.conquer_your_bias_header,
                    text: strings.conquer_your_bias_text,
                    icon: proContraArguments,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.argument_proposal_header,
                    text: strings.argument_proposal_text,
                    icon: feedArguments,
                    iconScale: "50%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.pro_contra_argument_header_soft,
                    text: strings.pro_contra_argument_text,
                    icon: proContraArguments,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
                },
                {
                    header: strings.features.argumentProposal.dynamicCounterarguments.header,
                    text: strings.features.argumentProposal.dynamicCounterarguments.text,
                    icon: proContraArguments,
                    iconScale: "100%",
                    approaches: [politicalContentCreatorPersona]
                },
            ]
        },
        {
            name: "generatedContent",
            content: [
                {
                    header: strings.feature_generated_content_header,
                    text: strings.feature_generated_content_text,
                    icon: articleStatementPost,
                    iconScale: "100%",
                    approaches: [singularInformationSourceApproach]
                },
            ]
        },
        {
            name: "conflictResolution",
            content: [
                {
                    header: strings.features.conflictResolution.resolutionGuidance.header,
                    text: strings.features.conflictResolution.resolutionGuidance.text,
                    icon: resolveConflict,
                    iconScale: "100%",
                    approaches: [politicalContentCreatorPersona]
                },
            ]
        }
    ];
    const allBenefits = [
        {
            header: strings.better_overview_header,
            text: strings.better_overview_text,
            icon: statementNetworkIcon,
            approaches: [
                singularInformationSourceApproach,
                reasonablePoliticalDebateApproach,
                politicalContentCreatorPersona,
            ]
        },
        {
            header: strings.benefits.structured.structuredShort.header,
            text: strings.benefits.structured.structuredShort.text,
            icon: statementNetworkIcon,
            approaches: [
                logicalKnowledgeBaseApproach,
            ]
        },
        {
            header: strings.time_saving_header,
            text: strings.time_saving_text,
            icon: timeIcon,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.benefits.fast.reduceResearchTime.header,
            text: strings.benefits.fast.reduceResearchTime.text,
            icon: timeIcon,
            approaches: [politicalContentCreatorPersona]
        },
        {
            header: strings.benefit_engaging_header,
            text: strings.benefit_engaging_text,
            icon: controllerIcon,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.benefit_shared_header,
            text: strings.benefit_shared_text,
            icon: sharedIcon,
            approaches: [
                singularInformationSourceApproach,
                reasonablePoliticalDebateApproach,
            ]
        },
        {
            header: strings.benefits.shared.sharedKnowledgeShort.header,
            text: strings.benefits.shared.sharedKnowledgeShort.text,
            icon: sharedIcon,
            approaches: [
                logicalKnowledgeBaseApproach
            ]
        },
        { // TODO Restructure benefits like features or move benefits to features
            header: strings.benefits.shared.shareWithCommunity.header,
            text: strings.benefits.shared.shareWithCommunity.text,
            icon: sharedIcon,
            approaches: [politicalContentCreatorPersona]
        },
        {
            header: strings.benefit_smart_header_alternative_1,
            text: strings.benefit_smart_text,
            icon: aiChessIcon,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach, politicalContentCreatorPersona]
        },
        {
            header: strings.benefit_unbiased_header,
            text: strings.benefit_unbiased_text,
            icon: scaleIcon,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach, politicalContentCreatorPersona]
        },
        {
            header: strings.benefit_smart_header_alternative_1,
            text: strings.automatic_reasoning_text,
            icon: aiChessIcon,
            approaches: [
                reasonablePoliticalDebateApproach,
                logicalKnowledgeBaseApproach,
                politicalContentCreatorPersona
            ]
        },
    ];
    const allCallsToAction = [
        {
            header: strings.call_to_action_elevate_insights_header,
            text: strings.call_to_action_elevate_insights_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach, logicalKnowledgeBaseApproach]
        },
        {
            header: strings.call_to_action_uncover_knowledge_header,
            text: strings.call_to_action_uncover_knowledge_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_expand_horizons_header,
            text: strings.call_to_action_expand_horizons_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_header_soft,
            text: strings.call_to_action_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_register_header,
            text: strings.call_to_action_register_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_header,
            text: strings.call_to_action_register_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_register_test_header,
            text: strings.call_to_action_register_test_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_try_solution_header,
            text: strings.call_to_action_try_solution_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.call_to_action_get_test_access_header,
            text: strings.call_to_action_get_test_access_text,
            approaches: [singularInformationSourceApproach, reasonablePoliticalDebateApproach]
        },
        {
            header: strings.callsToAction.startCrafting.header,
            text: strings.callsToAction.startCrafting.text,
            approaches: [politicalContentCreatorPersona]
        },
        {
            header: strings.callsToAction.tryNow.header,
            text: strings.callsToAction.tryNow.text,
            approaches: [logicalKnowledgeBaseApproach]
        },
    ];

    const getRandomObjects = (array: Array<Object>, numberOfRandomObjects: number): any => {
        if (numberOfRandomObjects === 1) {
            const randomIndex = Math.floor(Math.random() * array.length);
            return array[randomIndex];
        } else {
            // Shuffle the array to randomize the order
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]];
            }
            // Return the first objects
            return array.slice(0, numberOfRandomObjects);
        }
    }

    const randomSubHeaderAssignment = () => {
        const allSubHeadersForApproach = allSubHeaders.filter(subHeader => subHeader.approaches.includes(approach) || subHeader.approaches.includes(persona) || subHeader.approaches.includes(generalApproach));
        return getRandomObjects(allSubHeadersForApproach, 1);
    }

    const randomHeaderAssignment = () => {
        const allHeadersForApproach = allHeaders.filter(header => header.approaches.includes(approach) || header.approaches.includes(persona) || header.approaches.includes(generalApproach));
        return getRandomObjects(allHeadersForApproach, 1);
    }

    const randomFeatureDemoAssignment = () => {
        const allFeatureDemosForApproach = allFeatureDemos.filter(featureDemo => featureDemo.approaches.includes(approach) || featureDemo.approaches.includes(persona) || featureDemo.approaches.includes(generalApproach));
        return getRandomObjects(allFeatureDemosForApproach, 1);
    }

    const randomBenefitAssignment = () => {
        const allBenefitsForApproach = allBenefits.filter(benefit => benefit.approaches.includes(approach) || benefit.approaches.includes(persona) || benefit.approaches.includes(generalApproach));
        return getRandomObjects(allBenefitsForApproach, 3);
    }

    const randomFeatureAssignment = () => {
        const featuresForApproach = allFeatures.filter(feature =>
            feature.content.filter((feature: any) =>
                feature.approaches.includes(approach) || feature.approaches.includes(persona) || feature.approaches.includes(generalApproach)
            ).length > 0
        );
        featuresForApproach.forEach((feature: any) =>
            feature.content = feature.content.filter((feature: any) =>
                feature.approaches.includes(approach) || feature.approaches.includes(persona) || feature.approaches.includes(generalApproach)
            )
        );
        const randomFeaturesForApproachAllContent = getRandomObjects(featuresForApproach, 3);
        console.log(randomFeaturesForApproachAllContent)
        const randomFeaturesForApproachRandomContent = randomFeaturesForApproachAllContent.map((feature: any) => getRandomObjects(feature.content, 1));
        console.log(randomFeaturesForApproachRandomContent);
        const randomNamedFeaturesForApproachRandomContent = randomFeaturesForApproachAllContent.map((feature: any, index: any) => {
            return {
                name: feature.name,
                header: randomFeaturesForApproachRandomContent[index].header,
                text: randomFeaturesForApproachRandomContent[index].text,
                icon: randomFeaturesForApproachRandomContent[index].icon,
                iconScale: randomFeaturesForApproachRandomContent[index].iconScale,
                approaches: randomFeaturesForApproachRandomContent[index].approaches
            }

        }
        );
        console.log(randomNamedFeaturesForApproachRandomContent);
        return randomNamedFeaturesForApproachRandomContent;
    }

    const randomCallToActionAssignment = () => {
        const allCallsToActionForApproach = allCallsToAction.filter(callToAction => callToAction.approaches.includes(approach) || callToAction.approaches.includes(persona) || callToAction.approaches.includes(generalApproach));
        return getRandomObjects(allCallsToActionForApproach, 1);
    }

    const randomHeader = randomHeaderAssignment();
    const randomSubHeader = randomSubHeaderAssignment();
    const randomCallToAction = randomCallToActionAssignment();
    const randomBenefits = randomBenefitAssignment();
    const randomFeatures = randomFeatureAssignment();
    const randomFeatureDemo = randomFeatureDemoAssignment();

    const handleSeeTestVersion = () => {
        logEvent(analytics, 'landing_page_register_click', { approach: approach });
    }

    const handleTry = () => {
        logEvent(analytics, 'landing_page_try_click', { approach: approach });
    }

    return (
        <>
            <LandingPageHeader handleLandingPageClick={handleSeeTestVersion} />
            <LandingPageSubHeader header={randomHeader} handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry} lightweight={lightweight} centered={lightweight}/>
            {!lightweight ? 
            <>
                {randomFeatureDemo.element}
                <Benefits subHeader={randomSubHeader} benefits={randomBenefits} />
                <Container>
                    {randomFeatures.map((feature: any, index: any) =>
                        <FeatureRow
                            header={feature.header}
                            text={feature.text}
                            icon={feature.icon}
                            orientation={index % 2 === 0 ? "right" : "left"}
                            iconScale={feature.iconScale}
                        />
                    )}
                </Container>
                <CallToAction callToAction={randomCallToAction} handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry} />
            </> : null}
            <FooterFeed />
        </>
    )
};

interface LandingPageSubHeaderProps { header: any, centered?: boolean, handleSeeTestVersion: Function, handleTry: Function, lightweight?: boolean }
export function LandingPageSubHeader({ header, centered = false, handleSeeTestVersion, handleTry, lightweight=false }: LandingPageSubHeaderProps) {

    const iconScale = centered ? lightweight ? "40%" : "22.5%" : "100%";
    const iconScaleSm = lightweight ? "100%" : "70%";
    const FADE_INTERVAL = 1000;
    const WORD_CHANGE_INTERVAL = FADE_INTERVAL * 2;
    const wordCarouselContent = header.headerEmphasized ? header.headerEmphasized : [""];
    const [fade, setFade] = useState<'fade-in' | 'fade-out'>('fade-in');
    const [wordCarouselIndex, setWordCarouselIndex] = useState(0);

    useEffect(() => {
        const fadeTimeout = setInterval(() => {
            fade === 'fade-in' ? setFade('fade-out') : setFade('fade-in');
        }, FADE_INTERVAL);
        return () => clearInterval(fadeTimeout);
    }, [fade]);

    useEffect(() => {
        const wordChangeTimeout = setInterval(() => {
            setWordCarouselIndex((previouesIndex) => (previouesIndex + 1) % wordCarouselContent.length);
        }, WORD_CHANGE_INTERVAL);
        return () => clearInterval(wordChangeTimeout);
    }, []);

    return (
        <Container fluid className={`px-0 ${lightweight? "vh-100 d-flex align-items-center" : ""}`}>
            <Row>
                <Row xs={1} sm={centered ? 1 : 2} md={centered ? 1 : 2} className={`mx-auto ${lightweight ? "px-0" : "px-5"} landing-page-header-knowledge-base ${centered ? "text-center mb-0 py-0" : "my-auto py-3 pb-5"}`}>
                    <Col className={`p-1 p-md-${header.iconPadding} ${centered ? "d-none d-sm-none d-md-block align-items-center" : "d-block d-sm-none d-md-none"}`}>
                        <Image fluid height={iconScale} width={iconScale} src={header.icon} />
                    </Col>
                    {centered &&
                        <Col className={`p-1 p-md-${header.iconPadding} d-block d-sm-block d-md-none align-items-center`}>
                            <Image fluid height={iconScaleSm} width={iconScaleSm} src={header.icon} />
                        </Col>
                    }
                    {!centered &&
                        <>
                            <Col className='my-auto p-0 p-md-5'>
                                <h1>{header.header}</h1>
                                <p className='text-secondary'>{header.text}</p>
                                <Stack direction='horizontal' gap={1}>
                                    {lightweight? 
                                        <WaitListButton handleLandingPageClick={handleTry}/>
                                        : 
                                        <>
                                            <TestVersionButton handleLandingPageClick={handleSeeTestVersion} />
                                            <TryButton handleLandingPageClick={handleTry} />
                                        </>
                                    }
                                </Stack>
                            </Col>
                            <Col className={`p-1 p-md-${header.iconPadding} d-none d-sm-block d-md-block`}>
                                <Image fluid src={header.icon} />
                            </Col>
                        </>
                    }
                </Row>
                {centered &&
                    <>
                        <Row className='mx-auto px-auto pt-2 mb-5 align-items-center'>
                            <Col className='my-auto mx-auto mx-md-5 px-auto px-md-5 text-center align-items-center justify-content-center'>
                                <div className='d-flex flex-column mx-auto mx-md-5 px-auto px-md-5'>
                                    <h1 className='mb-0 '>{header.headerBeforeEmphasized ? header.headerBeforeEmphasized : header.header}</h1>
                                    {header.headerEmphasized && <h1 className='mb-0'><span className={`${fade} text-gradient`}>{wordCarouselContent[wordCarouselIndex]}</span></h1>}
                                    {/* <h1 className={`${fade} text-gradient`}>{wordCarouselContent[wordCarouselIndex]}</h1> */}
                                    {/* <h1>{header.header}</h1> */}
                                    <h1 className=''>{header.headerAfterEmphasized ? header.headerAfterEmphasized : ""}</h1>
                                    <p className='text-tertiary'>{header.text}</p>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    {lightweight ? 
                                        <WaitListButton handleLandingPageClick={handleTry}/>
                                        :
                                        <>
                                            <div className='p-1'>
                                                <TestVersionButton handleLandingPageClick={handleSeeTestVersion} />
                                            </div>
                                            <div className='p-1'>
                                                <TryButton handleLandingPageClick={handleTry} />
                                            </div>
                                        </>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </Row>
        </Container>
    )
}

interface LandingPageBenefitsProps { subHeader: any, benefits: any }
export function Benefits({ subHeader, benefits }: LandingPageBenefitsProps) {

    return (
        <Container fluid className='pt-1 pb-5 px-0 px-md-5 landing-page-benefits'>
            <Row className='pb-0 pt-5 mx-5'>
                <Col>
                    <h2>{subHeader.header}</h2>
                    {/* <p>{subHeader.text}</p> */}
                </Col>
                <Col>

                </Col>
            </Row>
            <Row xs={1} sm={3} md={3} className='my-auto mx-5 introduction-group'>
                {benefits.map((benefit: any) =>
                    <BenefitCol
                        header={benefit.header}
                        text={benefit.text}
                        icon={benefit.icon} />
                )}
            </Row>
        </Container>
    )
}