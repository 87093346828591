import { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LocalizedStringsContext } from '../../LucidMindShared/localization.web';
import { PersonalIcon, ProfileIcon, PublicIcon } from './Icons';

export function NavigationBar(props: any) {

    let navigate = useNavigate();
    const strings = useContext(LocalizedStringsContext);

    const homePageActive = window.location.href.endsWith("public");
    const myWorldViewActive = window.location.href.includes("personal");
    const profileActive = window.location.href.includes("profile");

    return (
        <Nav variant="pills" className="flex-column position-static sticky-top">
        <Nav.Item>
        <Nav.Link className={homePageActive ? "sidebar-link-active" : "sidebar-link-inactive"} onClick={()=>navigate("/public")}>
            <PublicIcon sidebar={true}/>
            {strings.public}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link className={myWorldViewActive ? "sidebar-link-active" : "sidebar-link-inactive"} onClick={()=>navigate("/personal")} >
                {/* <svg xmlns="http://www.w3.org/2000/svg" width={icon sidebar={true}izeNavigationBar * 0.9}  height={icon sidebar={true}izeNavigationBar * 0.9} fill="currentColor" className="bi bi-diagram-3 sidebar-icon sidebar={true} viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"/>
                </svg> */}
                <PersonalIcon sidebar={true}/>
                {strings.private}
            </Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link className={profileActive ? "sidebar-link-active" : "sidebar-link-inactive"} onClick={() => navigate("/profile")}>
                {/* <svg xmlns="http://www.w3.org/2000/svg"
                    width={iconSizeNavigationBar}
                    height={iconSizeNavigationBar} fill="currentColor" className="bi bi-person sidebar-icon" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                </svg> */}
                <ProfileIcon sidebar={true}/>
                {strings.profile}
            </Nav.Link>
        </Nav.Item>
    </Nav>
    )
};