import { useContext, useEffect, useState } from "react";
import { AddConditionButton, BelieveButton, BookmarkButton, NotBelieveButton } from "./Buttons";
import { useNavigate } from "react-router-dom";
import { Statement } from "../../model";
import { ContainerType, StanceType, VoteType, useStatementListener } from "../../LucidMindShared/dataModel.web";
import { Alert, Button, Card, Dropdown, Modal, Spinner, Stack } from "react-bootstrap";
import { ConflictBadge, ConflictType, TopicBadges } from "./Badges";
import { LocalizedStringsContext } from "../../LucidMindShared/localization.web";
import { getSimulatedCheckResult } from "../util";
import { getUserName, publishSubtree } from "../../LucidMindShared/helperFunctions.web";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { InfoAlert } from "./InfoAlert";
dayjs.extend(relativeTime);

interface StatementProps { 
  rootId: string, 
  statementObject: Statement, 
  statementId: string , 
  displayTopics: boolean, 
  displayHeader: boolean, 
  displayUser: boolean, 
  displayCreationTime: boolean, 
  deleteEnabled: boolean, 
  editEnabled: boolean,
  publishEnabled: boolean,
}
export function StatementBox({ 
  rootId, 
  statementObject, 
  statementId, 
  displayTopics=true, 
  displayHeader=true, 
  displayUser=false, 
  displayCreationTime=false,
  deleteEnabled=true,
  editEnabled=false,
  publishEnabled=true,
}: StatementProps) { // TODO Rename prop to statementId

  const [interactionBadgeStatementIsShown] = useState(false);
  const [topicsShown] = useState(displayTopics);
  const [headerIsShown] = useState(displayHeader);
  const [userShown] = useState(displayUser);
  const [creationTimeShown] = useState(displayCreationTime);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [conflictModalActive, setConflictModalActive] = useState(false);

  const strings = useContext(LocalizedStringsContext);

  const { statement, loading, error } = useStatementListener(rootId ? rootId : "241a43d6-ec7b-65b3-a8ea-e5db8aacfcb0");

  let navigate = useNavigate();

  useEffect(() => {
    if(userShown) {
      getUserName(statement.getUserId()).then((value) => {
        setUserName(value);
      })
    }
  }, [statement, userShown]);

  const handleViewStatement = (e: any) => {
    if (!(e.target.tagName === "button" || e.target.tagName === "svg" || e.target.tagName === "path" || e.target.tagName === "A" || e.target.tagName === "SPAN" || e.target.tagName === "BUTTON" || conflictModalActive)) {
      navigate(`/statement/${rootId}`);
    }
  };

  const onPublish = (event: any) => {
    setShowPublishModal(true);
    event.stopPropagation();
  }

  const checkForSupportingArguments = () => {
    return statement.getChildPaths().filter(childPath => statement.getChildStance(childPath) === StanceType.SUPPORT).length > 0;
  }

  const handleBelieve = () => {
    const vote = statement.getOwnVote() !== VoteType.UPVOTE
      ? VoteType.UPVOTE
      : VoteType.NOVOTE;
    statement.setOwnVote(vote);
  }

  const handleNotBelieve = () => {
    const vote = statement.getOwnVote() !== VoteType.DOWNVOTE
      ? VoteType.DOWNVOTE
      : VoteType.NOVOTE;
    statement.setOwnVote(vote);
  }

  const supportingArgumentsFound = () => {
    const supportingArgumentsFound = statement?.getChildStances().includes(StanceType.SUPPORT);
    return supportingArgumentsFound;
  }

  if (loading) {
  } else if (error) {
  }

  return (
    <>
    <Card
      className="statement border border-0 border-secondary rounded-4"
      id={statementId}
      onClick={handleViewStatement}
    // onMouseEnter={() => setInteractionBadgeStatementIsShown(true)}
    // onMouseLeave={() => setInteractionBadgeStatementIsShown(false)}
    >

      {headerIsShown? <Card.Header className="border-0 bg-transparent pb-0">
        <Stack direction="horizontal" className="align-items-start">
          <p className="m-0">
            <strong>{strings.statement + " "}</strong>
            {topicsShown? 
              <>
                <TopicBadges statement={statement}/>
                {" "}
              </> 
              : null
            }
            {userShown? strings.by + " " + userName + " " : null}
            {creationTimeShown? dayjs(statement.getCreationTime()?.toDate()).fromNow() : null}
          </p>
          <div className="ms-auto">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-basic" className="statement-options-dropdown">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                  <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item disabled={!editEnabled}>{strings.edit}</Dropdown.Item>
                <Dropdown.Item disabled={!deleteEnabled} onClick={() => statement.delete()}>{strings.delete}</Dropdown.Item>
                <Dropdown.Item disabled>{strings.share}</Dropdown.Item>
                <Dropdown.Item disabled={!publishEnabled} onClick={onPublish}>{strings.publish}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Stack>
      </Card.Header> : null }

      <Card.Body className={headerIsShown? "pt-0" : ""}>
          <Stack direction="horizontal" className="align-items-start">
            <Card.Text className="statement-text mb-0">
              {rootId ? statement.getContent() : statementObject.content}
            </Card.Text>
            {headerIsShown? 
              null 
              : 
              <div className="ms-auto">
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic" className="statement-options-dropdown">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                    </svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item disabled={!editEnabled}>{strings.edit}</Dropdown.Item>
                    <Dropdown.Item disabled={!deleteEnabled} onClick={() => statement.delete()}>{strings.delete}</Dropdown.Item>
                    <Dropdown.Item disabled>{strings.share}</Dropdown.Item>
                    <Dropdown.Item disabled={!publishEnabled} onClick={onPublish}>{strings.publish}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            }
          </Stack>
        {/* {interactionBadgeStatementIsShown && (
            <InteractionBadgeStatement statement={statement} />)
          } */}
        {interactionBadgeStatementIsShown ?
          <span className="position-absolute top-50 start-100  translate-middle badge rounded-pill bg-transparent">
            <AddConditionButton />
          </span>
          : null
        }
        {/* 
          {interactionBadgeStatementIsShown && !statement.believed ?
  
            <span className="position-absolute top-100 start-100  translate-middle badge rounded-pill bg-transparent">
              <BelieveButton statement={statement} />
            </span>
  
            : null
          } */}

      </Card.Body>

      <Card.Footer className="border-0 bg-transparent">
        <Stack direction="horizontal">
          <ConflictBadge
            type={supportingArgumentsFound() ? ConflictType.NOCONFLICT : ConflictType.SUPPORTINGARGUMENTSNOTFOUND}
            containerType={ContainerType.STATEMENT}
            supportingArgumentsFound={supportingArgumentsFound()} 
            setConflictModalActive={setConflictModalActive}          
          />
          <div className="ms-auto">
            <BookmarkButton/>
          </div>
          <div>
            <BelieveButton vote={statement.getOwnVote()} onBelieve={handleBelieve}/>
          </div>
          <div>
            <NotBelieveButton vote={statement.getOwnVote()} onNotBelieve={handleNotBelieve}/>
          </div>
        </Stack>
      </Card.Footer>

    </Card>

    <PublishModal statementPath={rootId} show={showPublishModal} handleClose={() => setShowPublishModal(false)} checkForSupportingArguments={checkForSupportingArguments}/>
    </>
  )
}

export function PublishModal({statementPath, show, handleClose, checkForSupportingArguments}: any) {

  const strings = useContext(LocalizedStringsContext);
  const [argumentCheckSuccessful, setArgumentCheckSuccessful] = useState(undefined);
  const [duplicateCheckSuccessful, setDuplicateCheckSuccessful] = useState(undefined);
  let navigate = useNavigate();

  useEffect(() => {
    setArgumentCheckSuccessful(undefined);
    setDuplicateCheckSuccessful(undefined);
    setArgumentCheckSuccessful(checkForSupportingArguments());
    getSimulatedCheckResult(true, 3000)
      .then(({checkSuccessful}: any) => {
        setDuplicateCheckSuccessful(checkSuccessful)
      });
   }, [checkForSupportingArguments, show])

   const publishStatement = () => {
      publishSubtree(statementPath)
        .then(() => navigate("/public"))
      ;
   }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton className="border-0">
          <Modal.Title>
            {strings.publish_statement}
          </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-0">
        <Stack gap={2}>
          <StatementBox statementId={statementPath} rootId={statementPath} statementObject={new Statement("Loading", false)} displayTopics={false} displayHeader={true} displayUser={true} displayCreationTime={false} deleteEnabled={false} editEnabled={false} publishEnabled={false} />
          <InfoAlert content={strings.publish_checks_coming_soon_info}/>
          {duplicateCheckSuccessful === undefined ? 
            <Alert variant="warning" className="m-0">
              <Spinner as="span" animation="border" size="sm"/>{strings.duplicate_check_loading}
            </Alert>
          : duplicateCheckSuccessful ?
            <Alert variant="success" className="m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>{strings.duplicate_check_successful}
            </Alert>
            :
            <Alert variant="danger" className="m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>{strings.duplicate_check_failed}
            </Alert>
          }
          {argumentCheckSuccessful === undefined ? 
            <Alert variant="warning" className="m-0">
              <Spinner as="span" animation="border" size="sm"/>{strings.supporting_arguments_loading}
            </Alert>
          : argumentCheckSuccessful ?
            <Alert variant="success" className="m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>{strings.supporting_arguments_found}
            </Alert>
            :
            <Alert variant="danger" className="m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>{strings.supporting_arguments_not_found}
            </Alert>
          }
          {argumentCheckSuccessful === undefined ? 
            <Alert variant="warning" className="m-0">
              <Spinner as="span" animation="border" size="sm"/>{strings.checks_loading}
            </Alert>
          : argumentCheckSuccessful ?
            <Alert variant="success" className="m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>{strings.checks_successful}
            </Alert>
            :
            <Alert variant="danger" className="m-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>{strings.checks_failed}
            </Alert>
          }
        </Stack>
      </Modal.Body>
      <Modal.Footer className="border-0">
        {argumentCheckSuccessful ? 
          <Button onClick={publishStatement}>{strings.publish}</Button>
          :
          <Button disabled>{strings.publish}</Button>
        }
      </Modal.Footer>
    </Modal>
  )
}