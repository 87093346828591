import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState, useContext } from 'react';
import { CategoryData, ContainerType, StanceType, _CategoryData, dbPath } from '../LucidMindShared/dataModel.web';
import { createPathWithUUID, getLocationFromPath } from '../LucidMindShared/helperFunctions.web';
import { getAuth } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { Badge, Stack } from 'react-bootstrap';
import { CancelButton, ConfirmButton } from './Common/Buttons';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';

export function AddTopicButtonInlineAndModal(props: any) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="secondary" onClick={handleShow}>
                Neues Thema erstellen
            </Button>

            <Modal show={show} onHide={handleClose} backdrop='static' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Neues Thema erstellen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TopicForm onClose={handleClose} parentPath={''} />
                </Modal.Body>
            </Modal>
        </>
    )
}

interface TopicFormProps {parentPath: dbPath, onClose: Function}
export function TopicForm({parentPath, onClose}: TopicFormProps) {

    const [newTopicName, setNewTopicName] = useState('');
    const strings = useContext(LocalizedStringsContext);

    const handleCreateTopic = () => {
        saveCategoryToDB();
        onClose();
    }

    const saveCategoryToDB = () => {
        const userId = getAuth().currentUser?.uid || "";
        const path = createPathWithUUID(getLocationFromPath(parentPath), ContainerType.CATEGORY);
        const categoryData: _CategoryData = {
            name: newTopicName,
            type: ContainerType.CATEGORY,
            path: path,
            userId: userId,
            parentPaths: [parentPath],
            childPaths: [],
            votes: {},
            creationTime: Timestamp.now(),
          };
          CategoryData.makeInDB(categoryData, StanceType.NEUTRAL);
    }

    return (
        <Form>
            <Badge className="topic-badge topic-badge-big-inactive" pill>
                <Stack direction='horizontal' gap={0}>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder={strings.add_topic_name_hint}
                            autoFocus
                            onChange={(event) => setNewTopicName(event.target.value)}
                            className='topic-input'
                        />
                    </Form.Group>
                    <CancelButton onClick={onClose} />
                    <ConfirmButton onClick={handleCreateTopic}/>
                </Stack>
            </Badge>
        </Form>
    )

}