import { Col, Image } from 'react-bootstrap';

export const BenefitCol = ({ header, text, icon }: any) => {
    return (
        <Col className='pt-0 p-0 p-sm-2 p-md-3'>
            <Image className='pl-0 pb-0' key="introImage" src={icon} fluid width={"75%"} height={"75%"}/>
            <h3 className='fs-6'>{header}</h3>
            <p className='fs-6 mb-0'>{text}</p>
        </Col>
    )
};