import { Alert, Col, Container, Placeholder, PlaceholderButton, Row, Stack } from "react-bootstrap";
import { ContainerType, useCategoryListener } from "../LucidMindShared/dataModel.web";
import { Header } from "../components/Common/Header";
import { StatementBox } from "../components/Common/StatementBox";
import { Statement, Topic } from "../model";
import { AddButtonAndModal, ToggleTopicViewButton } from "../components/Common/Buttons";
import { NavigationBar } from "../components/Common/NavigationBar";
import { useContext, useState } from "react";
import { InfoAlert } from "../components/Common/InfoAlert";
import { LocalizedStringsContext } from "../LucidMindShared/localization.web";
import { useParams } from "react-router-dom";
import { getTypeFromPath, useUserState } from "../LucidMindShared/helperFunctions.web";
import { NavigationBarBottom } from "../components/Common/NavigationBarBottom";
import { TopicBadgeBig } from "../components/Common/Badges";
import { FooterFeed } from "../components/Common/Footer";


interface TopicViewProps { topic: Topic, topicId: string, topics: Topic[] }
export function TopicView({ topic, topicId, topics }: TopicViewProps) {

    const [view, setView] = useState("list");
    const { id } = useParams();
    const user = useUserState();

    return (
        <>
            <Header topics={topics} />

            <Row>
                <Col className="navigation-sidebar d-none d-sm-block d-md-block d-lg-block d-xl-block" xs={0} sm={4} xl={2}>
                    <NavigationBar />
                </Col> 
                <Col xs={12} sm={8} xl={8} className="full-height">
                    {user ? <TopicListView topicId={id ? id : ""} view={view} setView={setView}/> : null}
                </Col>
            </Row>

            <AddButtonAndModal 
                parentPath={id? id : ""}
                enableAddTopic={true}
                enableAddArgument={false}
                enableAddStatement={true}
                enableAddSource={false}
            />

            <NavigationBarBottom/>

            <FooterFeed/>

        </>
    )
}

interface TopicListViewProps {topicId: string, view: string, setView: Function}
function TopicListView ({topicId, view, setView}: TopicListViewProps) {

    const { category, loading, error } = useCategoryListener(topicId);
    const strings = useContext(LocalizedStringsContext);

    return (
        <>
            {!error ?
                <Container className="pb-3">
                    <Row className="py-4">
                        <Col xs={"auto"} sm={10} className="d-flex align-items-center">
                            <Container className="px-0">
                                <Row className="g-1 gy-2">
                                    {loading? 
                                        <Col xs={12} sm={12} md={"auto"} className="mr-1">
                                            <Placeholder className="my-4" size="lg" as="h3" xs={5} />
                                        </Col>
                                        :
                                        <Col xs={12} sm={12} md={"auto"} className="mr-1">
                                            <TopicBadgeBig topicPath={topicId} active={true} />
                                        </Col>
                                    }
                                    {category?.getChildPaths().map((childPath) => getTypeFromPath(childPath) === ContainerType.CATEGORY ? 
                                        <Col xs="auto">
                                            <TopicBadgeBig topicPath={childPath} key={childPath} />
                                        </Col>
                                        : 
                                        null
                                    )}
                                </Row>
                            </Container>
                        </Col>
                        <Col xs={"auto"} sm={2}  className="pt-3 pt-sm-0 align-items-start justify-content-end d-none d-sm-flex">
                            {loading ?
                                <PlaceholderButton style={{ width: '6em' }} bg="secondary"/>
                                :
                                <ToggleTopicViewButton view={view} setView={setView}/>
                            }
                        </Col>
                    </Row>
                    {view === "list" ?
                        <>
                            <Row xs={1} md={2} xl={3} className="g-2">
                                {category?.getChildPaths().map(childId => getTypeFromPath(childId) === ContainerType.STATEMENT ?
                                    <Col>
                                        <StatementBox 
                                            key={childId}
                                            rootId={childId}
                                            statementId={childId}
                                            statementObject={new Statement("Loading", false)}
                                            displayHeader={false}
                                            displayTopics={false}
                                            displayUser={false}
                                            displayCreationTime={false} 
                                            deleteEnabled={true} 
                                            editEnabled={false} 
                                            publishEnabled={true}                                        />
                                    </Col> 
                                    :
                                    null 
                                )}    
                            </Row>
                            {!loading && category?.getChildPaths().length === 0 ? 
                                <Container className="px-0 pt-3">
                                    <InfoAlert content={strings.no_statements_info}/> 
                                </Container>
                                : 
                                null
                            }
                        </>
                        :
                        <InfoAlert content={strings.tree_view_info}/>
                    }
                </Container>
                :
                null
            }
            {error ? 
                <Container>
                    <Alert variant="danger">
                        Error at loading topic. Please try again.
                    </Alert>
                </Container>
                : 
                null
            }
        </>
    )
}