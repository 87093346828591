import Xarrow from "react-xarrows"; // https://eliav2.github.io/react-xarrows/, alternative https://www.npmjs.com/package/react-arrows
import { StatementBox } from "../components/Common/StatementBox";
import { ConditionalSyllogismBadge } from "../components/Common/Badges";
import { Header } from "../components/Common/Header";
import { Statement, Topic } from "../model";
import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react';
import ReactFlow, {
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  useReactFlow,
  useUpdateNodeInternals,
  ReactFlowProvider,
  applyEdgeChanges,
  MarkerType,
  applyNodeChanges,
  addEdge,
  Handle,
  Position,
  MiniMap,
  Panel,
  FitBoundsOptions,
  FitViewOptions
} from 'reactflow';
import StatementNode from '../components/StatementNode';
import ArgumentNode from '../components/ArgumentNode';
import ArgumentParentNode from "../components/ArgumentParentNode";
import TopicNode from '../components/TopicNode';
import AddStatementNode from '../components/AddStatementNode';
import { getArgumentIds, getTypeFromPath, useUserState } from "../LucidMindShared/helperFunctions.web";
import { getEdge } from "../components/util";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ContainerType, StanceType, useStatementListener } from "../LucidMindShared/dataModel.web";
import { ConditionsListItem } from "../components/Common/ConditionsListItem";
import { AddButtonAndModal, ProposeArgumentButton } from "../components/Common/Buttons";
import { NavigationBar } from "../components/Common/NavigationBar";
import { SourceListItem } from "../components/Common/SourceListItem";
import { LocalizedStringsContext } from "../LucidMindShared/localization.web";
import { InfoAlert } from "../components/Common/InfoAlert";
import { NavigationBarBottom } from "../components/Common/NavigationBarBottom";
import { FooterFeed } from "../components/Common/Footer";
let initialNodes: any = [];
let initialEdges: any = [];
// TODO Move constants to global file
let nodeHeight = 150; // TODO Relative to amount of nodes displayed?
const nodeElementMargin = 20;
let nodeElementWidth = 250;
let nodeElementHeight = nodeHeight * 0.95; // TODO Relative to amount of nodes displayed, relative to nodeHeight
let nodeBorderRadius = 20;
let argumentWidth = 50;
let argumentHeight = argumentWidth;
let argumentNodeWidth = nodeElementWidth * 1.1;
let nodeId = 0;
const getId = () => `${nodeId++}`;
const defaultEdgeOptions = { // TODO Move to separate file to use in statement and world view
  type: 'straight',
  markerStart: {
    type: MarkerType.ArrowClosed,
  },
};
const nodeTypes = {  // TODO Move to separate file to use in statement and world view
  statement: StatementNode,
  addStatement: AddStatementNode,
  argument: ArgumentNode,
  argumentParent: ArgumentParentNode,
  topic: TopicNode
};
const fitBoundsOptions: FitBoundsOptions = {
  // duration?: number;
  // padding?: number;
};
const fitViewOptions: FitViewOptions = {
  padding: undefined,
  includeHiddenNodes: undefined,
  minZoom: 1,
  maxZoom: 1,
  duration: undefined,
  nodes: undefined
};

interface StatementViewProps { statement: Statement, topics: Topic[] }
export function StatementView({ statement, topics }: StatementViewProps) {

  const { id } = useParams();

  // useEffect(() => {
  //   console.log("Add")
  //   addInitialNodes("a0487190-08a1-9c61-3aef-c26acb10e7c2");
  // }, []);

  const user = useUserState();

  return (
    <>
      <Header topics={topics} />
      {/* <StatementViewReactFlowProvider statementId="c0111236-d44c-b919-b43d-be7f7e470166" /> */}
      {user ? <StatementViewList statementId={id ? id : ""} /> : null}

      <AddButtonAndModal parentPath={id ? id : ""} enableAddTopic={false} enableAddArgument={true} enableAddStatement={false} enableAddSource={true}/>
    </>
  )
}

interface StatementViewListProps { statementId: string }
function StatementViewList({ statementId }: StatementViewListProps) {

  const { statement, loading, error } = useStatementListener(statementId);
  const strings = useContext(LocalizedStringsContext);

  return (
    <>
      <Row>
                <Col className="navigation-sidebar d-none d-sm-block d-md-block d-lg-block d-xl-block" xs={0} sm={4} xl={2}>
                    <NavigationBar />
                </Col> 
                <Col xs={12} sm={8} xl={8} className="full-height">
                  <Container className="pb-3">
                    <Row className="justify-content-center my-3 mx-0">
                      <StatementBox statementId={statementId} rootId={statementId} statementObject={new Statement("Loading", false)} displayTopics={true} displayHeader={true} displayUser={false} displayCreationTime={false} deleteEnabled={true} editEnabled={false} publishEnabled={true} />
                    </Row>
                    {!loading && statement.getChildPaths().length === 0 ?
                      <Row className="justify-content-center my-3 mx-0 g-3">
                        <InfoAlert content={strings.no_arguments_info} />
                        <ProposeArgumentButton />
                      </Row> 
                      : 
                      null
                    }
                    <Row className="justify-content-center g-3">
                      {statement?.getChildPaths().filter(childPath => 
                        statement.getChildStance(childPath) === StanceType.SUPPORT || statement.getChildStance(childPath) === StanceType.NEUTRAL).length > 0 ?
                        <Col>
                          <Stack gap={3}>
                            {statement?.getChildPaths().map((childPath: string) => 
                              (getTypeFromPath(childPath) === ContainerType.STATEMENT || getTypeFromPath(childPath) === ContainerType.STATEMENT_GROUP)
                              && statement.getChildStance(childPath) === StanceType.SUPPORT ? 
                                <ConditionsListItem key={childPath} id={childPath} parentId={statementId} stance={StanceType.SUPPORT} /> : null
                            )}
                            {statement?.getChildPaths().map((childPath: string) => 
                              getTypeFromPath(childPath) === ContainerType.SOURCE 
                              && (statement.getChildStance(childPath) === StanceType.SUPPORT || statement.getChildStance(childPath) === StanceType.NEUTRAL) ? 
                                <SourceListItem key={childPath} path={childPath} stance={StanceType.SUPPORT}/> : null
                            )}
                          </Stack>
                        </Col>
                      : null}
                      {statement?.getChildPaths().filter(childPath => 
                        statement.getChildStance(childPath) === StanceType.OPPOSE).length > 0 ?
                        <Col>
                          <Stack gap={3}>
                            {statement?.getChildPaths().map((childPath: string) =>
                              (getTypeFromPath(childPath) === ContainerType.STATEMENT || getTypeFromPath(childPath) === ContainerType.STATEMENT_GROUP)
                              && statement.getChildStance(childPath) === StanceType.OPPOSE ? 
                                <ConditionsListItem key={childPath} id={childPath} parentId={statementId} stance={StanceType.OPPOSE} /> : null
                            )}
                            {statement?.getChildPaths().map((childPath: string) => 
                              getTypeFromPath(childPath) === ContainerType.SOURCE 
                              && statement.getChildStance(childPath) === StanceType.OPPOSE ? 
                                <SourceListItem key={childPath} path={childPath} stance={StanceType.OPPOSE}/> : null
                            )}
                          </Stack>
                        </Col>
                      : null }
                    </Row>
                  </Container>
                </Col>
            </Row>

            <NavigationBarBottom/>

            <FooterFeed/>

    </>
  )
}

interface StatementViewReactFlowProps { statementId: string }
function StatementViewReactFlow({ statementId }: StatementViewReactFlowProps) {

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { getNode, project } = useReactFlow(); // = reactFlowInstance
  const onConnect = useCallback((params: any) => setEdges((eds) => addEdge(params, eds)), []);
  const updateNodeInternals = useUpdateNodeInternals();
  const statementListener = useStatementListener(statementId);
  const [argumentIds, setArgumentIds] = useState([]);

  useEffect(() => {
    console.log("Arg ids changed!");
    async function getArgumentIdsInternalFunction(statementId: string) {
      const argumentIdsArray = await getArgumentIds(statementId)
      setArgumentIds(argumentIdsArray);
    }

    if (argumentIds.length !== 0) {
      const newNodes: any = [];
      const newEdges: any = [];
      argumentIds.forEach((argumentChildIds: string[], argumentIndex, argumentIds) => {
        console.log("ArgChildIds", argumentChildIds);

        const offset = -(((argumentIds.length - 1) * (nodeElementWidth + 50)) - (0 * (nodeElementWidth + 50))) / 2;
        const gapBetweenStatementNodes = 10;
        const margin = 10;
        const argumentParentNode = {
          id: getId(),
          type: 'argumentParent',
          data: {
            // label: 'Argument Parent Node'
          },
          style: {
            width: nodeElementWidth + 2 * margin,
            height: nodeElementHeight * argumentChildIds.length + gapBetweenStatementNodes * (argumentChildIds.length - 1) + 2 * margin,
            borderRadius: 0.1 * nodeElementWidth * 1.1
          },
          position: {
            x: -nodeElementWidth / 2 + argumentIndex * (nodeElementWidth * 1.1 + 50) + offset,
            y: -nodeElementHeight / 2 + nodeElementHeight * 1.1 + 50
          },
        }
        newNodes.push(argumentParentNode);
        const newEdge: any = getEdge(nodes[0].id, argumentParentNode.id, Math.random() > 0.5 ? "default" : "negation", "default", false);
        // newEdge.sourceHandle = argumentIndex.toString();
        console.log("newEdge", newEdge)
        newEdges.push(newEdge);

        argumentChildIds.forEach((argumentChildId: string, argumentConditionIndex: number, argumentChildIds) => {
          const newNode: any = getStatementNode(argumentChildId, getId());
          const totalNodeHeight = argumentParentNode.style.height;
          const contentHeightWithGaps = nodeElementHeight * argumentChildIds.length + gapBetweenStatementNodes * (argumentConditionIndex - 1);
          const marginOffsetY = (totalNodeHeight - contentHeightWithGaps) / 2;
          newNode.data.viewType = "horizontal";
          newNode.position = {
            x: (argumentNodeWidth - nodeElementWidth) / 2,
            y: marginOffsetY + nodeElementHeight * argumentConditionIndex + gapBetweenStatementNodes * (argumentConditionIndex - 1) // -nodeElementHeight / 2 + nodeElementHeight + 50
          }
          newNode.parentNode = argumentParentNode.id;
          console.log("newNode", newNode);
          newNodes.push(newNode);
        });

      })
      setNodes(nodes => nodes.concat(nodes, newNodes));
      setEdges(edges => edges.concat(edges, newEdges));
    } else {
      getArgumentIdsInternalFunction(statementId);
    }

  }, [argumentIds])

  useEffect(() => {
    const newNode: any = getStatementNode(statementId, getId())
    newNode.position = { x: -nodeElementWidth / 2, y: -nodeElementHeight / 2 };
    newNode.data.isBasicStatement = true;
    newNode.data.viewType = "horizontal";
    console.log("newNOdeStatement", newNode);
    setNodes(nodes => nodes.concat(nodes, newNode));
  }, []);

  const onNodeClick = useCallback((event: any, node: any) => {
    // if (node.type === "addStatement") {
    //   const newNode = getStatementNode(new Statement("Neue Aussage", false), getId(), 0, 0, false);
    //   newNode.position = project(node.position);
    //   setNodes((nodes) => {
    //     nodes = nodes.filter(n => n.id !== node.id);
    //     nodes = nodes.concat(nodes, newNode);
    //     return nodes;
    //   });
    // }
  }, [])

  return (
    <div className="wrapper" ref={reactFlowWrapper} style={{ height: window.innerHeight, width: document.getElementById('root')?.clientWidth }}>
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        onNodeClick={onNodeClick}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        defaultEdgeOptions={defaultEdgeOptions}
        connectionLineType={'straight'}
        nodeTypes={nodeTypes}
        minZoom={1}
        maxZoom={1}
        defaultViewport={{ x: document.getElementById('root')?.clientWidth / 2, y: 100, zoom: 1 }}
        style={{ backgroundColor: '#fffcfa' }}
      >
      </ReactFlow>
    </div>
  );
}

interface StatementViewReactFlowProviderProps { statementId: string }
function StatementViewReactFlowProvider({ statementId }: StatementViewReactFlowProviderProps) {
  return (
    <ReactFlowProvider>
      <StatementViewReactFlow statementId={statementId} />
    </ReactFlowProvider>
  );
}

function getStatementNode(statementId: string, nodeId: string) {
  return {
    id: (nodeId).toString(),
    type: 'statement',
    data: {
      label: "Test",
      type: 'statement',
      statement: undefined,
      isBasicStatement: false,
      width: nodeElementWidth,
      height: nodeElementHeight,
      statementId: statementId,
      position: {
        x: 0,
        y: 0
      },
    },
    style: {
      width: nodeElementWidth, //  * (Math.max(0.5, depth,
      height: nodeElementHeight,
      borderRadius: 0.1 * nodeElementWidth
    },
    position: {
      x: 0,
      y: 0
    }
  };
}

export function StatementViewOld(props) { // Bootstrap Carousel Approach
  return (
    <>
      <Header topics={props.topics} />

      <div className="statement-view container">
        <div className="conditions carousel carousel-dark slide" id="conditions-carousel">
          {/* <div class="carousel-indicators">
            {props.statement.getConditionalSyllogisms().map(
              (syllogism, syllogismIndex) =>
                <button
                  type="button"
                  data-bs-target="#conditions-carousel"
                  data-bs-slide-to={syllogismIndex}
                  class={syllogismIndex === 0 ? "active" : ""}
                  aria-current={syllogismIndex === 0 ? "true" : "false"}
                  aria-label={"Slide " + syllogismIndex}></button>
            )}
          </div> */}
          <div className='carousel-inner'>
            {props.statement.getConditionalSyllogisms().map(
              (syllogism, syllogismIndex) =>
                <div className={syllogismIndex === 0 ? 'carousel-item active' : 'carousel-item'}>
                  <div className='row mx-4 mt-1 mb-5 g-2' id={"condition" + syllogismIndex}>
                    {syllogism.conditions.map(
                      (condition, conditionIndex) =>
                        <StatementBox statement={condition} />
                    )}
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                      fill={syllogism.type === "negation" ? "red" : "green"} class="bi bi-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                    </svg>
                  </div>
                </div>
            )}
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#conditions-carousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon br-1" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#conditions-carousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div className='row my-2 mx-4 justify-content-end' id="counter-badges">
          <div className='col'>
            <ConditionalSyllogismBadge type="positive" statement={props.statement} />
            <ConditionalSyllogismBadge type="negative" statement={props.statement} />
          </div>
        </div>
        <div className='statement-main my-0 gx-0 gy-0 rounded-4 align-items-center justify-content-evenly'>
          <div className='row my-2 mx-4' id="statement">
            <StatementBox statement={props.statement} statementId="conclusion1" />
          </div>
        </div>
        {/* <div className="conclusions carousel carousel-dark slide" id="conclusions-carousel">
          <div class="carousel-indicators">
            {props.statement.getConclusionalSyllogisms().map(
              (syllogism, syllogismIndex) =>
                <button
                  type="button"
                  data-bs-target="#conclusions-carousel"
                  data-bs-slide-to={syllogismIndex}
                  class={syllogismIndex === 0 ? "active" : ""}
                  aria-current={syllogismIndex === 0 ? "true" : "false"}
                  aria-label={"Slide " + syllogismIndex}></button>
            )}
          </div>
          <div className='carousel-inner'>
            {console.log(props.statement.getConclusionalSyllogisms())}
            {props.statement.getConclusionalSyllogisms().map(
              (syllogism, syllogismIndex) =>
                <div className={syllogismIndex === 0 ? 'carousel-item active' : 'carousel-item'}>
                  <div className='row mx-4 mt-1 mb-5 g-2' id={"condition" + syllogismIndex}>
                    {syllogism.conditions.map(
                      (condition, conditionIndex) =>
                        condition !== props.statement ?
                          <div className='row align-items-center'>
                            <div className='col-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                              </svg>
                            </div>
                            <div className='col'>
                              <StatementBox statement={condition} />
                            </div>
                          </div>
                          : null
                    )}
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                      fill={syllogism.type === "negation" ? "red" : "green"} class="bi bi-arrow-down" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" />
                    </svg>
                    <StatementBox statement={syllogism.conclusion} />
                  </div>
                </div>
            )}
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#conclusions-carousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon br-1" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#conclusions-carousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> */}
      </div >
    </>
  );
}

export function StatementViewXarrow(props) { // State in Function verändern möglich oder muss hier ein Component hin, damit gererendert wird bei hinzufügen eines Statement // ANTWORT: Nein handleAddStatement weitergeben!
  return (
    <div className="container StatementView">
      <div className={"row-cols-1" + "conditions row gx-5 gy-5 align-items-center row justify-content-evenly"} id="conditions">
        {props.statement.getConditionsOfSyllogisms().map(
          (conditions, conditionsIndex) =>
            <div className='col-6'>
              <div className='row my-5 gx-5 gy-5 align-items-center justify-content-evenly' id={"condition" + conditionsIndex}>
                {conditions.map(
                  (condition, conditionIndex) =>
                    <div className='col-6 border p-3 bg-light'>
                      <StatementBox statement={condition} />
                      {/* {index < props.statement.conditions.length - 1 ?
                          <Conjunction />
                          :
                          <AddStatementButton statement={props.statement} addStatement={props.addStatement} statementType="precondition" />
                        } */}
                      <Xarrow start={"condition" + conditionsIndex} end="conclusion1" />
                    </div>
                )}
              </div>
            </div>
        )}
      </div>
      <div className='statement row my-5 gx-5 gy-5 align-items-center justify-content-evenly'>
        <div className='col-4 border p-3' id="stat1">
          <StatementBox statement={props.statement} statementId="conclusion1" />
        </div>
      </div>
      <div className="conclusions" id="imp1">
        {/* {props.statement.getConclusions.map((conclusion, index) =>
            <div>
              <StatementBox content={conclusion} />
              {index < props.statement.implications.length - 1 ?
                <Conjunction />
                :
                <AddStatementButton statement={props.statement} addStatement={props.addStatement} statementType="implication" />
              }
            </div>
          )} */}
      </div>


    </div >
  );
}