import {Card, Stack } from "react-bootstrap";
import { ContainerType, StanceType, dbPath, useSourceListener } from "../../LucidMindShared/dataModel.web";
import { ConflictBadge, ConflictType } from "./Badges";
import { OptionsDropdown } from "./Buttons";

interface SourceListItemProps {path: dbPath, stance: StanceType}
export function SourceListItem({ path, stance }: SourceListItemProps): JSX.Element {
    
    const { source, loading, error } =  useSourceListener(path);

    if (loading) {
    } else if (error) {
    }

    const onDelete = () => source.delete();

    return (
        <Card className={stance === StanceType.SUPPORT || stance === StanceType.NEUTRAL ? "source-card source-card-pro" : "source-card source-card-contra"}>
            <Card.Body className="source-card-body">
                <Card className="source">
                    <Card.Body>
                        <Stack direction="horizontal" className="p-2 align-items-start">
                            <Card.Text className="statement-text mb-0">
                                {`"${source?.getContent()}"`}
                                {source.getReference().length > 0 ? 
                                    <p className="text-muted mb-0">
                                    <a
                                      className="text-muted"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={source?.getUrl()}
                                      title="Caution: You are leaving our website."
                                      onClick={(e) => {
                                        if (!window.confirm("Are you sure you want to leave our website?")) {
                                          e.preventDefault();
                                        }
                                      }}
                                    >
                                      {source?.getUrl()}
                                    </a>
                                    {` (${source?.getReference()})`}
                                  </p>
                                    :
                                    <p className="text-muted mb-0">
                                        {source?.getUrl()}
                                    </p>
                                }
                            </Card.Text>
                            <div className="ms-auto">
                                <OptionsDropdown horizontal={false} deleteEnabled={true} onDelete={onDelete} editEnabled={false} onEdit={()=>{}} publishEnabled={false} onPublish={()=>{}} shareEnabled={false} onShare={()=>{}}/>
                            </div>
                        </Stack>
                    </Card.Body>
                    <Card.Footer className="border-0 bg-transparent">
                        <Stack direction="horizontal" className="my-1">
                            <ConflictBadge type={ConflictType.SOURCENOTCHECKED} containerType={ContainerType.SOURCE} supportingArgumentsFound={false} setConflictModalActive={() => {}}/>
                        </Stack>
                    </Card.Footer>
                </Card>
            </Card.Body>
        </Card>
    );
}