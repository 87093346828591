import { AddButtonAndModal } from "../components/Common/Buttons";
import { Header } from "../components/Common/Header";
import { TopicListHorizontal } from "../components/TopicList";
import { StatementBox } from "../components/Common/StatementBox";
import { Statement } from "../model";
import { ContainerType, FirestoreConstants, useContainerListener } from "../LucidMindShared/dataModel.web";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getTypeFromPath, useUserState } from "../LucidMindShared/helperFunctions.web";
import { NavigationBar } from "../components/Common/NavigationBar";
import { LocalizedStringsContext } from "../LucidMindShared/localization.web";
import { InfoAlert } from "../components/Common/InfoAlert";
import { NavigationBarBottom } from "../components/Common/NavigationBarBottom";
import { useLocation } from "react-router-dom";
import { IntroductionModal } from "../components/IntroductionModal";
import { FooterFeed } from "../components/Common/Footer";

export function MyWorldView(props: any) {

    const user = useUserState();
    const [params, setParams] = useState<string|string[]|null>(null);
    const location = useLocation();
    const [showTutorial, setShowTutorial] = useState<boolean|null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const singleValue = queryParams.get('tutorial');
        setShowTutorial(singleValue === "true");
        if(!singleValue) return;
        setParams(singleValue);    
      }, [location.search, params, showTutorial])

    return (
        <>
            <Header topics={props.topics} />

            <Row>
                <Col className="navigation-sidebar d-none d-sm-block d-md-block d-lg-block d-xl-block" xs={0} sm={4} xl={2}>
                    <NavigationBar activeTab="myWorldView"/>
                </Col> 
                <Col xs={12} sm={8} xl={8} className="full-height">
                    {user ? 
                        <YourStatements />
                        : 
                        null
                    }
                </Col>
                <Col xs={0} sm={0} xl={2}>

                </Col>
            </Row>

            <AddButtonAndModal 
                parentPath={FirestoreConstants.ROOT_PATH}
                enableAddTopic={true}
                enableAddArgument={false}
                enableAddStatement={true}
                enableAddSource={false}
            />

            <NavigationBarBottom/>

            <FooterFeed/>

            <IntroductionModal externalShow={showTutorial? showTutorial: false} />

        </>

    );
}

function YourStatements() {

    const { container, loading, error } = useContainerListener(FirestoreConstants.ROOT_PATH);
    const strings = useContext(LocalizedStringsContext);

    return (
        <>
            <Container className="mx-auto pb-3">
                {/* <h4>{strings.personal_statements_header}</h4> */}
                <TopicListHorizontal/>
                <Row xs={1} md={2} xl={3} className="g-2">
                    {container?.getChildPaths().map((childPath: string) => 
                        getTypeFromPath(childPath) === ContainerType.STATEMENT?
                            <Col key={childPath}>
                                <StatementBox 
                                    key={childPath}
                                    statementId={childPath}
                                    rootId={childPath}
                                    statementObject={new Statement("Loading", false)}
                                    displayTopics={false}
                                    displayHeader={false}
                                    displayUser={false}
                                    displayCreationTime={false} 
                                    deleteEnabled={true} 
                                    editEnabled={false} 
                                    publishEnabled={true}                                />
                            </Col>
                            :
                            loading? 
                                null
                                :error? 
                                    null 
                                    : null
                    )}
                    {!loading && container.getChildPaths().length === 0 ?
                        <div>
                            <InfoAlert content={strings.no_statements_info} />
                        </div>
                        :
                        null
                    }
                </Row>
            </Container>
        </>
    )
}