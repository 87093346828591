import { useRootIdsListener, useStatementListener } from "./LucidMindShared/helperFunctions.web";

export class User {

    constructor() {

    }

    getUserData() {
        // const rootIdsListener = useRootIdsListener();
        // console.log(rootIdsListener);
        // const id = route.params && route.params.id ? route.params.id : rootIdsListener.data[0];
        // const rootStatementListener = useStatementListener(props.id);
        // console.log(rootStatementListener);
    }
}

/**
 * Represents a fact or a rule as an conclusion of conditions.
 * @constructor
 * @param {string} content
 * @param {Statement[]} // TODO Documentation
 */
export class Statement { // TODO Convert Backend Objects to Frontend Model Objects: https://firebase.google.com/docs/firestore/query-data/get-data#custom_objects
    id: string;
    content: string;
    believed: boolean;
    syllogisms: Syllogism[];

    constructor(content: string, believed: boolean = false, id: string = "") {
        this.content = content;
        this.believed = believed;
        this.syllogisms = [];
        this.id = id;
    }

    addConditions(conditions: Statement[]) { // TODO Refactor
        let syllogism = new Syllogism(
            conditions,
            this,
            []
        );
        return this;
    }

    addPrecondition(condition: Statement) { // TODO Refactor
        this.syllogisms.push(
            new Syllogism(
                [condition],
                this,
                []
            )
        );
        return this;
    }

    addImplication(conclusion: Statement) { // TODO Refactor
        this.syllogisms.push(
            new Syllogism(
                [this],
                conclusion,
                []
            )
        );
        return this;
    }

    /**
     * 
     * @returns conditions: List of conditions seperated by syllogisms
     */
    getConditionsOfSyllogisms() {
        let conditions: Statement[] = [];
        this.syllogisms.forEach(syllogism => {
            if (syllogism.conclusion.content === this.content) // TODO Also possible: (syllogism.conclusion == this)?
                conditions = conditions.concat(syllogism.conditions);
        });
        return conditions;
    }

    /**
     * 
     * @returns syllogisms with statement as conclusion
     */
    getConditionalSyllogisms() {
        let conditionalSyllogisms: Syllogism[] = [];
        this.syllogisms.forEach(syllogism => {
            if (syllogism.conclusion === this)
                conditionalSyllogisms.push(syllogism);
        });
        return conditionalSyllogisms;
    }

    getAmountOfNegativeConditionalSyllogisms() {
        let amountNegativeConditionalSyllogisms = 0;
        this.getConditionalSyllogisms().forEach(
            conditionalSyllogism =>
                amountNegativeConditionalSyllogisms +=
                conditionalSyllogism.type === "negation" ? 1 : 0
        );
        return amountNegativeConditionalSyllogisms;
    }

    getAmountOfPositiveConditionalSyllogisms() {
        let amountPositiveConditionalSyllogisms = this.getConditionalSyllogisms().length - this.getAmountOfNegativeConditionalSyllogisms();
        return amountPositiveConditionalSyllogisms;
    }

    getConclusions() {
        let conclusions: Statement[] = [];
        this.syllogisms.forEach(syllogism => {
            if (syllogism.conclusion.content !== this.content) // TODO Also possible: (Syllogism.conclusion == this)?
                conclusions.push(syllogism.conclusion);
        })
        return conclusions;
    }

    /**
     * 
     * @returns list of syllogisms, with statement as one of the conditions
     */
    getConclusionalSyllogisms() {
        let conclusionalSyllogisms: Syllogism[] = [];
        this.syllogisms.forEach(syllogism =>
            syllogism.conditions.forEach(condition =>
                condition === this ?
                    conclusionalSyllogisms.push(syllogism) : null
            )
        )
        return conclusionalSyllogisms;
    }
}

enum ArgumentType {
    DEFAULT = "default",
    NEGATION = "negation"
}

/**
 * Represents a syllogism with conditions and one conclusion.
 * @constructor
 * @param {string} conditions
 * @param {Statement[]} conclusion
 * @param {string} type one of "negation" or "default"
 * @param {bool} understood
 * @param {string} topics Topics that this syllogism is related to
 */
export class Syllogism {
    conditions: Statement[];
    conclusion: Statement;
    topics: string[];
    type: ArgumentType;
    understood: boolean;
    id: string;

    constructor(
        conditions: Statement[],
        conclusion: Statement,
        topics: string[],
        id: string = "",
        type: string = "default",
        understood: boolean = false,
    ) {
        this.conditions = conditions;
        this.conclusion = conclusion;
        this.topics = topics;
        this.type = type === "negation" ? ArgumentType.NEGATION : ArgumentType.DEFAULT;
        this.understood = understood;
        this.id = id;

        conclusion.syllogisms.push(this);
        conditions.forEach((condition) => condition.syllogisms.push(this));

    }

    getConditions() {
        return this.conditions;
    }

    getConclusion() {
        return this.conclusion;
    }
}

export class Topic {
    name: string;
    syllogisms: Syllogism[];
    statements: Statement[];
    id: string;

    constructor(
        name: string,
        syllogisms = [],
        statements = [],
        id = ""
    ) {
        this.name = name;
        this.syllogisms = syllogisms;
        this.statements = statements;
        this.id = id;
    }

    getBasicStatements(maximumStatements = 1) {
        let basicStatements = this.statements.filter(
            (statement) => statement.getConclusionalSyllogisms().length === 0)
        if (basicStatements.length <= maximumStatements) {
            let conditionalArguments = basicStatements.flatMap(basicStatement => basicStatement.getConditionalSyllogisms());
            let conditionsOfBasicStatements = conditionalArguments.flatMap(argument => argument.getConditions());
            basicStatements = basicStatements.concat(conditionsOfBasicStatements);
        }
        if (basicStatements.length > maximumStatements) {
            basicStatements = basicStatements.slice(0, maximumStatements);
        }
        return basicStatements;
    }
}