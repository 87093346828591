import { Card, Col, Container, Row, Image } from "react-bootstrap";
import { FooterFeed } from "../components/Common/Footer";
import { CallToAction, FeatureRow } from "./LandingPageSingularInformationSource";
import { LandingPageHeader } from "../components/Common/LandingPageHeader";
import { Benefits, LandingPageSubHeader } from "./LandingPage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase-config";
import { strings } from "../LucidMindShared/localization.web";
// @ts-ignore
import fallingCubesIcon from "../LucidMindShared/res/fallingCubesIcon.png";
// @ts-ignore
import collectionNetworkPersonalSyllogism from "../LucidMindShared/res/collectionNetworkPersonalSyllogism.png";
// @ts-ignore
import collectionFeedConflictsProfile from "../LucidMindShared/res/collectionFeedConflictsProfile.png";
// @ts-ignore
import collectionBrowserPluginArticles from "../LucidMindShared/res/collectionBrowserPluginArticles.png";
// @ts-ignore
import logicHandImage from "../LucidMindShared/res/logicHandImage.png";
// @ts-ignore
import statementNetwork from "../LucidMindShared/res/statementNetwork.png";
// @ts-ignore
import mockupCollectionDE from "../LucidMindShared/res/mockupCollectionDE.png";
// @ts-ignore
import collectionSyllogismNetworkCubes from "../LucidMindShared/res/collections/collectionSyllogismNetworkCubes.png";
// @ts-ignore
import collectionArticlesNetworkBrain from "../LucidMindShared/res/collectionArticlesNetworkBrain.png";
// @ts-ignore
import school from '../LucidMindShared/res/school.svg';
// @ts-ignore
import for_you from '../LucidMindShared/res/for_you.svg';
// @ts-ignore
import ink_pen from '../LucidMindShared/res/ink_pen.svg';

export function LandingPageGeneralPublic() {

    const approaches = [
        {
            header: strings.approaches.structureKnowledgeLogically.header,
            text: strings.approaches.structureKnowledgeLogically.text,
            icon: collectionNetworkPersonalSyllogism,
            iconScale: "75%",
            iconPaddingY: 3,
        },
        {
            header: strings.approaches.shareKnowledgeGainConsistency.header,
            text: strings.approaches.shareKnowledgeGainConsistency.text,
            comingSoon: true,
            icon: collectionFeedConflictsProfile,
            iconScale: "75%",
            iconPaddingY: 3,
        },
        {
            header: strings.approaches.extractKnowledge.header,
            text: strings.approaches.extractKnowledge.text,
            comingSoon: true,
            icon: collectionBrowserPluginArticles,
            iconScale: "75%",
            iconPaddingY: 3,
        },
    ]

    const handleSeeTestVersion = () => {
        logEvent(analytics, 'landing_page_register_click', { approach: "general" });
    }

    const handleTry = () => {
        logEvent(analytics, 'landing_page_try_click', { approach: "general" });
    }

    return (
        <>
            <LandingPageHeader showNavigation={true} handleLandingPageClick={handleSeeTestVersion} />
            <LandingPageSubHeader 
                header={
                    {
                        header: "",
                        headerBeforeEmphasized: strings.headers.general.header.understand,
                        headerEmphasized: [
                            strings.opinions,
                            strings.facts,
                            strings.sources,
                            strings.news,
                            strings.debates,
                            strings.ideas,
                            strings.arguments,
                            strings.topics,
                            strings.others,
                            strings.the_world,
                            strings.knowledge
                        ],
                        headerAfterEmphasized: strings.headers.general.header.through_logic,
                        text: strings.headers.general.text,
                        icon: logicHandImage,
                        iconPadding: 0,
                    }
                }
                centered
                handleSeeTestVersion={handleSeeTestVersion} 
                handleTry={handleTry}/>
            {/* <Benefits subHeader={randomSubHeader} benefits={randomBenefits}/> */}
            <Container id="features">
                {approaches.map((approach: any, index: any) =>
                    <FeatureRow
                        header={approach.header}
                        text={approach.text}
                        comingSoon={approach.comingSoon}
                        icon={approach.icon}
                        orientation={index % 2 === 0 ? "right" : "left"}
                        iconScale={approach.iconScale}
                        iconPaddingY={approach.iconPaddingY}
                    />
                )}
            </Container>
            <UseCases/>
            <CallToAction 
                callToAction={
                    {
                        header: strings.callsToAction.tryNow.header,
                        text: strings.callsToAction.tryNow.text,
                    }
                } handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry}/>
            <FooterFeed />
        </>
    )
};

const UseCases = () => {

    const audiences = [
        {
            header: strings.personas.everyone.header,
            text: strings.personas.everyone.text,
            icon: for_you
        },
        {
            header: strings.personas.students.header,
            text: strings.personas.students.text,
            icon: school
        },
        {
            header: strings.personas.journalists.header,
            text: strings.personas.journalists.text,
            icon: ink_pen
        }
    ]
    return(
        <Container id="solutions">
            <Row className="px-5 pt-5 pb-2 text-center">
                <h2 className="text-gradient">{strings.for_whom}</h2>
            </Row>
            <Row xs={1} sm={2} md={3} className="px-5 pt-3 pb-5">
                {audiences.map((audience) => 
                    <Col className="px-3 py-3 align-items-center">
                        <Card className="audience-card p-3 p-sm-4 p-lg-5 text-center justify-content-center align-items-center">
                            <Image width="30%" src={audience.icon}/>
                            <h3 className="text-gradient">
                                {audience.header}
                            </h3>
                            <p className="text-secondary">
                                {audience.text}
                            </p>
                        </Card>
                    </Col> 
                )}
            </Row>
        </Container>
    )
}