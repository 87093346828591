import Button from 'react-bootstrap/Button';
import { FirestoreConstants } from '../LucidMindShared/dataModel.web';
import { Col, Container, Row } from 'react-bootstrap';
import { StatementContainer } from './Common/Container';
import { useContext } from 'react';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';
import { AllTopicsBadge } from './Common/Badges';
import { InfoAlert } from './Common/InfoAlert';

export function StatementList(props: any) {

    const strings = useContext(LocalizedStringsContext)

    return (
        <div className='statement-list'>
            <Container className='p-0 pb-2 mb-2'>
                <AllTopicsBadge/>
            </Container>
            <Container className='px-0 pb-3'>
                <InfoAlert content={strings.feed_info} />
            </Container>
            <Row xs={1} sm={1} md={1} lg={2} xl={2} xxl={3} className='g-2'>
                {props.data.map((childId: string) =>
                    <Col>
                        <StatementContainer key={childId} id={childId} parentId={FirestoreConstants.ROOT_ID} />
                    </Col>
                )}
            </Row>
            <Row className='p-3'>
                <Button onClick={props.onEndReached} >
                    {strings.load_more}
                </Button>
            </Row>
        </div>
    )
}