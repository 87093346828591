import { ContainerType, useContainerListener, uuid } from "../../LucidMindShared/dataModel.web";
import { Statement } from "../../model";
import { StatementBox } from "./StatementBox";

export type containerProps = {
    id: uuid,
    parentId: uuid,
}
export function StatementContainer({ id, parentId }: containerProps): JSX.Element {
    const { container, loading, error } = useContainerListener(id);

    if (loading) {
    } else if (error) {
    }

    const props = {
        id: id,
        parentId: parentId,
    }
    if (container.getType() === ContainerType.STATEMENT) {
        return <StatementBox deleteEnabled={false} editEnabled={false} publishEnabled={false} displayTopics={true} displayHeader={true} displayUser={true} displayCreationTime={true} {...props} statementId={id} rootId={id} statementObject={new Statement("Loading", false)} />;
    } else if (container.getType() === ContainerType.STATEMENT_GROUP) {
        return <></>; // <StatementGroupBox {...props} />;
    } else if (container.getType() === ContainerType.SOURCE) {
        return <></>; // <SourceBox {...props} />;
    } else if (container.getType() === ContainerType.CATEGORY) {
        return <></>; // <CategoryBox {...props} />;
    } else {
        return <></>;
    }
}