import {onSnapshot, DocumentReference, DocumentData, CollectionReference, Timestamp, updateDoc, arrayRemove, getDoc, writeBatch, getFirestore} from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import {
  // useContext,
  useEffect,
  useState} from 'react';
// import { LocalizedStringsContext } from "./localization";
import {convertToPublicPath, getRefFromDB, getUserId, pathIsPublic, saveContainerToDB} from './helperFunctions.web';

export type DocumentRef = DocumentReference<DocumentData>;
export type CollectionRef = CollectionReference<DocumentData>;

export type uuid = string;
export type userId = string;
export type dbPath = string;

export enum PathConstants {
  PERSONAL = '0',
  PUBLIC = '1',
}

export enum FirestoreConstants {
  USER_DATA = 'userData',
  PERSONAL_ELEMENTS = 'personalElements',
  PERSONAL_ROOT_ELEMENTS = 'rootElements',
  ROOT_ID = 'rootId',
  ROOT_PATH = PathConstants.PERSONAL + '_' + ROOT_ID,
  PUBLIC_DATA = 'publicData',
  FEED = 'testFeed',
  FEED_ELEMENTS = 'feedElements',
  FEED_ROOT_ELEMENTS = 'rootElements',
  PERSONAL_STATEMENTS = 'personalStatements', // For older version of backend, not applicable anymore
}

export enum ContainerType {
  NONE = '0',
  STATEMENT = '1',
  SOURCE = '2',
  STATEMENT_GROUP = '3',
  STATEMENT_GROUP_ALTERNATIVE = 'StatementGroup', // For older version of backend, not applicable anymore
  CATEGORY = '4',
}

export enum StanceType {
  NEUTRAL = 0,
  SUPPORT = 1,
  OPPOSE = 2,
}

export enum VoteType {
  UPVOTE = 1,
  DOWNVOTE = -1,
  NOVOTE = 0,
}

export interface _ContainerData {
  type: ContainerType;
  // TODO: Could be removed by using the documents key as id, which is essentially the same.
  path: dbPath;

  // Not necessarily needed for personal elements.
  userId: userId;
  parentPaths: dbPath[];
  childPaths: {[key: dbPath]: StanceType}[];
  votes: {[key: userId]: VoteType};
  creationTime: Timestamp | null;
}

// not sure if the overriding of the data variable makes updates impossible when using functions of the super class.
// If polymorphism is not applied, it is possible that only the variable of the super class is updated and updates are not
// reflected in getters. However, this is probably not relevant if setters directly interact with firestore since this will
// cause a new constructor invocation completely omitting the old state and build the new state from the ground up.
export class ContainerData {
  protected readonly data: _ContainerData;

  // Used to directly retrieve parent specific properties.
  // Also used in the creation process to determine where to add the container in the graph.
  // This is only a temporary local variable and is not stored in the database.
  protected postponeUpdate = false;

  public constructor(data: _ContainerData) {
    this.data = data;
  }

  public static makeInDB(data: _ContainerData, stance: StanceType) {
    saveContainerToDB(new ContainerData(data), stance);
  }

  public static makeFromListener(data: _ContainerData): ContainerData {
    return new ContainerData(data);
  }

  // TODO: Also for child classes?
  public static async getFromDB(path: dbPath): Promise<ContainerData> {
    const doc = await getDoc(getRefFromDB(path));
    if (!doc.exists()) {
      throw new Error('Document does not exist: ' + path);
    }
    return new ContainerData(doc.data() as _ContainerData);
  }

  public getType(): ContainerType {
    return this.data.type;
  }

  public getTypeAsLocalizedString(): string {
    // const localizedStrings = useContext(LocalizedStringsContext);

    // let result = localizedStrings.none;
    // Object.entries(ContainerType).forEach(([key, value]) => {
    //     if (value === this.data.type) {
    //         result = localizedStrings[key.toLowerCase()];
    //     }
    // });
    let testResult = 'TestTypeAsLocalizedString';
    let result = testResult;
    return result;
  }

  public getData(): _ContainerData {
    return this.data;
  }

  public getPath(): dbPath {
    return this.data.path;
  }

  public isPublic(): boolean {
    return pathIsPublic(this.getPath());
  }

  public isPersonal(): boolean {
    return !this.isPublic();
  }

  public isOwnedByCurrentUser(): boolean {
    return this.getUserId() === getUserId();
  }

  public getUserId(): userId {
    return this.data.userId;
  }

  public setPath(path: dbPath): void {
    this.data.path = path;
    this.applyUpdate();
  }

  public getChildData(): {[key: dbPath]: StanceType}[] {
    return this.data.childPaths;
  }

  public setChildData(childData: {[key: dbPath]: StanceType}[]): void {
    this.data.childPaths = childData;
    this.applyUpdate();
  }

  public getChildPaths(): dbPath[] {
    return this.data.childPaths.map(entry => Object.keys(entry)[0]);
  }

  public setChildPaths(childPaths: {[key: uuid]: StanceType}[]): ContainerData {
    this.data.childPaths = childPaths;
    this.applyUpdate();
    return this;
  }

  public getChildStances(): StanceType[] {
    return this.data.childPaths.map(entry => Object.values(entry)[0]);
  }

  public getChildStance(childPath: dbPath): StanceType {
    const index = this.getChildPaths().indexOf(childPath);
    if (index === -1) {
      throw new Error('Child id not found.');
    }
    return this.getChildStances()[index];
  }

  public setChildStance(childPath: dbPath, stance: StanceType) {
    const index = this.getChildPaths().indexOf(childPath);
    if (index === -1) {
      throw new Error('Child id not found.');
    }
    this.data.childPaths[index] = {[childPath]: stance};

    this.applyUpdate();
  }

  public getParentPaths(): dbPath[] {
    return this.data.parentPaths;
  }

  public setParentPaths(parentPaths: dbPath[]): void {
    this.data.parentPaths = parentPaths;
    this.applyUpdate();
  }

  public getVotes(): {[key: userId]: VoteType} {
    return this.data.votes;
  }

  public getUpvotes(): number {
    return Object.values(this.getVotes()).filter(
      vote => vote === VoteType.UPVOTE,
    ).length;
  }

  public getOwnVote(): VoteType {
    // TODO: Delete later because this is needed for legacy data.
    if (!this.data.votes) {
      this.data.votes = {};
      this.applyUpdate();
    }
    if (getAuth().currentUser?.uid) {
      const userId = getAuth().currentUser!.uid;
      if (this.data.votes[userId]) {
        return this.data.votes[userId];
      }
    }
    return VoteType.NOVOTE;
  }

  public setOwnVote(vote: VoteType) {
    console.log('setOwnVote');
    if (getAuth().currentUser?.uid) {
      const userId = getAuth().currentUser!.uid;
      if (vote !== VoteType.NOVOTE) {
        this.data.votes[userId] = vote;
      } else {
        delete this.data.votes[userId];
      }
      this.applyUpdate();
    }
  }

  public getCreationTime(): Timestamp | null {
    return this.data.creationTime;
  }

  public setCreationTime(creationTime: Timestamp) {
    this.data.creationTime = creationTime;
    this.applyUpdate();
  }

  public setCreationTimeNow() {
    this.setCreationTime(Timestamp.now());
  }

  public keepUpdatesLocal(): ContainerData {
    this.postponeUpdate = true;
    return this;
  }

  public pushLocalUpdates() {
    this.postponeUpdate = false;
    this.applyUpdate();
  }

  protected applyUpdate() {
    if (!this.postponeUpdate) {
      this.updateData(this.data);
    }
  }

  public convertToPublicData(): ContainerData {
    this.keepUpdatesLocal();
    this.setCreationTimeNow();
    this.setPath(convertToPublicPath(this.getPath()) ?? '');
    this.setChildPaths(
      this.data.childPaths.map(entry => {
        return {
          [convertToPublicPath(Object.keys(entry)[0])!]:
            Object.values(entry)[0],
        };
      }),
    );
    const parentPaths = this.data.parentPaths
      .map(path => convertToPublicPath(path))
      .filter(path => path !== null) as dbPath[];
    this.data.parentPaths = parentPaths;
    return this;
  }

  /**
   * This function is used to minimize database update calls when multiple changes must be applied to the data.
   *
   * @param functionsToBeApplied Inside this function, multiple functions changing the data can be applied. The update will only be applied once after all functions have been executed.
   */
  public applyAll(functionsToBeApplied: (container: ContainerData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }

  /**
   * !Careful when using this function as it does not check if the data is valid!
   * Overrides the data. Used internally by other methods.
   *
   * @param data Data to use.
   */
  public async updateData(data: {[x: string]: any;}) {
    try {
      await updateDoc(getRefFromDB(this.getPath()), data);
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  }

  public async removeFromParent(parentPath: dbPath) {
    // TODO: Use Batch delete. Aggregate all changes and then apply them at once.
    if (
      (this.getParentPaths().length === 1 &&
        this.getParentPaths()[0] === parentPath) ||
      this.getParentPaths().length === 0
    ) {
      // TODO: Uncomment as soon as delete is implemented.
      this.delete();
    } else {
      // Remove path from parent's childPaths
      try {
        await updateDoc(
          getRefFromDB(parentPath),
          {childPaths: arrayRemove(this.getPath())}
        );
      } catch (error) {
        console.error("Error updating active parent's childPaths: ", error);
      }

      // Remove parent path from this container's parentPaths.
      try {
        await updateDoc(
          getRefFromDB(this.getPath()),
          {parentPaths: arrayRemove(parentPath)}
        );
      } catch (error) {
        console.error('Error updating containers parentPaths: ', error);
      }
    }
  }

  /* TODO: Implement this function.
    // Think about implementation. Can I use the listener here? Or do I need to use the db directly? Can I await the container?
    // Does this block the main thread? If so, how can I avoid this?
    /**
     * Use with caution. This function deletes the container entirely from the db.
     * If child container are only used by this container, they will be deleted as well.
     */
  public delete() {
    setTimeout(async () => {
      const batch =  writeBatch(getFirestore());

      const parentDoc = await getDoc(getRefFromDB(this.getParentPaths()[0]));
      if (parentDoc.exists()) {
        const parentContainer = new ContainerData(
          parentDoc.data() as _ContainerData,
        );
        parentContainer.keepUpdatesLocal();
        parentContainer.setChildPaths(
          parentContainer
            .getChildData()
            .filter(
              pathAndStance => Object.keys(pathAndStance)[0] !== this.getPath(),
            ),
        );
        const parentContainerData: {[x: string]: any} = parentContainer.getData();
        batch.update(
          getRefFromDB(this.getParentPaths()[0]),
          parentContainerData,
        );
      }
      batch.delete(getRefFromDB(this.getPath()));

      await Promise.all(
        this.getChildPaths().map(async childPath => {
          const childDoc = await getDoc(getRefFromDB(childPath));
          if (childDoc.exists()) {
            const childContainer = new ContainerData(
              childDoc.data() as _ContainerData,
            );
            childContainer.keepUpdatesLocal();
            childContainer.setParentPaths(
              childContainer
                .getParentPaths()
                .filter(path => path !== this.getPath()),
            );
            const childContainerData: {[x: string]: any} =  childContainer.getData();
            batch.update(getRefFromDB(childPath), childContainerData);
          }
        }),
      );

      batch.commit();
    }, 0);
  }
}

export function useContainerListener(path: dbPath): {
  container: ContainerData;
  loading: boolean;
  error: Error | null;
} {
  const [container, setContainerData] = useState(
    new ContainerData({
      type: ContainerType.NONE,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      //TODO: The error handling on all listeners in not really solved in a useful way yet.
      if (path === undefined || path === null || path === '') {
        setError(new Error('Cannot listen to container with an empty path.'));
      }
      const containerRef = getRefFromDB(path);

      if (containerRef === undefined || containerRef === null) {
        setError(new Error('Document does not exist.'));
        return;
      }

      const unsubscribe = onSnapshot(containerRef, snapshot => {
        if (snapshot && snapshot.exists()) {
          // Not sure if I can really reuse this factory function for all container types as there might be problems with the type of the data object.
          const typeId = snapshot.data()?.type || ContainerType.NONE;
          if (typeId === ContainerType.STATEMENT) {
            setContainerData(
              new StatementData(snapshot.data() as _StatementData),
            );
          } else if (typeId === ContainerType.STATEMENT_GROUP) {
            setContainerData(
              new StatementGroupData(snapshot.data() as _StatementGroupData),
            );
          } else if (typeId === ContainerType.SOURCE) {
            setContainerData(new SourceData(snapshot.data() as _SourceData));
          } else if (typeId === ContainerType.CATEGORY) {
            setContainerData(
              new CategoryData(snapshot.data() as _CategoryData),
            );
          } else {
            setContainerData(
              ContainerData.makeFromListener(snapshot.data() as _ContainerData),
            );
          }
        } else {
          setError(
            new Error('Did not find Element with path ' + path + ' in DB.'),
          );
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [path]);

  return {container: container, loading: loading, error: error};
}

export interface _StatementData extends _ContainerData {
  content: string;
}

export class StatementData extends ContainerData {
  protected readonly data: _StatementData;

  public constructor(data: _StatementData) {
    super(data);
    this.data = data;
  }

  public makeInDB(data: _StatementData, stance: StanceType) {
    saveContainerToDB(new StatementData(data), stance);
  }

  public static makeFromListener(data: _StatementData): StatementData {
    return new StatementData(data);
  }

  public getContent(): string {
    return this.data.content;
  }

  public setContent(content: string): StatementData {
    this.data.content = content;
    this.applyUpdate();
    return this;
  }

  public applyAll(functionsToBeApplied: (statement: StatementData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }
}

export function useStatementListener(path: dbPath): {
  statement: StatementData;
  loading: boolean;
  error: Error | null;
} {
  const [statement, setStatement] = useState(
    new StatementData({
      type: ContainerType.STATEMENT,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      content: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (!loading && statement.getType() !== ContainerType.STATEMENT) {
        throw new Error('ContainerData is not a Statement!');
      }

      if (path === undefined || path === null) {
        throw new Error('Cannot listen to container without any id.');
      }

      const statementRef = getRefFromDB(path);

      if (statementRef === undefined || statementRef === null) {
        setError(new Error('Document does not exist.'));
        return;
      }

      const unsubscribe = onSnapshot(statementRef, snapshot => {
        if (snapshot && snapshot.exists()) {
          // Not sure if I can really reuse this factory function for all container types as there might be problems with the type of the data object.
          setStatement(
            StatementData.makeFromListener(snapshot.data() as _StatementData),
          );
        } else {
          setError(new Error('Snapshot does not exist or is empty.'));
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [path]);

  return {statement: statement, loading: loading, error: error};
}

export interface _SourceData extends _StatementData {
  url: string;
  reference: string;
}

export class SourceData extends StatementData {
  protected readonly data: _SourceData;

  public constructor(data: _SourceData) {
    super(data);
    this.data = data;
  }

  public makeInDB(data: _SourceData, stance: StanceType) {
    saveContainerToDB(new SourceData(data), stance);
  }

  public static makeFromListener(data: _SourceData): SourceData {
    return new SourceData(data);
  }

  public getUrl(): string {
    return this.data.url;
  }

  public setUrl(url: string): SourceData {
    this.data.url = url;
    this.applyUpdate();
    return this;
  }

  public getReference(): string {
    return this.data.reference;
  }

  public setReference(reference: string): SourceData {
    this.data.reference = reference;
    this.applyUpdate();
    return this;
  }

  public applyAll(functionsToBeApplied: (source: SourceData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }
}

export function useSourceListener(path: dbPath): {
  source: SourceData;
  loading: boolean;
  error: Error | null;
} {
  const [source, setSource] = useState(
    new SourceData({
      type: ContainerType.SOURCE,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      content: '',
      url: '',
      reference: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (!loading && source.getType() !== ContainerType.SOURCE) {
        throw new Error('ContainerData is not a Source!');
      }

      if (path === undefined || path === null) {
        throw new Error('Cannot listen to container without any path.');
      }

      const sourceRef = getRefFromDB(path);

      if (sourceRef === undefined || sourceRef === null) {
        setError(new Error('Document does not exist.'));
        return;
      }

      const unsubscribe = onSnapshot(sourceRef, snapshot => {
        if (snapshot && snapshot.exists()) {
          // Not sure if I can really reuse this factory function for all container types as there might be problems with the type of the data object.
          setSource(
            SourceData.makeFromListener(snapshot.data() as _SourceData),
          );
        } else {
          setError(new Error('Snapshot does not exist or is empty.'));
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [path]);

  return {source: source, loading: loading, error: error};
}

export type _StatementGroupData = _ContainerData;

export class StatementGroupData extends ContainerData {
  protected readonly data: _StatementGroupData;

  public constructor(data: _StatementGroupData) {
    super(data);
    this.data = data;
  }

  public makeInDB(data: _StatementGroupData, stance: StanceType) {
    saveContainerToDB(new StatementGroupData(data), stance);
  }

  public static makeFromListener(
    data: _StatementGroupData,
  ): StatementGroupData {
    return new StatementGroupData(data);
  }
}

export function useStatementGroupListener(path: dbPath): {
  statementGroup: StatementGroupData;
  loading: boolean;
  error: Error | null;
} {
  const [statementGroup, setStatementGroup] = useState(
    new StatementGroupData({
      type: ContainerType.STATEMENT_GROUP,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      if (
        !loading &&
        statementGroup.getType() !== ContainerType.STATEMENT_GROUP
      ) {
        throw new Error('ContainerData is not a Statement Group!');
      }

      if (path === undefined || path === null) {
        throw new Error('Cannot listen to container without any id.');
      }

      const statementGroupRef = getRefFromDB(path);

      if (statementGroupRef === undefined || statementGroupRef === null) {
        setError(new Error('Document does not exist.'));
        return;
      }

      const unsubscribe = onSnapshot(statementGroupRef, snapshot => {
        if (snapshot && snapshot.exists()) {
          // Not sure if I can really reuse this factory function for all container types as there might be problems with the type of the data object.
          setStatementGroup(
            StatementGroupData.makeFromListener(
              snapshot.data() as _StatementGroupData,
            ),
          );
        } else {
          setError(new Error('Snapshot does not exist or is empty.'));
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [path]);

  return {statementGroup: statementGroup, loading: loading, error: error};
}

export interface _CategoryData extends _ContainerData {
  name: string;
}

export class CategoryData extends ContainerData {
  protected readonly data: _CategoryData;

  public constructor(data: _CategoryData) {
    super(data);
    this.data = data;
  }

  public makeInDB(data: _CategoryData, stance: StanceType) {
    saveContainerToDB(new CategoryData(data), stance);
  }

  public static makeFromListener(data: _CategoryData): CategoryData {
    return new CategoryData(data);
  }

  public getName(): string {
    return this.data.name;
  }

  public setName(name: string): CategoryData {
    this.data.name = name;
    this.applyUpdate();
    return this;
  }

  public applyAll(functionsToBeApplied: (category: CategoryData) => void) {
    this.postponeUpdate = true;
    functionsToBeApplied(this);
    this.postponeUpdate = false;
    this.applyUpdate();
  }
}

export function useCategoryListener(path: dbPath): {
  category: CategoryData;
  loading: boolean;
  error: Error | null;
} {
  const [category, setCategory] = useState(
    new CategoryData({
      type: ContainerType.CATEGORY,
      parentPaths: [],
      childPaths: [],
      path: '',
      userId: '',
      name: '',
      votes: {},
      creationTime: null,
    }),
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  if (!loading && category.getType() !== ContainerType.CATEGORY) {
    throw new Error('ContainerData is not a Category!');
  }

  if (path === undefined || path === null) {
    throw new Error('Cannot listen to container without any path.');
  }

  useEffect(() => {
    try {
      if (!loading && category.getType() !== ContainerType.CATEGORY) {
        throw new Error('ContainerData is not a Category!');
      }

      if (path === undefined || path === null) {
        throw new Error('Cannot listen to container without any id.');
      }

      const categoryRef = getRefFromDB(path);

      if (categoryRef === undefined || categoryRef === null) {
        setError(new Error('Document does not exist.'));
        return;
      }

      const unsubscribe = onSnapshot(categoryRef, snapshot => {
        if (snapshot && snapshot.exists()) {
          // Not sure if I can really reuse this factory function for all container types as there might be problems with the type of the data object.
          setCategory(
            CategoryData.makeFromListener(snapshot.data() as _CategoryData),
          );
        } else {
          setError(new Error('Snapshot does not exist or is empty.'));
        }
        setLoading(false);
      });

      return () => {
        unsubscribe();
      };
    } catch (error: any) {
      console.error(error);
      setError(error);
    }
  }, [path]);

  return {category: category, loading: loading, error: error};
}
