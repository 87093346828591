import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCjSXrrNnbzWtvsfgboXEks0f7fzLTsGp0",
    authDomain: "test-d99d9.firebaseapp.com",
    projectId: "test-d99d9",
    storageBucket: "test-d99d9.appspot.com",
    messagingSenderId: "8815986443",
    appId: "1:8815986443:web:6b7fa5cd46791845ba040e",
    measurementId: "G-RDEHDFL3LZ"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);