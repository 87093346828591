import Container from 'react-bootstrap/esm/Container';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import { useContext } from 'react';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';
import { TestVersionButton, TryButton } from '../components/Common/Buttons';
// @ts-ignore
import collectionSourceToArguments from "../LucidMindShared/res/collectionSourceToArguments.png";
// @ts-ignore
import extractStatementsPlugin from "../LucidMindShared/res/extractStatementsPlugin.png";
// @ts-ignore
import orderThoughts from "../LucidMindShared/res/orderThoughts.png";
// @ts-ignore
import generateManuscript from "../LucidMindShared/res/generateManuscript.png";
import { CallToAction, FeatureRow } from './LandingPageSingularInformationSource';
import { FooterFeed } from '../components/Common/Footer';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase-config';
import { LandingPageHeader } from '../components/Common/LandingPageHeader';
import { BenefitCol } from './BenefitCol';

export function FeaturePageBrowserPlugin() {

    const browserPluginFeature = "browser-plugin";
    const approach = browserPluginFeature;

    const strings = useContext(LocalizedStringsContext);
    const allHeaders = [
        {
            header: "Verkürze Deine Recherchezeit",
            text: "Extrahiere Aussagen von Quellen, ordne Aussagen zu Argumenten und generiere ein Manuskript in Windeseile.",
            icon: collectionSourceToArguments,
            iconPadding: 0,
            approaches: [browserPluginFeature]
        },
    ];
    const allFeatures = [
        {
            name: "statementExtractionPlugin",
            content: [
                {
                    header: "Schritt 1: Von Quelle zu Aussage in Sekundenschnelle",
                    text: "Extrahiere Aussagen aus Quellen im Browser und füge sie deinem Projekt hinzu.",
                    icon: extractStatementsPlugin,
                    iconScale: "100%",
                    approaches: [browserPluginFeature]
                }
            ]
        },
        {
            name: "structureThoughtsInSyllogisms",
            content: [
                {
                    header: "Schritt 2: Ordne deine Gedanken in Argumente",
                    text: "Strukturiere in Quellen gefundene Aussagen in Syllogismen, um die Übersicht zu behalten und trennscharf zu argumentieren.",
                    icon: orderThoughts,
                    iconScale: "100%",
                    approaches: [browserPluginFeature]
                },
            ]
        },
        {
            name: "generateDraftManuscript",
            content: [
                {
                    header: "Schritt 3: Manuskript-Entwurf im Handumdrehen",
                    text: "Generiere einen Entwurf deines Manuskripts anhand deiner Argumente.",
                    icon: generateManuscript,
                    iconScale: "100%",
                    approaches: [browserPluginFeature]
                },
            ]
        },
    ];
    const allCallsToAction = [
        {
            header: strings.call_to_action_get_test_access_header,
            text: "",
            approaches: [browserPluginFeature]
        },
    ];

    const getRandomObjects = (array: Array<Object>, numberOfRandomObjects: number): any => {
        if (numberOfRandomObjects === 1) {
            const randomIndex = Math.floor(Math.random() * array.length);
            return array[randomIndex];
        } else {
            // Return the first objects
            return array.slice(0, numberOfRandomObjects);
        }
    }

    const randomHeaderAssignment = () => {
        const allHeadersForApproach = allHeaders.filter(header => header.approaches.includes(approach));
        return getRandomObjects(allHeadersForApproach, 1);
    }

    const randomFeatureAssignment = () => {
        const featuresForApproach = allFeatures.filter(feature =>
            feature.content.filter((feature: any) =>
                feature.approaches.includes(approach)
            ).length > 0
        );
        featuresForApproach.forEach((feature: any) =>
            feature.content = feature.content.filter((feature: any) =>
                feature.approaches.includes(approach)
            )
        );
        const randomFeaturesForApproachAllContent = getRandomObjects(featuresForApproach, 3);
        console.log(randomFeaturesForApproachAllContent)
        const randomFeaturesForApproachRandomContent = randomFeaturesForApproachAllContent.map((feature: any) => getRandomObjects(feature.content, 1));
        console.log(randomFeaturesForApproachRandomContent);
        const randomNamedFeaturesForApproachRandomContent = randomFeaturesForApproachAllContent.map((feature: any, index: any) =>
            {
                return {
                    name: feature.name,
                    header: randomFeaturesForApproachRandomContent[index].header,
                    text: randomFeaturesForApproachRandomContent[index].text,
                    icon: randomFeaturesForApproachRandomContent[index].icon,
                    iconScale: randomFeaturesForApproachRandomContent[index].iconScale,
                    approaches: randomFeaturesForApproachRandomContent[index].approaches
                }
                
            }
        );
        console.log(randomNamedFeaturesForApproachRandomContent);
        return randomNamedFeaturesForApproachRandomContent;
    }

    const randomCallToActionAssignment = () => {
        const allCallsToActionForApproach = allCallsToAction.filter(callToAction => callToAction.approaches.includes(approach));
        return getRandomObjects(allCallsToActionForApproach, 1);
    }

    const randomHeader = randomHeaderAssignment();
    const randomCallToAction = randomCallToActionAssignment();
    const randomFeatures = randomFeatureAssignment();

    const handleSeeTestVersion = () => {
        logEvent(analytics, 'landing_page_register_click', { approach: approach });
    }

    const handleTry = () => {
        logEvent(analytics, 'landing_page_try_click', { approach: approach });
    }

    return (
        <>
            <LandingPageHeader handleLandingPageClick={handleSeeTestVersion} />
            <LandingPageSubHeader header={randomHeader} handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry}/>
            <Container>
                {randomFeatures.map((feature: any, index: any) =>
                    <FeatureRow
                        header={feature.header}
                        text={feature.text}
                        icon={feature.icon}
                        orientation={index % 2 === 0 ? "right" : "left"}
                        iconScale={feature.iconScale}
                    />
                )}
            </Container>
            <CallToAction callToAction={randomCallToAction} handleSeeTestVersion={handleSeeTestVersion} handleTry={handleTry}/>
            <FooterFeed />
        </>
    )
};

interface LandingPageSubHeaderProps { header: any, handleSeeTestVersion: Function, handleTry: Function }
export function LandingPageSubHeader({ header, handleSeeTestVersion, handleTry }: LandingPageSubHeaderProps) {

    return (
        <Container fluid className='px-0'>
            <Row xs={1} sm={2} md={2} className='mx-auto my-auto px-5 py-3 pb-5 landing-page-header-knowledge-base'>
                <Col className={`p-1 p-md-${header.iconPadding} d-block d-sm-none d-md-none`}>
                    <Image fluid src={header.icon} />
                </Col>
                <Col className='my-auto p-0 p-md-5'>
                    <h1>{header.header}</h1>
                    <p>{header.text}</p>
                    <Stack direction='horizontal' gap={1}>
                        <TestVersionButton handleLandingPageClick={handleSeeTestVersion} />
                        <TryButton handleLandingPageClick={handleTry} />
                    </Stack>
                </Col>
                <Col className={`p-1 p-md-${header.iconPadding} d-none d-sm-block d-md-block`}>
                    <Image fluid src={header.icon} />
                </Col>
            </Row>
        </Container>
    )
}

interface LandingPageBenefitsProps { subHeader: any, benefits: any }
export function Benefits({ subHeader, benefits }: LandingPageBenefitsProps) {

    return (
        <Container fluid className='pt-1 pb-5 px-0 px-md-5 landing-page-benefits'>
            <Row className='pb-0 pt-5 mx-5'>
                <Col>
                    <h2>{subHeader.header}</h2>
                    {/* <p>{subHeader.text}</p> */}
                </Col>
                <Col>
                    
                </Col>
            </Row>
            <Row xs={1} sm={3} md={3} className='my-auto mx-5 introduction-group'>
                {benefits.map((benefit: any) =>
                    <BenefitCol
                        header={benefit.header}
                        text={benefit.text}
                        icon={benefit.icon} />
                )}
            </Row>
        </Container>
    )
}