import Container from 'react-bootstrap/esm/Container';
import { Header } from '../components/Common/Header';
import { useUserState } from '../LucidMindShared/helperFunctions.web';
import { Button, Col, Row, Stack, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NavigationBar } from '../components/Common/NavigationBar';
import { useContext } from 'react';
import { LocalizedStringsContext } from '../LucidMindShared/localization.web';
import { InfoAlert } from '../components/Common/InfoAlert';
import { getAuth, signOut } from 'firebase/auth';
import { NavigationBarBottom } from '../components/Common/NavigationBarBottom';
import { FooterFeed } from '../components/Common/Footer';

export function ProfileView(props: any) {

    const user = useUserState()
    const strings = useContext(LocalizedStringsContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        const authentication = getAuth();
        await signOut(authentication);
        navigate("/login");
    }

    return (
        <div>
            <Header topics={props.topics} />

            <Row>
                <Col xs={0} sm={4} xl={2} className="navigation-sidebar d-none d-sm-block d-md-block d-lg-block d-xl-block">
                    <NavigationBar/>
                </Col>
                <Col xs={12} sm={8} xl={8} className="full-height">
                    <Container className='m-1'>
                        <Row className='m-1 p-4'>
                            <Stack direction='horizontal' gap={3}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                                </svg>
                                <div>
                                    <h1>
                                        {user?.displayName}
                                    </h1>
                                    <h6>
                                        {user?.email}
                                    </h6>
                                </div>
                                <Button className='btn-icon ms-auto'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                                        <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z"/>
                                    </svg>
                                </Button>
                            </Stack>
                        </Row>
                        <Row className='mx-1 p-4'>
                            <Col>
                                <h5>
                                    0 {strings.followed}
                                </h5>
                            </Col>
                            <Col>
                                <h5>
                                    0 {strings.following}
                                </h5>
                            </Col>
                        </Row>
                        <Row className='mx-1 p-4'>
                            <Tabs
                                defaultActiveKey="published-statements"
                                id="profile-tab"
                                className="mb-3 profile-tab"
                                fill
                                justify
                                >
                                <Tab eventKey="published-statements" title={strings.published_statements} className="profile-tab">
                                    <InfoAlert content={strings.published_statements_info}/>
                                </Tab>
                                <Tab eventKey="bookmarks" title={strings.bookmarks} className="profile-tab">
                                    <InfoAlert content={strings.bookmarks_info}/>
                                </Tab>
                            </Tabs>
                        </Row>
                        <Row className='mx-1 p-4'>
                            <Button onClick={handleLogout}>
                                {strings.logout}
                            </Button>
                        </Row>
                    </Container>
                </Col>
            </Row>

                {/* <TopicList
                    topics={props.userTopics}
                    handleViewTopic={props.handleViewTopic}
                    handleCreateTopic={props.handleCreateTopic}
                /> */}

            <FooterFeed/>

            <NavigationBarBottom/>
            
        </div>
    )
};