export const en = {
  // Navigation
  public: 'Public',
  private: 'Personal',
  profile: 'Profile',
  settings: 'Settings',
  // container types
  opinion: 'Opinion',
  fact: 'Fact',
  statement: 'Statement',
  source: 'Source',
  argument: 'Argument',
  statement_group: 'Statement Group',
  category: 'Topic',
  none: 'None',

  // Introduction
  introduction_statements: "With lucidmind, you can collect and share opinions and information in a simple and logical way.\nThe basic element is the Statement. Statements are sentences that are either true or false. You can structure Statements into Topics to keep them organized.",
  introduction_arguments: "You can prove or refute Statements with Arguments. Arguments consist of one or more Conditions. Conditions are also Statements. You can also provide Sources for Statements.",
  introduction_private_public: "lucidmind has a personal and a public area.\nIn the personal area, you can structure and add your own opinions as Statements and Arguments.\nIn the public area, you can share your own Statements and find Statements from others. In the future, Statements and Arguments from the public area will also be automatically suggested to you in order to question your opinion.",
  introduction_interaction: "You can interact with Statements and later Arguments.\nA thumbs up means that you believe that this Statement is true. A thumbs down means that you believe that this Statement is false.",
  introduction_checks_conflicts: "Statements and later Arguments are subjected to certain controls that should help you to build up your Argument as coherently as possible. If there are Conflicts in your argumentation, you will be shown suggested solutions.",
  introduction_test_version: "This is a test version. Some functions are not yet available, but have already been teased via various buttons and information boards.",
  next: "Next",
  previous: "Previous",
  continue: "Continue",

  // Container forms
  add_topic: "New Topic",
  add_topic_name_hint: "Enter the Topic name...",
  add_statement: "New Statement",
  add_statement_content_hint: "Enter a Statement...",
  add_argument: "New Argument",
  add_source: "New Source",
  add_source_content_hint: "Enter a quote...",
  add_source_url_hint: "Enter URL... (Begins with \"https://\")",
  add_source_reference_hint: "Enter reference if applicable... (i.e. \"p.5, l.34\")",
  add_condition: "New Condition", // Vote: 'Premise', 'Condition'
  add_condition_content_hint: "Enter a Condition...",

  search: "Search...",
  search_teaser: "You will be able to search for Topics and Statements soon.",
  save: 'Save',
  create: 'Create',
  cancel: 'Cancel',
  yes: 'Yes',
  delete: 'Delete',
  delete_question: 'Do you really want to delete this element?',
  add: 'Add',
  edit: 'Edit',
  move: 'Move',
  copy: 'Copy',
  paste: 'Paste',
  share: 'Share',
  report: 'Report',
  copy_link: 'Copy Link',
  copied_to_clipboard: 'Copied to clipboard',
  link: 'External link',
  link_question: 'Are you certain you want to open the following link?',
  refresh: 'Refresh',
  load_more: 'Load more',
  publish: 'Publish',
  published: 'Published',
  publish_statement: 'Publish Statement',
  published_statements: 'Published Statements',
  published_statements_info: 'Here you can soon see your published Statements.',
  bookmark: 'Bookmark',
  added_to_bookmarks: 'Bookmark added',
  removed_from_bookmarks: 'Bookmark removed',
  bookmarks: 'Bookmarks',
  bookmarks_info: 'Here you can soon see your bookmarked Statements.',
  bookmark_tooltip: 'Here you can soon add Statements to your bookmarks.',
  following: 'following',
  followed: 'followed',
  by: 'by',
  about: 'about',
  unknown_user: 'unknown user',
  by_unknown_user: 'unknown user',
  found_reasoning: 'Found reasoning',
  no_reasoning_found: 'No reasoning found',
  loading: 'Loading...',
  content_placeholder: 'Enter content here',
  category_placeholder: 'Enter name of Topic here',
  url_placeholder: 'Enter URL of Source here',
  reference_placeholder: 'Enter reference of Source here',
  support: 'in favor of',
  oppose: 'against',
  error: 'Error',
  not_yet_implemented:
    'We are still working on this feature, sorry for the inconvenience!',
  interested_question:
    'Feel free to let us know if this feature is of interest to you.',
  interested: 'Interested',
  not_interested: 'Not interested',
  personal_categories_header: 'Your Topics',
  personal_statements_header: 'Your Statements',
  feed_header: 'News',
  no_topics_info: 'No Topics. Click "+" to add Topic.',
  no_arguments_info: 'No Arguments. Click "+" to add Argument.',
  no_statements_info: 'No Statements. Click "+" to add Statement.',
  propose_arguments: 'Propose Argument',
  propose_arguments_tooltip: 'Here you can soon receive proposals for Arguments about this Statement. The Arguments are taken from public Statements or generated automatically.',
  tree_view_info: 'View a Topic as a tree to get a better overview. Feature coming soon.',
  feed_info: 'The public feed is so far only implemented to show the basic functionality. More features are coming soon.',
  without_topic: 'Without Topic',
  all_topics: 'All Topics',

  // Checks and Conflicts
  conflicts: "Conflicts",
  conflicts_info: "Conflicts are inconsistencies in your world view. Inconsistencies are found through Checks. You can see possible Checks below. Those Checks are not implemented yet. Click on a Conflict to see explanations. Click 'Show Example' to see how it would look for an example.",
  publish_checks_coming_soon_info: "Certain Checks have to be passed for a Statement to be able to be published. For now the Statement just needs at least one supporting Argument. In the future there will be a Check for duplicates, so only completely new Statements or Arguments can be posted.",
  checks_failed: "Some Checks failed. Adjust the content, to be able to publish.",
  checks_successful: "Checks successful.",
  checks_loading: "Checks ongoing.",
  duplicate_check_info: "Only new Statements and Arguments should be published so that no artificial relevance of a Topic can be created.",
  duplicate_check_successful: "No duplicates found",
  duplicate_check_failed: "Duplicate found",
  duplicate_check_loading: "Looking for duplicates...",
  supporting_arguments_check_info: "A Statement should always be explained with at least one supporting Argument.",
  supporting_arguments_not_found: "No Supporting Arguments found",
  supporting_arguments_found: "Supporting Arguments found",
  supporting_arguments_loading: "Looking for supporting Arguments...",
  supporting_arguments_not_checked: "Supporting Arguments not checked",
  source_check_info: "Sources should be trustworthy and uphold scientific or journalistic standards. Note that for Statements that are an opinion, Sources are for reference only and do not say anything about the truthfulness of the Statement.",
  source_not_checked: "Source not checked",
  source_valid: "Source valid",
  source_invalid: "Source not valid", // TODO Difference between source not trustworthy, source fake, source insufficient to proof Statement?
  language_check_info: "Language for productive discourse should be as neutral and objective as possible. Choose terms wisely and avoid polemic.",
  language_objective: "Objective language",
  language_not_checked: "Objectivity of language not checked",
  language_unobjective: "Nonobjective language",
  relevant_arguments_check_info: "If you actively agree or disagree to a Statement, you should have at least heard all Arguments against your position.",
  relevant_arguments_resolve_prompt: "View all Arguments against your position about this Statement to resolve this Conflict.",
  relevant_arguments_not_checked: "Arguments against your position not checked",
  relevant_arguments_unseen: "There are unseen Arguments against your position",
  relevant_arguments_seen: "Seen all published Arguments against your position", // TODO Eisntellung / Anschauung / Auffassung
  resolve_conflict: "Resolve",
  resolve_tooltip: "Guided Conflict resolution coming soon",

  // authentification
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  register_call_to_action: 'Register for free.',
  join_waitlist: 'Join Waitlist',
  display_name: 'Display Name',
  email: 'Email',
  password: 'Password',
  already_have_account: 'I already have an account!',
  forgot_password: 'Forgot your password?',
  no_account: "Don't have an account?",
  enter_email_password: 'Please enter your email and password.',
  invalid_email: 'That email address is invalid!',
  wrong_email_password: 'Wrong email or password!',
  enter_email: 'Please enter your email.',
  reset_link_sent: 'Password reset link has been sent.',

  // Landing Page
  about_caps: 'About',
  solutions: 'Solutions',
  features_header_link: 'Features',
  pricing: 'Pricing',
  see_test_version: "Register for free",
  header_header: "Stay Informed Easily and Logically About Political Topics",
  header_text: "Are you yearning for a reasonable and rational exchange of opinions? Form your opinion on lucidmind to be aware of all arguments and stay up to date.",
  introduction_group_header: "Being Informed is Now Simple",
  introduction_group_header_soft: "Being Informed is Now Easier",
  introduction_group_statements_header: "Share Your Opinion Logically",
  introduction_group_statements_text: "At lucidmind, statements are shared. Statements must be posted with reasoning. These can be either sources or other statements.",
  introduction_group_logical_tree_header: "Maintain the Overview",
  introduction_group_logical_tree_text: "All statements are logically connected in a vast network. This way, you can quickly and easily inform yourself about new topics.",
  introduction_group_interaction_header: "Learn to Understand the World",
  introduction_group_interaction_header_soft: "Organize Your Thoughts",
  introduction_group_interaction_text: "Decide which statements you believe and which reasoning you comprehend. In this way, form your own worldview.",
  argument_proposal_header: "Stay Up to Date",
  argument_proposal_text: "Lean back. New arguments and arguments against your worldview will be delivered to your feed. This way, you learn to solidify or discard your arguments.",
  pro_contra_argument_header_soft: "Get Closer to the Truth",
  pro_contra_argument_header: "Find the Truth",
  pro_contra_argument_text: "Weigh between pros and cons to form your opinion. Keep track of all arguments.",
  logical_network_header: "Shape Your Opinion",
  logical_network_text: "Your own logical network assists you in knowing what and why you believe. Use it in discussions with family and friends and learn to remain objective.",
  conflictsChecks_header: "Argue Coherently",
  conflictsChecks_text: "Statements undergo specific transparent checks to help you build your argumentation as coherently as possible.",
  call_to_action_header: "Become the Citizen You Should Be",
  call_to_action_header_soft: "Stay Informed.",
  call_to_action_text: "Sign up for the test version.",

  // Knowledge Base Approach
  header_knowledge_base: "Your personal AI-supported logical knowledgebase",
  header_knowledge_base_text: "One place for all sources, facts and opinions. Build a consistent world view based on logical reasoning. Resolve inconsistencies and share your knowledge to receive a broader view on any subject.",
  artificial_intelligence_supported_groud_header: "Get informed utilizing latest AI-advancements",
  knowledge_extraction_header: "Information Extraction",
  knowledge_extraction_text: "Extract statements from sources as news or scientific articles with one click. Dont waste time on lengthy research.",
  automatic_reasoning_header: "Automatic Reasoning",
  automatic_reasoning_text: "Automatically structure statements logically to get an overview over unstructured information.",
  argument_generation_header: "Generate Arguments",
  argument_generation_text: "Use Generative Artificial Intelligence zu find new angles on subjects.",
  share_knowledge_header: "Shared knowledge",
  share_knowledge_text: "Share your opinions with others and include others knowledge in your world view.",
  automatic_critizism_header: "Stay consistent",
  automatic_critizism_text: "We detect inconsistencies in your world view and help you to resolve them.",
  conquer_your_bias_header: "Conquer your bias",
  conquer_your_bias_text: "You get all the relevant arguments to critisize your view automatically.",
  source_fact_opinion: "From Source to Fact to Opinion",

  // Singular Information Source Approach
  header_information_source: "Your singular information source for a fast paced world",
  header_information_source_text: "One place for all sources, facts and opinions. Receive logical structured content from news or scientific articles and embed them in your world view. Focus only on the main points and topics relevant to you.",
  clean_feed_header: "One feed fits all",
  clean_feed_text: "A clean feed with the latest statements without any clutter or spam.",
  one_place_for_all_header: "One place for all sources, facts and opinions.",
  time_saving_header: "Fast",
  time_saving_text: "Reduce reading time by only focusing on the important parts of articles.",
  better_overview_header: "Structured",
  better_overview_text: "Get the big picture of topics with logical structured content. New information is automatically embedded in one big logical network.",
  deeper_understanding_header: "Get a better understanding",
  deeper_understanding_text: "Understand arguments for both sides by automatically receiving counter-arguments.",
  benefit_unbiased_header: "Unbiased",
  benefit_unbiased_text: "Get all views from all sides by receiving all supporting and opposing arguments of a statement.",


  // Benefits
  benefit_fast_header: "Fast",
  benefit_fast_header_alternative_1: "Reduce Reading Time",
  benefit_fast_text: "Reduce reading time by only focusing on the important parts of articles.",
  benefit_shared_header: "Shared",
  benefit_shared_text: "Exchange knowledge with others to gain a broader and deeper view of topics. Let others criticize your opinions to make them more concrete.",
  benefit_smart_header: "Smart",
  benefit_smart_header_alternative_1: "AI-supported",
  benefit_smart_text: "Receive smart proposals for counter arguments or generate Arguments using Generative AI.",
  benefit_engaging_header: "Engaging",
  benefit_engaging_header_alternative_1: "Gamification",
  benefit_engaging_header_alternative_2: "Fun",
  benefit_engaging_text: "Earn achievements for getting a consistent world view. Resolve conflicts to gain a higher level.",

  // Features
  feature_generated_content_header: "Reduce Reading Time",
  feature_generated_content_text: "In todays fast paced world its more important than ever to get information fast and efficient. With automatically generated content from different sources you will quickly have an understanding of all relevant topics.",

  // Call to action
  call_to_action_register_header: "Stay informed.",
  call_to_action_register_text: "Sign up now.",
  call_to_action_elevate_insights_header: "Enhance Your Understanding",
  call_to_action_elevate_insights_text: "Navigate intricate knowledge landscapes, gaining clarity and insight with each step.",
  call_to_action_uncover_knowledge_header: "Reveal Hidden Insights",
  call_to_action_uncover_knowledge_text: "Delve into the realm of knowledge, where complex ideas unfold systematically.",
  call_to_action_expand_horizons_header: "Broaden Your Perspectives",
  call_to_action_expand_horizons_text: "Engage in a journey with fellow seekers of knowledge, fostering diverse opinions and fresh viewpoints.",
  call_to_action_register_test_header: "Experience lucidmind Firsthand",
  call_to_action_register_test_text: "Get hands-on experience with our test version. Explore its features and witness the impact on your insights.",
  call_to_action_try_solution_header: "Try lucidmind Today",
  call_to_action_try_solution_text: "Immerse yourself in the world of lucidmind. See how it transforms understanding.",
  call_to_action_get_test_access_header: "Access Test Version Now",
  call_to_action_get_test_access_text: "Unlock the doors to our test version. Witness the power of knowledge enhancement firsthand.",

  try_now: "Try now",
  learn_more: "Learn more",
  coming_soon: "Coming soon",
  for_whom: "For whom is LucidMind?",

  opinions: 'Opinions',
  facts: 'Facts',
  sources: 'Sources',
  news: 'News',
  debates: 'Debates',
  ideas: 'Ideas',
  arguments: 'Arguments',
  topics: 'Topics',
  others: 'Others',
  the_world: 'the World',
  knowledge: 'Knowledge',

  personas: {
    everyone: {
      header: 'Everyone',
      text: 'Every knowledge-seeker who wants to improve his critical thinking and gain clarity. Structure your thoughts and let your voice be heard.',
    },
    students: {
      header: 'Students',
      text: 'Structure the most important arguments from lectures, to improve your understanding. Extract statements from scripts and others sources.',
    },
    journalists: {
      header: 'Journalists',
      text: 'Work faster with sources by extracting statements automatically. Structure them logically to keep the overview.',
    }
  },
  approaches: {
    structureKnowledgeLogically: {
      header: "Structure Knowledge logically",
      text: "Structure your knowledge in statements, that are connected through arguments. Add sources to statements for a more consistent world view.",
    },
    shareKnowledgeGainConsistency: {
      header: "Share Knowledge and gain Consistency",
      text: "Share arguments with others and automatically receive criticism about your views. Resolve detected conflicts to gain consistency.",
    },
    extractKnowledge: {
      header: "Extract Knowledge from Sources",
      text: "Extract Statements and Arguments from sources like news or scientific articles to rapidly understand content. Add them easily to your world view.",
    }
  },
  headers: {
    general: {
      header: {
        understand: 'Understand',
        through_logic: 'through Logic',
      },
      text: 'LucidMind is the tool for all knowledge-seekers, who want to understand our complex world. Structure information logically, share it with others and gain consistency.',
    },
    debate_superpowers: {
      header: "Unlock Your Debate Superpowers",
      text: "Welcome to the ultimate tool for debaters and speakers who strive for perfection. Unleash your rhetorical skills and win every debate with ease."
    },
    rational_content: {
      header: "Foster rational Discourse with Your Content",
      text: "Craft compelling content effortlessly with our AI-driven knowledge structuring tool."
    },
    structureKnowledge: {
      header: "Structure Your Knowledge",
      text: "A tool to logically organize your thoughts and information. Keep all viewpoints, facts, and sources in one place.",
    },
    generatedNews: {
      header: "Source-based logical structured News",
      text: "Logical structured and source-based news articles generated supported by AI."
    },
    generatedNews2: {
      header: "Source-based logical News",
      text: "Logical structured news extracted from different sources."
    }
  },
  subHeaders: {
    contentExcellence: {
      header: "Your Solution for Content Excellence"
    },
    logicalOrganization: {
      header: "Logical Knowledge Organization",
      text: "Our platform allows you to structure your knowledge and arguments in clear syllogisms. Identify contradictions in your worldview and generate new arguments with artificial intelligence."
    },
  },
  benefits: {
    shared: {
      shareWithCommunity: {
        header: "Together",
        text: "Enable your audience to get a deeper view on your shared content. Let them engage in the discourse and foster a constructive debate.",
      },
      sharedKnowledgeShort: {
        header: "Shared",
        text: "Exchange knowledge with others to gain a broader and deeper view of topics.",
      },
    },
    fast: {
      reduceResearchTime: {
        header: "Efficient",
        text: "Reduce research time by only focusing on the most important points of sources. Keep your audience engaged with more frequent posts.",
      }
    },
    structured: {
      structuredShort: {
        header: "Structured",
        text: "New information is automatically embedded in one big logical network.",
      }
    }
  },
  features: {
    argumentNetwork: {
      webOfKnowledge: {
        header: "The Web of Knowledge",
        text: "Visualize your thoughts in a vast network of interconnected statements. See the logical flow of your arguments at a glance.",
      },
      structureKnowledge: {
        header: "Structure Your Knowledge",
        text: "Organize your knowledge and arguments logically within a network of statements. Integrate counterarguments and detect conflicts in your worldview.",
      },
    },
    aiGeneratedArguments: {
      intelligentArguments: {
        header: "Intelligent Argumentation",
        text: "Leverage advanced AI to create and organize your arguments seamlessly.",
      },
      counterArgumentGeneration: {
        header: "Counterargument Generation",
        text: "Automatically generate counterarguments to enhance your content's persuasiveness.",
      }
    },
    consistencyChecker: {
      consistencyAtGlance: {
        header: "Consistency at a Glance",
        text: "Ensure your worldview is consistent across all your arguments and statements.",
      },
      consistentExcellence: {
        header: "Consistent Excellence",
        text: "Consistently produce high-quality content and elevate your authority in your niche.",
      },
    },
    argumentProposal: {
      dynamicCounterarguments: {
        header: "Dynamic Counterarguments",
        text: "Empower your content with clear counterarguments that strengthen your arguments and captivate your audience.",
      }
    },
    conflictResolution: {
      resolutionGuidance: {
        header: "Conflict Resolution Guidance",
        text: "Navigate through conflicts in your worldview with proposed arguments and achieve consistency."
      }
    },
    sharedArguments: {
      shareAndDiscuss: {
        header: "Share and Discuss",
        text: "Share your statements and arguments with other users. Engage in meaningful discussions and expand your knowledge.",
      }
    }
  },
  callsToAction: {
    startCrafting: {
      header: "Start Crafting Persuasive Content",
      text: "Join our community and transform your content creation process."
    },
    tryNow: {
      header: "Try it now",
      text: "Enhance your thinking processes and expand your knowledge base. Become part of a community that values logical thinking and knowledge exchange."
    }
  },
  // Other
  privacy_policy: "Privacy Policy",
  impressum: "Impressum - Legal Disclosure",
  agb: "AGB",

  demo: {
    logicalStructure: {
      title: "Create a logical argument",
      socrates_is_mortal: "Socrates is mortal.",
      all_ment_are_mortal: "All men are mortal.",
      socrates_is_a_men: "Socrates is a men.",
    }
  }

};
