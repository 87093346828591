import { Col, Row, Image, Container, Navbar, Nav, Offcanvas } from "react-bootstrap"
// @ts-ignore
import logo from '../../LucidMindShared/res/LogoLM.png';
// @ts-ignore
import logoWithText from '../../LucidMindShared/res/logoWithText.png';
import { TestVersionButton } from "./Buttons";
import { strings } from "../../LucidMindShared/localization.web";

interface LandingPageHeaderProps { showNavigation?: boolean, handleLandingPageClick: Function }
export function LandingPageHeader({ showNavigation = false, handleLandingPageClick }: LandingPageHeaderProps) {

  const landingPageActive = false;
  const expand = "md"

  return (
    <Navbar className='py-1 px-3 landing-page-header-header' expand={expand} collapseOnSelect>
      <Container fluid>
        <Navbar.Brand href='/' className='mx-2'>
          {landingPageActive ?
            <>
              <Image className="mx-2" src={logoWithText} height="20%" width="20%" />
            </>
            :
            <>
              <Image className="mx-2" src={logo} height="40px" width="40px" />
            </>
          }
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Offcanvas
                </Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {/* <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Link</Nav.Link>
                  <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                <Form className="d-flex">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="me-2"
                    aria-label="Search"
                  />
                  <Button variant="outline-success">Search</Button>
                </Form> */}

              {showNavigation &&
                <>
                  <Nav.Link href="https://app.lucid-mind.eu/login">{strings.about_caps}</Nav.Link>
                  <Nav.Link href="#features">{strings.features_header_link}</Nav.Link>
                  <Nav.Link href="#solutions">{strings.solutions}</Nav.Link>
                  <Nav.Link className="me-2 d-none d-sm-none d-md-block" href="https://app.lucid-mind.eu/login">{strings.pricing}</Nav.Link>
                  <Nav.Link className="mb-2 d-block d-sm-block d-md-none" href="https://app.lucid-mind.eu/login">{strings.pricing}</Nav.Link>
                </>
              }

              <TestVersionButton handleLandingPageClick={handleLandingPageClick} />

            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        {/* {showNavigation && 
                    <>
                        <Nav.Link href="/login">About</Nav.Link>
                        <Nav.Link href="#features">Features</Nav.Link>
                        <Nav.Link href="/login">Solutions</Nav.Link>
                        <Nav.Link href="/login">Pricing</Nav.Link>
                    </>
                } */}

      </Container>
    </Navbar >
  )
}